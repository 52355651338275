import * as E from 'fp-ts/Either';
import { toUnion, mapLeft, map } from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import { groupBy, mapObjIndexed, values } from 'ramda';
import 'fp-ts/Array';
import { mapFail, orElse, of } from './core.mjs';
import 'value-or-factory';
import 'js-base64';
import { formatError, formatPath, errorAt } from './formatting.mjs';
import 'fp-ts/lib/function';
import './specialty.mjs';
import 'uuid';

/**
 * Throws if there is an error, otherwise returns the value.
 */
const throwError = (formatter = formatError(false)) => flow(singleError(formatter), E.mapLeft(_ => { throw new globalThis.Error(_); }), toUnion);
/**
 * Turns the errors into a map of errors, where the key is the formatted path and the value is an array of errors for each key.
 */
const groupErrors = () => mapFail(groupBy(_ => formatPath(_.path)));
/**
 * Turns the errors into a map of errors, where the key is the formatted path and the value is an array of string for each key.
 */
const groupMessages = (formatter = formatError(false)) => flow(groupErrors(), mapLeft(mapObjIndexed(_ => _.map(formatter))));
/**
 * Turns the errors into a map of errors, where the key is the formatted path and the value is a single string of all the errors for that key.
 */
const combineMessages = (formatter = formatError(false)) => flow(groupErrors(), mapLeft(mapObjIndexed(error => formatter(error) + ".")));
/**
 * Turns the errors into a list of error messages.
 */
const messageList = (formatter = formatError(false)) => flow(combineMessages(formatter), mapLeft(flow(mapObjIndexed((value, key) => errorAt(key, value)), values)));
/**
 * Turns the errors into one string.
 */
const singleError = (formatter = formatError(false)) => flow(messageList(formatter), E.mapLeft(l => l.join("\n")));
/**
 * Returns only successful values. If there were errors, the value is undefined.
 */
const valueOnly = () => flow(E.mapLeft(_ => undefined), toUnion);
/**
 * Returns only errors. If there are no errors, the value is undefined.
 */
const errorMessagesOnly = (formatter = formatError(false)) => flow(messageList(formatter), map(_ => undefined), toUnion);
/**
 * Returns only errors. If there are no errors, the value is undefined.
 */
const errorsOnly = () => flow(map(_ => undefined), toUnion);
/**
 * Turn failed chains into undefined values with no errors.
 */
const failToEmpty = () => orElse(() => of(undefined));

export { combineMessages, errorMessagesOnly, errorsOnly, failToEmpty, groupErrors, groupMessages, messageList, singleError, throwError, valueOnly };
