import { gql } from "@apollo/client"
import { ZERO } from "adapters/index"
import { camelCase, pascalCase, snakeCase } from "change-case"
import { MAX_VARIABLES_PER_QUERY, PG } from "config"
import { EnumType, VariableType, jsonToGraphQLQuery } from "json-to-graphql-query"
import { Kysely, sql } from "kysely"
import { Ramda } from "namespaces/Ramda"
import { Schema, SyncSchema } from "services/db/Schema"
import Introspector, { IntrospectionColumn } from "services/sync/Introspector"
import * as GenQLSync from "shared/genql/sync"
import { maybe } from "shared/maybe"
import { promiseChain } from "shared/misc"
import { Hasura } from "ui/services/Hasura"
import { stringify } from "uuid"

function transformUp(value: unknown, dataType: string) {
    if (value === null) {
        return null
    }
    else if (dataType === "uuid" && value instanceof Uint8Array) {
        return stringify(value)
    }
    else if ((dataType === "timestamp" || dataType === "datetime") && typeof value === "number") {
        return new Date(value).toISOString()
    }
    else if (dataType === "boolean") {
        return !!value
    }
    else {
        return value
    }
}
function transformDown(rows: readonly Record<string, unknown>[], columns: readonly IntrospectionColumn[]) {
    const transformers = columns.flatMap(column => {
        if (column.type === "uuid") {
            if (PG) {
                return [
                    (row: Record<string, unknown>) => row//Object.assign(row, { [column.name]: parseId(row[column.select] as string) })
                ]
            }
            else {
                return [
                    /* (row: Record<string, unknown>) => {
 
                         return Object.assign(row, { [column.name]: row[column.select] === null ? null : parseId(row[column.select] as string) })
                     }*/
                    (row: Record<string, unknown>) => Object.assign(row, { [column.name]: sql`string_to_id(${row[column.select]})` })
                ]
            }
        }
        else if (column.type === "timestamp" || column.type === "datetime") {
            if (PG) {
                return [
                    (row: Record<string, unknown>) => row// Object.assign(row, { [column.name]: row[column.select] })
                ]
            }
            else {
                return [
                    (row: Record<string, unknown>) => Object.assign(row, { [column.name]: sql`iso_string_to_date(${row[column.select]})` })
                ]
            }
        }
        else if (column.type === "boolean") {
            return [
                (row: Record<string, unknown>) => Object.assign(row, { [column.name]: row[column.select] ? 1 : 0 as unknown })
            ]
        }
        return []
    })
    return rows.map(row => {
        return transformers.reduce((row, func) => func(row), row)
    })
}

export default class {

    private readonly introspector
    private readonly masterDb
    private readonly entityDb

    constructor(db: Kysely<unknown>, private readonly hasura: Hasura.Client<GenQLSync.Client>, private readonly userId: number) {
        this.masterDb = db.withTables<Schema>()
        this.entityDb = db.withTables<SyncSchema>()
        this.introspector = new Introspector(db, hasura.apollo)
    }

    /**
     * Copy the main information such as companies and users.
     * @returns Statistics.
     */
    async prepare() {
        const result = await this.hasura.query({
            companies: { id: true, name: true },
            users: { id: true, name: true },
            permissions: { userId: true, companyId: true, role: true },
        })
        console.log("[Sync/Object] Received sync preparation information.", result)
        await this.masterDb.transaction().execute(async tx => {
            if (result.companies.length > 0) {
                /*
                await tx.deleteFrom("companies")
            // maybe add a way to clear old data manually
                    .where("id", "not in", result.companies.map(_ => _.id))
                    .execute()
                    TODO mark as archived instead of deleting - or just rely on perms?
                // WE HAVE TO EXCLUDE FROM SYNC!!!
                    */
                await tx.insertInto("companies")
                    .values(result.companies)
                    .onConflict(ob => {
                        return ob.column("id").doUpdateSet({
                            name: eb => eb.ref("excluded.name")
                        })
                    })
                    .execute()
            }
            else {
                // TODO mark as archived instead of deleting - or just rely on perms?
                // maybe add a way to clear old data manually
                // WE HAVE TO EXCLUDE FROM SYNC!!!
                //  await tx.deleteFrom("companies")
                //  .execute()
            }
            if (result.users.length > 0) {
                await tx.deleteFrom("users")
                    .where("id", "not in", result.users.map(_ => _.id))
                    .execute()
                await tx.insertInto("users")
                    .values(result.users)
                    .onConflict(ob => {
                        return ob.column("id").doUpdateSet({
                            name: eb => eb.ref("excluded.name")
                        })
                    })
                    .execute()
            }
            else {
                await tx.deleteFrom("users")
                    .execute()
            }
            if (result.permissions.length > 0) {
                await tx.deleteFrom("permissions")
                    .where(wb => {
                        return wb.not(wb.or(
                            result.permissions.map(permission => {
                                return wb.and([
                                    wb("userId", "=", permission.userId),
                                    wb("companyId", "=", permission.companyId),
                                    wb("role", "=", permission.role)
                                ])
                            })
                        ))
                    })
                    .execute()
                return await tx.insertInto("permissions")
                    .values(result.permissions)
                    .onConflict(ob => {
                        return ob.doNothing()
                    })
                    .execute()
            }
            else {
                await tx.deleteFrom("permissions")
                    .execute()
            }
        })
        return {
            companies: result.companies.length,
            users: result.users.length,
            permissions: result.permissions.length,
        }
    }

    private async lastsQuery() {
        const introspection = await this.introspector.introspection
        const query = await introspection.map(table => {
            return this.entityDb.selectFrom(table.name)
                .select([
                    "companyId",
                    sql.val(table.name).as("table"),
                    eb => eb.fn.max("lastActivity").as("last")
                ])
                .groupBy("companyId")
        }).reduce((a, b) => {
            return a.unionAll(b)
        }).execute()
        return introspection.map(table => [table.name, query.filter(_ => _.table === table.name)] as const)
    }

    async download() {
        const [
            introspection,
            lasts
        ] = await Promise.all([
            this.introspector.introspection,
            this.lastsQuery()
        ])
        // We sort by last activity because if a sync partially finishes, we only want the oldest items, since it will pull only items newer than our newest item based on last activity.
        const query = Object.fromEntries(lasts.map(([table, lasts]) => {
            const introspected = maybe(introspection.find(_ => _.name === table))
            return [table, {
                __args: {
                    orderBy: {
                        lastActivity: new EnumType("ASC")
                    },
                    ...Ramda.call(() => {
                        if (lasts.length > 0) {
                            return {
                                where: {
                                    _or: [
                                        {
                                            companyId: { _nin: lasts.map(_ => _.companyId) }
                                        },
                                        ...lasts.map(last => {
                                            return { companyId: { _eq: last.companyId }, lastActivity: { _gt: new Date(Number(last.last)).toISOString() } }
                                        })
                                    ]
                                }
                            }
                        }
                    })
                },
                ...Object.fromEntries(introspected.columns.map(_ => [_.select, true])),
            }]
        }))
        const gqlQuery = jsonToGraphQLQuery({ query })
        console.log("[Sync/Object] Sending query to GraphQL server...", { gqlQuery })
        const result = await this.hasura.apollo.query<Record<string, Record<string, unknown>[]>>({
            query: gql(gqlQuery)
        })
        const totalObjects = Ramda.sum(Object.values(result.data).map(_ => _.length))
        if (totalObjects === 0) {
            return {
                objectsDownloaded: totalObjects
            }
        }
        // See above note about sorting by last activity. We have to run promises sequentially instead of in parallel to make this work.
        const counts = await promiseChain(introspection.map(table => {
            return async () => {
                const items = maybe(result.data[table.name])
                if (items.length === 0) {
                    return {
                        table: table.name,
                        count: 0,
                    }
                }
                // Note - if things are synced, updatedOn being greater than OR equal to, it accepts the change.
                // It does the same on the server.
                // The reason for this is that you can set isDirty to update items without having to change updatedOn
                const counts = await this.entityDb.transaction().execute(async tx => {
                    const transformed = transformDown(items, table.columns).map(row => Object.assign(row, { isDirty: 0 }))
                    const updates = table.columns.filter(_ => !_.pk).map(_ => _.name).concat(Introspector.METAS)
                    const exclusions = Object.fromEntries(updates.map(column => [column, sql.ref("excluded." + column)] as const))
                    const pks = table.columns.filter(_ => _.pk).map(_ => _.name)
                    const chunkSize = Math.floor(MAX_VARIABLES_PER_QUERY / (table.columns.length + Introspector.METAS.length))
                    const chunks = Ramda.splitEvery(chunkSize, transformed)
                    console.log("[Sync/Object] Inserting items (slice of 10 attached)...", { table: table.name, items: items.slice(0, 10) })
                    return await promiseChain(chunks.map((chunk, index) => {
                        return async () => {
                            const started = performance.now()
                            console.log("[Sync/Object] Inserting chunk (slice of 10 attached)...", { chunk: chunk.slice(0, 10), index, totalChunks: chunks.length, table: table.name })
                            await tx.insertInto(table.name)
                                .values(chunk)
                                .onConflict(ob => {
                                    return ob.columns(pks)
                                        .doUpdateSet(exclusions)
                                        .whereRef("excluded.updatedOn", ">=", table.name + ".updatedOn")
                                })
                                .execute()
                            console.log("[Sync/Object] Inserted items (slice of 10 attached).", { chunk: chunk.slice(0, 10), timeElapsed: performance.now() - started })
                            return chunk.length
                        }
                    }))
                })
                return {
                    table: table.name,
                    count: Ramda.sum(counts)
                }
            }
        }))
        const types = counts.flat().map(entry => {
            return [
                "objectsDownloadedFrom" + entry.table,
                entry.count
            ] as const
        })
        return {
            objectsDownloaded: Ramda.sum(types.map(_ => _[1])),
            ...Object.fromEntries(types),
        }
    }
    /*
        private async dirtiesPerTable() {
            //TODO exclude companies that we dont have access to!!! - also for our main dirties query that's used in the app
            const introspection = await this.introspector.introspection
            return await introspection.map(table => {
                return this.entityDb.selectFrom(table.name)
                    .select([
                        sql.val(table.name).$castTo<string>().as("table"),
                        eb => {
                            return eb.fn.count<number>("isDirty").as("dirties")
                        }
                    ])
                    // .where(wb => {
                    //      return wb("companyId", "in", companiesQuery(wb).where("users.id", "=", auth.data.id))
                    //  })
                    .where("isDirty", "=", 1)
            }).reduce((a, b) => a.unionAll(b)).execute()
        }*/

    async upload() {
        //TODO exclude companies that we dont have access to!!!
        console.log("[Sync/Object] Uploading entities to server...")
        const tables = await this.introspector.introspection
        // const dirtiesPerTable = await this.dirtiesPerTable()
        //console.log("[Sync/Object] Found the following dirty entity counts.", dirtiesPerTable)
        //  const dirtyTables = dirtiesPerTable.filter(_ => _.dirties > 0).map(_ => _.table)
        const tablesToUpload = tables.filter(_ => _.columns.filter(column => column.insert).length !== 0).filter(_ => tables.map(_ => _.name).includes(_.name))
        const results = await promiseChain(tablesToUpload.map(table => {
            return async () => {
                console.log("[Sync/Object]  at table for object upload...", { table })
                const insertColumns = table.columns.filter(column => column.insert)
                const dirtyItems = await this.entityDb.selectFrom(table.name)
                    .where("isDirty", "=", 1)
                    // .where(wb => {
                    //TODO important limit only to companies we can access somehow
                    //        return wb("companyId", "in", companiesQuery(wb).where("users.id", "=", this.userId))
                    //  })
                    .select(insertColumns.map(_ => _.name))
                    .execute()
                if (dirtyItems.length === 0) {
                    console.log("[Sync/Object] Table has no dirty items. Skipping...", { table: table.name })
                    return 0
                }
                console.log("[Sync/Object] Found dirty items (slice of 10 attached). Sending...", dirtyItems.slice(0, 10))
                const objects = dirtyItems.map(item => {
                    return Object.fromEntries(
                        insertColumns.map(column => {
                            return [column.name, transformUp(item[column.name], column.type)] as const
                        })
                    )
                })
                const chunkSize = Math.floor(MAX_VARIABLES_PER_QUERY / table.columns.filter(column => column.insert).length)
                const chunks = Ramda.splitEvery(chunkSize, objects)
                await Promise.all(chunks.map(async (chunk, index) => {
                    console.log("[Sync/Object] Sending chunk to sync mechanism...", { index, totalChunks: chunks.length, table: table })
                    const hasuraKey = camelCase("insert_" + table.name)
                    const updateColumns = table.columns.filter(_ => _.update).map(_ => _.name)
                    const gqlCode = jsonToGraphQLQuery({
                        mutation: {
                            __variables: {
                                objects: "[" + pascalCase(table.name) + "InsertInput!]!",
                            },
                            [hasuraKey]: {
                                __args: {
                                    objects: new VariableType("objects"),
                                    ...(() => {
                                        if (updateColumns.length !== 0) {
                                            return {
                                                onConflict: {
                                                    constraint: new EnumType(snakeCase(table.name) + "_pkey"),
                                                    updateColumns: updateColumns.map(_ => new EnumType(camelCase(_))),
                                                }
                                            }
                                        }
                                    })()
                                },
                                affectedRows: true
                            }
                        }
                    })
                    console.log("[Sync/Object] Sending query to GraphQL server...", { gqlCode, chunk })
                    const result = await this.hasura.apollo.mutate<Record<string, { affectedRows: number }>>({
                        mutation: gql(gqlCode),
                        variables: {
                            objects: chunk
                        }
                    })
                    const affectedRows = maybe(result.data?.[hasuraKey]?.["affectedRows"])
                    if (affectedRows !== chunk.length) {
                        throw new Error("An insert/update query returned a mismatched number of rows. Expected " + chunk.length + " affected rows, but got " + affectedRows + ". This might mean you are trying to upload items to a company to which you no longer have edit access.")
                    }
                }))
                const localChunkSize = Math.floor((MAX_VARIABLES_PER_QUERY - 1) / table.columns.filter(_ => _.pk).length)
                const localChunks = Ramda.splitEvery(localChunkSize, dirtyItems)
                await this.entityDb.transaction().execute(async tx => {
                    await Promise.all(localChunks.map(async (chunk, index) => {
                        console.log("[Sync/Object] Updating local meta for chunk (slice of 10 attached)...", { items: chunk.slice(0, 10), index, totalSets: localChunks.length, table: table.name })
                        const update = tx.updateTable(table.name).set({ isDirty: ZERO })
                        const updateConditional = table.columns.filter(_ => _.pk).map(_ => _.name).reduce((query, column) => {
                            return query.where(column, "in", chunk.map(_ => _[column]))
                        }, update)
                        await updateConditional.execute()
                    }))
                })
                return dirtyItems.length
            }
        }))
        return {
            objectsUploaded: Ramda.sum(results)
        }
    }

}
