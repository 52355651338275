import { Base64 } from "js-base64";
import { parse, stringify, v4 } from "uuid";
function uint8ArrayToId(array) {
    if (array.byteLength === 16) {
        return array;
    }
    else {
        throw new Error("This is not a valid ID (byte length is " + array.byteLength + ").");
    }
}
export const newId = () => v4({}, new Uint8Array(16));
export const printId = (id) => {
    return stringify(id);
};
export const printIdBase64 = (id) => {
    return Base64.fromUint8Array(id, true);
};
export function parseId(id) {
    return uint8ArrayToId(parse(id));
}
export function parseIdBase64(id) {
    return uint8ArrayToId(Base64.toUint8Array(id));
}
export function parseIdIfNecessary(id) {
    if (typeof id !== "string") {
        return id;
    }
    return parseId(id);
}
export function isId(id) {
    try {
        if (ArrayBuffer.isView(id)) {
            return true;
        }
        return false;
    }
    catch (e) {
        return false;
    }
}
