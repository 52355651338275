
/**
 * - Some way to notify when the app has changed, and prompt for a reload?
 * - Multiple filters in table.
 * - no easy way to edit the Hazards column on procedures page on a touch screen?
 * 
 * shrink camera on ipad or landscape
 * 
 * PHOTO FIELD IN AUDIT TOOL
 * MULTIPLE PHOTOS - NOTES PER PHOTO
 * 
 * test out delivery scan on a phone - ui is kinda sloppy looking
 * 
 * find a way to not have to do pdf chunks
 * procedure page - not all menu items disable whn you hit reload
 * add phone num to users
find and delete all relative links
/**
 * on bulk upload page - disable camera button while uploading
 * 
 * template edit text box is very short


Jul 17, 7:33 PM
Terms and conditions
 * mark everyone as accepted tos except for jason and anthony from ers

why the fuck is the procedure dropdown when creating an inspection item not async? its slow as shit
https://app.thelotogroup.com/company/16/audit/cBS9mNBoRJSrR44H83EEPw/create

TODO add ocr on qr code/scanner popup

add id columns for control points on ALL tables - not just full procedures table?

when editing proc statuses, it reloads from DB twice on full proc table?

move shv1 to ERS

ORH3	4120-MSP01	173643	Printed and Delivered				1	2024-08-27 19:00:00	2024-09-18 10:13:05
jason said the created on for this procedure changed between printouts

make file import tolerant of duplicates

some kind of full export/import of everything

db breaks when you close first tab
printing for audits
on audits, check for failed, uncheck for passed
disable scan asset tab if no camera on audits
custom fields for audits? - jason said wait
add corrective action to findings
add priority to findings - matrix 

*/

import { Ramda } from "namespaces/Ramda"

/**
 * Convenience types.
 */
export const ONE: 0 | 1 = 1
export const ZERO: 0 | 1 = 0

/**
 * A bootstrap break.
 */
export type Break = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

/**
 * Require only the specified keys.
 */
export type RequireOnly<T, K extends keyof T> = Partial<{ [X in keyof Omit<T, K>]: T[X] | undefined | null }> & Pick<T, K>

/**
 * A utility function to create a query string.
 * @param object An object of the query parameters.
 * @returns A query string.
 */
export function queryString(object: Record<string, string | undefined>) {
    return new URLSearchParams(Ramda.pickBy(Ramda.isNotNil, object)).toString()
}
