import AccountContext from "adapters/contexts/AccountContext"
import GlobalContext from "adapters/contexts/GlobalContext"
import { IS_DEBUG } from "config"
import { pipe } from "fp-ts/function"
import { Compilable, QueryCreator, UnknownRow } from "kysely"
import { Option } from "namespaces/Option"
import { Ramda } from "namespaces/Ramda"
import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useCompanyDb, useDb } from "services/Core"
import { CompanySchema, Schema } from "services/db/Schema"
import { maybe } from "shared/maybe"
import { errorToString, typedJson } from "shared/misc"
import { useCallbackOnce, useDeepCompareMemo, useEventOnce, useFactory, useRequiredContext } from "state-hooks"
import { JsonValue } from "type-fest"
import { useAuth } from "ui/auth/authorization"
import DebugContext from "ui/contexts/DebugContext"
import { useSynchronousObservable } from "ui/observables"
import StoreItem from "ui/store/StoreItem"
import { ValueOrFactory, callOrGet } from "value-or-factory"

export function useErrorPopup() {
    return useCallbackOnce((e: unknown) => {
        toast.error(errorToString(e), { position: "top-center" })
    })
}

export function useSearch() {
    const [params, setParams] = useSearchParams()
    const value = Object.fromEntries([...params.entries()])
    const setValue = (state: SetStateAction<{ readonly [k: string]: string | undefined }>) => {
        setParams(prev => {
            return Ramda.pickBy(Ramda.isNotNil, callOrGet(state, Object.fromEntries([...prev.entries()])))
        })
    }
    return [value, setValue] as const
}

//TODO rm
export function useParam(key: string) {
    const [params, setParams] = useSearchParams()
    const param = params.get(key) ?? undefined
    const setParam = (state: SetStateAction<string | undefined>) => {
        setParams(prev => {
            return Ramda.pickBy(Ramda.isNotNil, {
                ...Object.fromEntries([...prev.entries()]),
                [key]: callOrGet(state, prev.get(key) ?? undefined)
            })
        })
    }
    return [param, setParam] as const
}

export function useJsonParam<T extends JsonValue>(key: string) {
    const [params, setParams] = useSearchParams()
    const param = useMemo(() => pipe(params.get(key), Option.fromNullable, Option.map(typedJson<T>), Option.toUndefined), [params.get("state")])
    const setParam = (factory: ValueOrFactory<T | undefined, [T | undefined]>) => {
        setParams(prev => {
            return Ramda.pickBy(Ramda.isNotNil, {
                ...prev,
                [key]: maybe(callOrGet(factory, maybe(prev.get(key), typedJson<T>)), JSON.stringify)
            })
        }, {
            replace: true,
        })
    }
    return [param, setParam] as const
}

export function useBaseUrl() {
    const debug = useContext(DebugContext)
    if (debug === IS_DEBUG) {
        return "/"
    }
    else if (debug) {
        return "/debug/"
    }
    else {
        return "/nodebug/"
    }
}
export function useCompanyBaseUrl() {
    const account = useAccount()
    const baseUrl = useBaseUrl()
    return baseUrl + "company/" + account.company.id + "/"
}

export function useStore() {
    const global = useGlobal()
    return global.store
}
export function usePageStore() {
    const global = useGlobal()
    return global.pageStore
}
export function useGlobal() {
    return useRequiredContext(GlobalContext)
}
export function useRestriction() {
    const store = useStore()
    const pageStore = usePageStore()
    const auth = useAuth()
    const account = useAccount()
    const localRestrict = useFactory((pageStore, userId, companyId) => new StoreItem<string>(pageStore, userId + "-" + companyId + "-restrictTo"), [pageStore, auth.data.id, account.company.id])
    const allRestrict = useFactory((store, userId, companyId) => new StoreItem<string>(store, userId + "-" + companyId + "-restrictTo"), [store, auth.data.id, account.company.id])
    const local = useSynchronousObservable(localRestrict)
    const all = useSynchronousObservable(allRestrict)
    const setLocal = useCallback((restrictTo: string | undefined) => localRestrict.setValue(restrictTo), [localRestrict])
    const setAll = useCallback((restrictTo: string | undefined) => allRestrict.setValue(restrictTo), [allRestrict])
    const current = useFactory((a, b) => a ?? b, [local, all])
    const clear = useCallback(() => {
        setLocal(undefined)
        setAll(undefined)
    }, [
        setLocal,
        setAll
    ])
    return {
        current,
        clear,
        local,
        all,
        setLocal,
        setAll,
    }
}
export function useAccount() {
    return useRequiredContext(AccountContext)
}

export function useCompanyQuery<O extends UnknownRow>(factory: (qc: QueryCreator<CompanySchema>) => Compilable<O>) {
    const db = useCompanyDb()
    const compiled = useMemo(() => factory(db).compile(), [factory, db])
    return useDeepCompareMemo(() => {
        return db.observeCompiled<O>(compiled)
    }, [
        db,
        compiled
    ])
}
export function useCompanyQueryTakeFirstOrThrow<O extends UnknownRow>(factory: (qc: QueryCreator<CompanySchema>) => Compilable<O>) {
    const db = useCompanyDb()
    const compiled = useMemo(() => factory(db).compile(), [factory, db])
    return useDeepCompareMemo(() => {
        return db.observeCompiledTakeFirstOrThrow<O>(compiled)
    }, [
        db,
        compiled
    ])
}
export function useCompanyQueryTakeFirst<O extends UnknownRow>(factory: (qc: QueryCreator<CompanySchema>) => Compilable<O>) {
    const db = useCompanyDb()
    const compiled = useMemo(() => factory(db).compile(), [factory, db])
    return useDeepCompareMemo(() => {
        return db.observeCompiledTakeFirst<O>(compiled)
    }, [
        db,
        compiled
    ])
}

export function useQuery<O extends UnknownRow>(factory: (qc: QueryCreator<Schema>) => Compilable<O>) {
    const db = useDb()
    const compiled = useMemo(() => factory(db).compile(), [factory, db])
    return useDeepCompareMemo(() => {
        return db.observeCompiled<O>(compiled)
    }, [
        db,
        compiled
    ])
}
export function useQueryTakeFirstOrThrow<O extends UnknownRow>(factory: (qc: QueryCreator<Schema>) => Compilable<O>) {
    const db = useDb()
    const compiled = useMemo(() => factory(db).compile(), [factory, db])
    return useDeepCompareMemo(() => {
        return db.observeCompiledTakeFirstOrThrow<O>(compiled)
    }, [
        db,
        compiled
    ])
}
export function useQueryTakeFirst<O extends UnknownRow>(factory: (qc: QueryCreator<Schema>) => Compilable<O>) {
    const db = useDb()
    const compiled = useMemo(() => factory(db).compile(), [factory, db])
    return useDeepCompareMemo(() => {
        return db.observeCompiledTakeFirst<O>(compiled)
    }, [
        db,
        compiled
    ])
}

export function useKeyPageNavigation(page: number, setPage: Dispatch<SetStateAction<number>>, pages: number) {
    useEventOnce(window, "keyup", event => {
        if (event.key === "PageUp") {
            if (page > 0) {
                setPage(page - 1)
            }
        }
        if (event.key === "PageDown") {
            if (page < pages - 1) {
                setPage(page + 1)
            }
        }
    })
}
