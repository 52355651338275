// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
const AssetTypes_possibleTypes = ['AssetTypes'];
export const isAssetTypes = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypes"');
    return AssetTypes_possibleTypes.includes(obj.__typename);
};
const AssetTypesAggregate_possibleTypes = ['AssetTypesAggregate'];
export const isAssetTypesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesAggregate"');
    return AssetTypesAggregate_possibleTypes.includes(obj.__typename);
};
const AssetTypesAggregateFields_possibleTypes = ['AssetTypesAggregateFields'];
export const isAssetTypesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesAggregateFields"');
    return AssetTypesAggregateFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesAvgFields_possibleTypes = ['AssetTypesAvgFields'];
export const isAssetTypesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesAvgFields"');
    return AssetTypesAvgFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesMaxFields_possibleTypes = ['AssetTypesMaxFields'];
export const isAssetTypesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesMaxFields"');
    return AssetTypesMaxFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesMinFields_possibleTypes = ['AssetTypesMinFields'];
export const isAssetTypesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesMinFields"');
    return AssetTypesMinFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesMutationResponse_possibleTypes = ['AssetTypesMutationResponse'];
export const isAssetTypesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesMutationResponse"');
    return AssetTypesMutationResponse_possibleTypes.includes(obj.__typename);
};
const AssetTypesStddevFields_possibleTypes = ['AssetTypesStddevFields'];
export const isAssetTypesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesStddevFields"');
    return AssetTypesStddevFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesStddevPopFields_possibleTypes = ['AssetTypesStddevPopFields'];
export const isAssetTypesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesStddevPopFields"');
    return AssetTypesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesStddevSampFields_possibleTypes = ['AssetTypesStddevSampFields'];
export const isAssetTypesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesStddevSampFields"');
    return AssetTypesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesSumFields_possibleTypes = ['AssetTypesSumFields'];
export const isAssetTypesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesSumFields"');
    return AssetTypesSumFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesVarPopFields_possibleTypes = ['AssetTypesVarPopFields'];
export const isAssetTypesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesVarPopFields"');
    return AssetTypesVarPopFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesVarSampFields_possibleTypes = ['AssetTypesVarSampFields'];
export const isAssetTypesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesVarSampFields"');
    return AssetTypesVarSampFields_possibleTypes.includes(obj.__typename);
};
const AssetTypesVarianceFields_possibleTypes = ['AssetTypesVarianceFields'];
export const isAssetTypesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetTypesVarianceFields"');
    return AssetTypesVarianceFields_possibleTypes.includes(obj.__typename);
};
const Assets_possibleTypes = ['Assets'];
export const isAssets = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssets"');
    return Assets_possibleTypes.includes(obj.__typename);
};
const AssetsAggregate_possibleTypes = ['AssetsAggregate'];
export const isAssetsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsAggregate"');
    return AssetsAggregate_possibleTypes.includes(obj.__typename);
};
const AssetsAggregateFields_possibleTypes = ['AssetsAggregateFields'];
export const isAssetsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsAggregateFields"');
    return AssetsAggregateFields_possibleTypes.includes(obj.__typename);
};
const AssetsAvgFields_possibleTypes = ['AssetsAvgFields'];
export const isAssetsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsAvgFields"');
    return AssetsAvgFields_possibleTypes.includes(obj.__typename);
};
const AssetsMaxFields_possibleTypes = ['AssetsMaxFields'];
export const isAssetsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsMaxFields"');
    return AssetsMaxFields_possibleTypes.includes(obj.__typename);
};
const AssetsMinFields_possibleTypes = ['AssetsMinFields'];
export const isAssetsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsMinFields"');
    return AssetsMinFields_possibleTypes.includes(obj.__typename);
};
const AssetsMutationResponse_possibleTypes = ['AssetsMutationResponse'];
export const isAssetsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsMutationResponse"');
    return AssetsMutationResponse_possibleTypes.includes(obj.__typename);
};
const AssetsStddevFields_possibleTypes = ['AssetsStddevFields'];
export const isAssetsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsStddevFields"');
    return AssetsStddevFields_possibleTypes.includes(obj.__typename);
};
const AssetsStddevPopFields_possibleTypes = ['AssetsStddevPopFields'];
export const isAssetsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsStddevPopFields"');
    return AssetsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const AssetsStddevSampFields_possibleTypes = ['AssetsStddevSampFields'];
export const isAssetsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsStddevSampFields"');
    return AssetsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const AssetsSumFields_possibleTypes = ['AssetsSumFields'];
export const isAssetsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsSumFields"');
    return AssetsSumFields_possibleTypes.includes(obj.__typename);
};
const AssetsVarPopFields_possibleTypes = ['AssetsVarPopFields'];
export const isAssetsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsVarPopFields"');
    return AssetsVarPopFields_possibleTypes.includes(obj.__typename);
};
const AssetsVarSampFields_possibleTypes = ['AssetsVarSampFields'];
export const isAssetsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsVarSampFields"');
    return AssetsVarSampFields_possibleTypes.includes(obj.__typename);
};
const AssetsVarianceFields_possibleTypes = ['AssetsVarianceFields'];
export const isAssetsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAssetsVarianceFields"');
    return AssetsVarianceFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotos_possibleTypes = ['AuditFindingPhotos'];
export const isAuditFindingPhotos = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotos"');
    return AuditFindingPhotos_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosAggregate_possibleTypes = ['AuditFindingPhotosAggregate'];
export const isAuditFindingPhotosAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosAggregate"');
    return AuditFindingPhotosAggregate_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosAggregateFields_possibleTypes = ['AuditFindingPhotosAggregateFields'];
export const isAuditFindingPhotosAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosAggregateFields"');
    return AuditFindingPhotosAggregateFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosAvgFields_possibleTypes = ['AuditFindingPhotosAvgFields'];
export const isAuditFindingPhotosAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosAvgFields"');
    return AuditFindingPhotosAvgFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosMaxFields_possibleTypes = ['AuditFindingPhotosMaxFields'];
export const isAuditFindingPhotosMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosMaxFields"');
    return AuditFindingPhotosMaxFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosMinFields_possibleTypes = ['AuditFindingPhotosMinFields'];
export const isAuditFindingPhotosMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosMinFields"');
    return AuditFindingPhotosMinFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosMutationResponse_possibleTypes = ['AuditFindingPhotosMutationResponse'];
export const isAuditFindingPhotosMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosMutationResponse"');
    return AuditFindingPhotosMutationResponse_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosStddevFields_possibleTypes = ['AuditFindingPhotosStddevFields'];
export const isAuditFindingPhotosStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosStddevFields"');
    return AuditFindingPhotosStddevFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosStddevPopFields_possibleTypes = ['AuditFindingPhotosStddevPopFields'];
export const isAuditFindingPhotosStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosStddevPopFields"');
    return AuditFindingPhotosStddevPopFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosStddevSampFields_possibleTypes = ['AuditFindingPhotosStddevSampFields'];
export const isAuditFindingPhotosStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosStddevSampFields"');
    return AuditFindingPhotosStddevSampFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosSumFields_possibleTypes = ['AuditFindingPhotosSumFields'];
export const isAuditFindingPhotosSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosSumFields"');
    return AuditFindingPhotosSumFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosVarPopFields_possibleTypes = ['AuditFindingPhotosVarPopFields'];
export const isAuditFindingPhotosVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosVarPopFields"');
    return AuditFindingPhotosVarPopFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosVarSampFields_possibleTypes = ['AuditFindingPhotosVarSampFields'];
export const isAuditFindingPhotosVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosVarSampFields"');
    return AuditFindingPhotosVarSampFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingPhotosVarianceFields_possibleTypes = ['AuditFindingPhotosVarianceFields'];
export const isAuditFindingPhotosVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingPhotosVarianceFields"');
    return AuditFindingPhotosVarianceFields_possibleTypes.includes(obj.__typename);
};
const AuditFindings_possibleTypes = ['AuditFindings'];
export const isAuditFindings = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindings"');
    return AuditFindings_possibleTypes.includes(obj.__typename);
};
const AuditFindingsAggregate_possibleTypes = ['AuditFindingsAggregate'];
export const isAuditFindingsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsAggregate"');
    return AuditFindingsAggregate_possibleTypes.includes(obj.__typename);
};
const AuditFindingsAggregateFields_possibleTypes = ['AuditFindingsAggregateFields'];
export const isAuditFindingsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsAggregateFields"');
    return AuditFindingsAggregateFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsAvgFields_possibleTypes = ['AuditFindingsAvgFields'];
export const isAuditFindingsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsAvgFields"');
    return AuditFindingsAvgFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsMaxFields_possibleTypes = ['AuditFindingsMaxFields'];
export const isAuditFindingsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsMaxFields"');
    return AuditFindingsMaxFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsMinFields_possibleTypes = ['AuditFindingsMinFields'];
export const isAuditFindingsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsMinFields"');
    return AuditFindingsMinFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsMutationResponse_possibleTypes = ['AuditFindingsMutationResponse'];
export const isAuditFindingsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsMutationResponse"');
    return AuditFindingsMutationResponse_possibleTypes.includes(obj.__typename);
};
const AuditFindingsStddevFields_possibleTypes = ['AuditFindingsStddevFields'];
export const isAuditFindingsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsStddevFields"');
    return AuditFindingsStddevFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsStddevPopFields_possibleTypes = ['AuditFindingsStddevPopFields'];
export const isAuditFindingsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsStddevPopFields"');
    return AuditFindingsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsStddevSampFields_possibleTypes = ['AuditFindingsStddevSampFields'];
export const isAuditFindingsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsStddevSampFields"');
    return AuditFindingsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsSumFields_possibleTypes = ['AuditFindingsSumFields'];
export const isAuditFindingsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsSumFields"');
    return AuditFindingsSumFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsVarPopFields_possibleTypes = ['AuditFindingsVarPopFields'];
export const isAuditFindingsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsVarPopFields"');
    return AuditFindingsVarPopFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsVarSampFields_possibleTypes = ['AuditFindingsVarSampFields'];
export const isAuditFindingsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsVarSampFields"');
    return AuditFindingsVarSampFields_possibleTypes.includes(obj.__typename);
};
const AuditFindingsVarianceFields_possibleTypes = ['AuditFindingsVarianceFields'];
export const isAuditFindingsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditFindingsVarianceFields"');
    return AuditFindingsVarianceFields_possibleTypes.includes(obj.__typename);
};
const AuditItems_possibleTypes = ['AuditItems'];
export const isAuditItems = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItems"');
    return AuditItems_possibleTypes.includes(obj.__typename);
};
const AuditItemsAggregate_possibleTypes = ['AuditItemsAggregate'];
export const isAuditItemsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsAggregate"');
    return AuditItemsAggregate_possibleTypes.includes(obj.__typename);
};
const AuditItemsAggregateFields_possibleTypes = ['AuditItemsAggregateFields'];
export const isAuditItemsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsAggregateFields"');
    return AuditItemsAggregateFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsAvgFields_possibleTypes = ['AuditItemsAvgFields'];
export const isAuditItemsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsAvgFields"');
    return AuditItemsAvgFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsMaxFields_possibleTypes = ['AuditItemsMaxFields'];
export const isAuditItemsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsMaxFields"');
    return AuditItemsMaxFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsMinFields_possibleTypes = ['AuditItemsMinFields'];
export const isAuditItemsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsMinFields"');
    return AuditItemsMinFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsMutationResponse_possibleTypes = ['AuditItemsMutationResponse'];
export const isAuditItemsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsMutationResponse"');
    return AuditItemsMutationResponse_possibleTypes.includes(obj.__typename);
};
const AuditItemsStddevFields_possibleTypes = ['AuditItemsStddevFields'];
export const isAuditItemsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsStddevFields"');
    return AuditItemsStddevFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsStddevPopFields_possibleTypes = ['AuditItemsStddevPopFields'];
export const isAuditItemsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsStddevPopFields"');
    return AuditItemsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsStddevSampFields_possibleTypes = ['AuditItemsStddevSampFields'];
export const isAuditItemsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsStddevSampFields"');
    return AuditItemsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsSumFields_possibleTypes = ['AuditItemsSumFields'];
export const isAuditItemsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsSumFields"');
    return AuditItemsSumFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsVarPopFields_possibleTypes = ['AuditItemsVarPopFields'];
export const isAuditItemsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsVarPopFields"');
    return AuditItemsVarPopFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsVarSampFields_possibleTypes = ['AuditItemsVarSampFields'];
export const isAuditItemsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsVarSampFields"');
    return AuditItemsVarSampFields_possibleTypes.includes(obj.__typename);
};
const AuditItemsVarianceFields_possibleTypes = ['AuditItemsVarianceFields'];
export const isAuditItemsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditItemsVarianceFields"');
    return AuditItemsVarianceFields_possibleTypes.includes(obj.__typename);
};
const Audits_possibleTypes = ['Audits'];
export const isAudits = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAudits"');
    return Audits_possibleTypes.includes(obj.__typename);
};
const AuditsAggregate_possibleTypes = ['AuditsAggregate'];
export const isAuditsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsAggregate"');
    return AuditsAggregate_possibleTypes.includes(obj.__typename);
};
const AuditsAggregateFields_possibleTypes = ['AuditsAggregateFields'];
export const isAuditsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsAggregateFields"');
    return AuditsAggregateFields_possibleTypes.includes(obj.__typename);
};
const AuditsAvgFields_possibleTypes = ['AuditsAvgFields'];
export const isAuditsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsAvgFields"');
    return AuditsAvgFields_possibleTypes.includes(obj.__typename);
};
const AuditsMaxFields_possibleTypes = ['AuditsMaxFields'];
export const isAuditsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsMaxFields"');
    return AuditsMaxFields_possibleTypes.includes(obj.__typename);
};
const AuditsMinFields_possibleTypes = ['AuditsMinFields'];
export const isAuditsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsMinFields"');
    return AuditsMinFields_possibleTypes.includes(obj.__typename);
};
const AuditsMutationResponse_possibleTypes = ['AuditsMutationResponse'];
export const isAuditsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsMutationResponse"');
    return AuditsMutationResponse_possibleTypes.includes(obj.__typename);
};
const AuditsStddevFields_possibleTypes = ['AuditsStddevFields'];
export const isAuditsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsStddevFields"');
    return AuditsStddevFields_possibleTypes.includes(obj.__typename);
};
const AuditsStddevPopFields_possibleTypes = ['AuditsStddevPopFields'];
export const isAuditsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsStddevPopFields"');
    return AuditsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const AuditsStddevSampFields_possibleTypes = ['AuditsStddevSampFields'];
export const isAuditsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsStddevSampFields"');
    return AuditsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const AuditsSumFields_possibleTypes = ['AuditsSumFields'];
export const isAuditsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsSumFields"');
    return AuditsSumFields_possibleTypes.includes(obj.__typename);
};
const AuditsVarPopFields_possibleTypes = ['AuditsVarPopFields'];
export const isAuditsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsVarPopFields"');
    return AuditsVarPopFields_possibleTypes.includes(obj.__typename);
};
const AuditsVarSampFields_possibleTypes = ['AuditsVarSampFields'];
export const isAuditsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsVarSampFields"');
    return AuditsVarSampFields_possibleTypes.includes(obj.__typename);
};
const AuditsVarianceFields_possibleTypes = ['AuditsVarianceFields'];
export const isAuditsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isAuditsVarianceFields"');
    return AuditsVarianceFields_possibleTypes.includes(obj.__typename);
};
const Companies_possibleTypes = ['Companies'];
export const isCompanies = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanies"');
    return Companies_possibleTypes.includes(obj.__typename);
};
const CompaniesAggregate_possibleTypes = ['CompaniesAggregate'];
export const isCompaniesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesAggregate"');
    return CompaniesAggregate_possibleTypes.includes(obj.__typename);
};
const CompaniesAggregateFields_possibleTypes = ['CompaniesAggregateFields'];
export const isCompaniesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesAggregateFields"');
    return CompaniesAggregateFields_possibleTypes.includes(obj.__typename);
};
const CompaniesAvgFields_possibleTypes = ['CompaniesAvgFields'];
export const isCompaniesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesAvgFields"');
    return CompaniesAvgFields_possibleTypes.includes(obj.__typename);
};
const CompaniesMaxFields_possibleTypes = ['CompaniesMaxFields'];
export const isCompaniesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesMaxFields"');
    return CompaniesMaxFields_possibleTypes.includes(obj.__typename);
};
const CompaniesMinFields_possibleTypes = ['CompaniesMinFields'];
export const isCompaniesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesMinFields"');
    return CompaniesMinFields_possibleTypes.includes(obj.__typename);
};
const CompaniesStddevFields_possibleTypes = ['CompaniesStddevFields'];
export const isCompaniesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesStddevFields"');
    return CompaniesStddevFields_possibleTypes.includes(obj.__typename);
};
const CompaniesStddevPopFields_possibleTypes = ['CompaniesStddevPopFields'];
export const isCompaniesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesStddevPopFields"');
    return CompaniesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const CompaniesStddevSampFields_possibleTypes = ['CompaniesStddevSampFields'];
export const isCompaniesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesStddevSampFields"');
    return CompaniesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const CompaniesSumFields_possibleTypes = ['CompaniesSumFields'];
export const isCompaniesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesSumFields"');
    return CompaniesSumFields_possibleTypes.includes(obj.__typename);
};
const CompaniesVarPopFields_possibleTypes = ['CompaniesVarPopFields'];
export const isCompaniesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesVarPopFields"');
    return CompaniesVarPopFields_possibleTypes.includes(obj.__typename);
};
const CompaniesVarSampFields_possibleTypes = ['CompaniesVarSampFields'];
export const isCompaniesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesVarSampFields"');
    return CompaniesVarSampFields_possibleTypes.includes(obj.__typename);
};
const CompaniesVarianceFields_possibleTypes = ['CompaniesVarianceFields'];
export const isCompaniesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompaniesVarianceFields"');
    return CompaniesVarianceFields_possibleTypes.includes(obj.__typename);
};
const CompanySettings_possibleTypes = ['CompanySettings'];
export const isCompanySettings = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettings"');
    return CompanySettings_possibleTypes.includes(obj.__typename);
};
const CompanySettingsAggregate_possibleTypes = ['CompanySettingsAggregate'];
export const isCompanySettingsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsAggregate"');
    return CompanySettingsAggregate_possibleTypes.includes(obj.__typename);
};
const CompanySettingsAggregateFields_possibleTypes = ['CompanySettingsAggregateFields'];
export const isCompanySettingsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsAggregateFields"');
    return CompanySettingsAggregateFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsAvgFields_possibleTypes = ['CompanySettingsAvgFields'];
export const isCompanySettingsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsAvgFields"');
    return CompanySettingsAvgFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsMaxFields_possibleTypes = ['CompanySettingsMaxFields'];
export const isCompanySettingsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsMaxFields"');
    return CompanySettingsMaxFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsMinFields_possibleTypes = ['CompanySettingsMinFields'];
export const isCompanySettingsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsMinFields"');
    return CompanySettingsMinFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsMutationResponse_possibleTypes = ['CompanySettingsMutationResponse'];
export const isCompanySettingsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsMutationResponse"');
    return CompanySettingsMutationResponse_possibleTypes.includes(obj.__typename);
};
const CompanySettingsStddevFields_possibleTypes = ['CompanySettingsStddevFields'];
export const isCompanySettingsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsStddevFields"');
    return CompanySettingsStddevFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsStddevPopFields_possibleTypes = ['CompanySettingsStddevPopFields'];
export const isCompanySettingsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsStddevPopFields"');
    return CompanySettingsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsStddevSampFields_possibleTypes = ['CompanySettingsStddevSampFields'];
export const isCompanySettingsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsStddevSampFields"');
    return CompanySettingsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsSumFields_possibleTypes = ['CompanySettingsSumFields'];
export const isCompanySettingsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsSumFields"');
    return CompanySettingsSumFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsVarPopFields_possibleTypes = ['CompanySettingsVarPopFields'];
export const isCompanySettingsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsVarPopFields"');
    return CompanySettingsVarPopFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsVarSampFields_possibleTypes = ['CompanySettingsVarSampFields'];
export const isCompanySettingsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsVarSampFields"');
    return CompanySettingsVarSampFields_possibleTypes.includes(obj.__typename);
};
const CompanySettingsVarianceFields_possibleTypes = ['CompanySettingsVarianceFields'];
export const isCompanySettingsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCompanySettingsVarianceFields"');
    return CompanySettingsVarianceFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplates_possibleTypes = ['ControlPointTemplates'];
export const isControlPointTemplates = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplates"');
    return ControlPointTemplates_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesAggregate_possibleTypes = ['ControlPointTemplatesAggregate'];
export const isControlPointTemplatesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesAggregate"');
    return ControlPointTemplatesAggregate_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesAggregateFields_possibleTypes = ['ControlPointTemplatesAggregateFields'];
export const isControlPointTemplatesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesAggregateFields"');
    return ControlPointTemplatesAggregateFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesAvgFields_possibleTypes = ['ControlPointTemplatesAvgFields'];
export const isControlPointTemplatesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesAvgFields"');
    return ControlPointTemplatesAvgFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesMaxFields_possibleTypes = ['ControlPointTemplatesMaxFields'];
export const isControlPointTemplatesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesMaxFields"');
    return ControlPointTemplatesMaxFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesMinFields_possibleTypes = ['ControlPointTemplatesMinFields'];
export const isControlPointTemplatesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesMinFields"');
    return ControlPointTemplatesMinFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesMutationResponse_possibleTypes = ['ControlPointTemplatesMutationResponse'];
export const isControlPointTemplatesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesMutationResponse"');
    return ControlPointTemplatesMutationResponse_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesStddevFields_possibleTypes = ['ControlPointTemplatesStddevFields'];
export const isControlPointTemplatesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesStddevFields"');
    return ControlPointTemplatesStddevFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesStddevPopFields_possibleTypes = ['ControlPointTemplatesStddevPopFields'];
export const isControlPointTemplatesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesStddevPopFields"');
    return ControlPointTemplatesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesStddevSampFields_possibleTypes = ['ControlPointTemplatesStddevSampFields'];
export const isControlPointTemplatesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesStddevSampFields"');
    return ControlPointTemplatesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesSumFields_possibleTypes = ['ControlPointTemplatesSumFields'];
export const isControlPointTemplatesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesSumFields"');
    return ControlPointTemplatesSumFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesVarPopFields_possibleTypes = ['ControlPointTemplatesVarPopFields'];
export const isControlPointTemplatesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesVarPopFields"');
    return ControlPointTemplatesVarPopFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesVarSampFields_possibleTypes = ['ControlPointTemplatesVarSampFields'];
export const isControlPointTemplatesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesVarSampFields"');
    return ControlPointTemplatesVarSampFields_possibleTypes.includes(obj.__typename);
};
const ControlPointTemplatesVarianceFields_possibleTypes = ['ControlPointTemplatesVarianceFields'];
export const isControlPointTemplatesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointTemplatesVarianceFields"');
    return ControlPointTemplatesVarianceFields_possibleTypes.includes(obj.__typename);
};
const ControlPoints_possibleTypes = ['ControlPoints'];
export const isControlPoints = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPoints"');
    return ControlPoints_possibleTypes.includes(obj.__typename);
};
const ControlPointsAggregate_possibleTypes = ['ControlPointsAggregate'];
export const isControlPointsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsAggregate"');
    return ControlPointsAggregate_possibleTypes.includes(obj.__typename);
};
const ControlPointsAggregateFields_possibleTypes = ['ControlPointsAggregateFields'];
export const isControlPointsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsAggregateFields"');
    return ControlPointsAggregateFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsAvgFields_possibleTypes = ['ControlPointsAvgFields'];
export const isControlPointsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsAvgFields"');
    return ControlPointsAvgFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsMaxFields_possibleTypes = ['ControlPointsMaxFields'];
export const isControlPointsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsMaxFields"');
    return ControlPointsMaxFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsMinFields_possibleTypes = ['ControlPointsMinFields'];
export const isControlPointsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsMinFields"');
    return ControlPointsMinFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsMutationResponse_possibleTypes = ['ControlPointsMutationResponse'];
export const isControlPointsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsMutationResponse"');
    return ControlPointsMutationResponse_possibleTypes.includes(obj.__typename);
};
const ControlPointsStddevFields_possibleTypes = ['ControlPointsStddevFields'];
export const isControlPointsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsStddevFields"');
    return ControlPointsStddevFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsStddevPopFields_possibleTypes = ['ControlPointsStddevPopFields'];
export const isControlPointsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsStddevPopFields"');
    return ControlPointsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsStddevSampFields_possibleTypes = ['ControlPointsStddevSampFields'];
export const isControlPointsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsStddevSampFields"');
    return ControlPointsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsSumFields_possibleTypes = ['ControlPointsSumFields'];
export const isControlPointsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsSumFields"');
    return ControlPointsSumFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsVarPopFields_possibleTypes = ['ControlPointsVarPopFields'];
export const isControlPointsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsVarPopFields"');
    return ControlPointsVarPopFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsVarSampFields_possibleTypes = ['ControlPointsVarSampFields'];
export const isControlPointsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsVarSampFields"');
    return ControlPointsVarSampFields_possibleTypes.includes(obj.__typename);
};
const ControlPointsVarianceFields_possibleTypes = ['ControlPointsVarianceFields'];
export const isControlPointsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isControlPointsVarianceFields"');
    return ControlPointsVarianceFields_possibleTypes.includes(obj.__typename);
};
const CustomFields_possibleTypes = ['CustomFields'];
export const isCustomFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFields"');
    return CustomFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsAggregate_possibleTypes = ['CustomFieldsAggregate'];
export const isCustomFieldsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsAggregate"');
    return CustomFieldsAggregate_possibleTypes.includes(obj.__typename);
};
const CustomFieldsAggregateFields_possibleTypes = ['CustomFieldsAggregateFields'];
export const isCustomFieldsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsAggregateFields"');
    return CustomFieldsAggregateFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsAvgFields_possibleTypes = ['CustomFieldsAvgFields'];
export const isCustomFieldsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsAvgFields"');
    return CustomFieldsAvgFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsMaxFields_possibleTypes = ['CustomFieldsMaxFields'];
export const isCustomFieldsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsMaxFields"');
    return CustomFieldsMaxFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsMinFields_possibleTypes = ['CustomFieldsMinFields'];
export const isCustomFieldsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsMinFields"');
    return CustomFieldsMinFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsMutationResponse_possibleTypes = ['CustomFieldsMutationResponse'];
export const isCustomFieldsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsMutationResponse"');
    return CustomFieldsMutationResponse_possibleTypes.includes(obj.__typename);
};
const CustomFieldsStddevFields_possibleTypes = ['CustomFieldsStddevFields'];
export const isCustomFieldsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsStddevFields"');
    return CustomFieldsStddevFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsStddevPopFields_possibleTypes = ['CustomFieldsStddevPopFields'];
export const isCustomFieldsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsStddevPopFields"');
    return CustomFieldsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsStddevSampFields_possibleTypes = ['CustomFieldsStddevSampFields'];
export const isCustomFieldsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsStddevSampFields"');
    return CustomFieldsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsSumFields_possibleTypes = ['CustomFieldsSumFields'];
export const isCustomFieldsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsSumFields"');
    return CustomFieldsSumFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsVarPopFields_possibleTypes = ['CustomFieldsVarPopFields'];
export const isCustomFieldsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsVarPopFields"');
    return CustomFieldsVarPopFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsVarSampFields_possibleTypes = ['CustomFieldsVarSampFields'];
export const isCustomFieldsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsVarSampFields"');
    return CustomFieldsVarSampFields_possibleTypes.includes(obj.__typename);
};
const CustomFieldsVarianceFields_possibleTypes = ['CustomFieldsVarianceFields'];
export const isCustomFieldsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isCustomFieldsVarianceFields"');
    return CustomFieldsVarianceFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasures_possibleTypes = ['EnergyMeasures'];
export const isEnergyMeasures = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasures"');
    return EnergyMeasures_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresAggregate_possibleTypes = ['EnergyMeasuresAggregate'];
export const isEnergyMeasuresAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresAggregate"');
    return EnergyMeasuresAggregate_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresAggregateFields_possibleTypes = ['EnergyMeasuresAggregateFields'];
export const isEnergyMeasuresAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresAggregateFields"');
    return EnergyMeasuresAggregateFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresAvgFields_possibleTypes = ['EnergyMeasuresAvgFields'];
export const isEnergyMeasuresAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresAvgFields"');
    return EnergyMeasuresAvgFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresMaxFields_possibleTypes = ['EnergyMeasuresMaxFields'];
export const isEnergyMeasuresMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresMaxFields"');
    return EnergyMeasuresMaxFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresMinFields_possibleTypes = ['EnergyMeasuresMinFields'];
export const isEnergyMeasuresMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresMinFields"');
    return EnergyMeasuresMinFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresMutationResponse_possibleTypes = ['EnergyMeasuresMutationResponse'];
export const isEnergyMeasuresMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresMutationResponse"');
    return EnergyMeasuresMutationResponse_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresStddevFields_possibleTypes = ['EnergyMeasuresStddevFields'];
export const isEnergyMeasuresStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresStddevFields"');
    return EnergyMeasuresStddevFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresStddevPopFields_possibleTypes = ['EnergyMeasuresStddevPopFields'];
export const isEnergyMeasuresStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresStddevPopFields"');
    return EnergyMeasuresStddevPopFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresStddevSampFields_possibleTypes = ['EnergyMeasuresStddevSampFields'];
export const isEnergyMeasuresStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresStddevSampFields"');
    return EnergyMeasuresStddevSampFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresSumFields_possibleTypes = ['EnergyMeasuresSumFields'];
export const isEnergyMeasuresSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresSumFields"');
    return EnergyMeasuresSumFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresVarPopFields_possibleTypes = ['EnergyMeasuresVarPopFields'];
export const isEnergyMeasuresVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresVarPopFields"');
    return EnergyMeasuresVarPopFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresVarSampFields_possibleTypes = ['EnergyMeasuresVarSampFields'];
export const isEnergyMeasuresVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresVarSampFields"');
    return EnergyMeasuresVarSampFields_possibleTypes.includes(obj.__typename);
};
const EnergyMeasuresVarianceFields_possibleTypes = ['EnergyMeasuresVarianceFields'];
export const isEnergyMeasuresVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergyMeasuresVarianceFields"');
    return EnergyMeasuresVarianceFields_possibleTypes.includes(obj.__typename);
};
const EnergySources_possibleTypes = ['EnergySources'];
export const isEnergySources = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySources"');
    return EnergySources_possibleTypes.includes(obj.__typename);
};
const EnergySourcesAggregate_possibleTypes = ['EnergySourcesAggregate'];
export const isEnergySourcesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesAggregate"');
    return EnergySourcesAggregate_possibleTypes.includes(obj.__typename);
};
const EnergySourcesAggregateFields_possibleTypes = ['EnergySourcesAggregateFields'];
export const isEnergySourcesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesAggregateFields"');
    return EnergySourcesAggregateFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesAvgFields_possibleTypes = ['EnergySourcesAvgFields'];
export const isEnergySourcesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesAvgFields"');
    return EnergySourcesAvgFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesMaxFields_possibleTypes = ['EnergySourcesMaxFields'];
export const isEnergySourcesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesMaxFields"');
    return EnergySourcesMaxFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesMinFields_possibleTypes = ['EnergySourcesMinFields'];
export const isEnergySourcesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesMinFields"');
    return EnergySourcesMinFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesMutationResponse_possibleTypes = ['EnergySourcesMutationResponse'];
export const isEnergySourcesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesMutationResponse"');
    return EnergySourcesMutationResponse_possibleTypes.includes(obj.__typename);
};
const EnergySourcesStddevFields_possibleTypes = ['EnergySourcesStddevFields'];
export const isEnergySourcesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesStddevFields"');
    return EnergySourcesStddevFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesStddevPopFields_possibleTypes = ['EnergySourcesStddevPopFields'];
export const isEnergySourcesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesStddevPopFields"');
    return EnergySourcesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesStddevSampFields_possibleTypes = ['EnergySourcesStddevSampFields'];
export const isEnergySourcesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesStddevSampFields"');
    return EnergySourcesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesSumFields_possibleTypes = ['EnergySourcesSumFields'];
export const isEnergySourcesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesSumFields"');
    return EnergySourcesSumFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesVarPopFields_possibleTypes = ['EnergySourcesVarPopFields'];
export const isEnergySourcesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesVarPopFields"');
    return EnergySourcesVarPopFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesVarSampFields_possibleTypes = ['EnergySourcesVarSampFields'];
export const isEnergySourcesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesVarSampFields"');
    return EnergySourcesVarSampFields_possibleTypes.includes(obj.__typename);
};
const EnergySourcesVarianceFields_possibleTypes = ['EnergySourcesVarianceFields'];
export const isEnergySourcesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isEnergySourcesVarianceFields"');
    return EnergySourcesVarianceFields_possibleTypes.includes(obj.__typename);
};
const Hazards_possibleTypes = ['Hazards'];
export const isHazards = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazards"');
    return Hazards_possibleTypes.includes(obj.__typename);
};
const HazardsAggregate_possibleTypes = ['HazardsAggregate'];
export const isHazardsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsAggregate"');
    return HazardsAggregate_possibleTypes.includes(obj.__typename);
};
const HazardsAggregateFields_possibleTypes = ['HazardsAggregateFields'];
export const isHazardsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsAggregateFields"');
    return HazardsAggregateFields_possibleTypes.includes(obj.__typename);
};
const HazardsAvgFields_possibleTypes = ['HazardsAvgFields'];
export const isHazardsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsAvgFields"');
    return HazardsAvgFields_possibleTypes.includes(obj.__typename);
};
const HazardsMaxFields_possibleTypes = ['HazardsMaxFields'];
export const isHazardsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsMaxFields"');
    return HazardsMaxFields_possibleTypes.includes(obj.__typename);
};
const HazardsMinFields_possibleTypes = ['HazardsMinFields'];
export const isHazardsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsMinFields"');
    return HazardsMinFields_possibleTypes.includes(obj.__typename);
};
const HazardsMutationResponse_possibleTypes = ['HazardsMutationResponse'];
export const isHazardsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsMutationResponse"');
    return HazardsMutationResponse_possibleTypes.includes(obj.__typename);
};
const HazardsStddevFields_possibleTypes = ['HazardsStddevFields'];
export const isHazardsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsStddevFields"');
    return HazardsStddevFields_possibleTypes.includes(obj.__typename);
};
const HazardsStddevPopFields_possibleTypes = ['HazardsStddevPopFields'];
export const isHazardsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsStddevPopFields"');
    return HazardsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const HazardsStddevSampFields_possibleTypes = ['HazardsStddevSampFields'];
export const isHazardsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsStddevSampFields"');
    return HazardsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const HazardsSumFields_possibleTypes = ['HazardsSumFields'];
export const isHazardsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsSumFields"');
    return HazardsSumFields_possibleTypes.includes(obj.__typename);
};
const HazardsVarPopFields_possibleTypes = ['HazardsVarPopFields'];
export const isHazardsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsVarPopFields"');
    return HazardsVarPopFields_possibleTypes.includes(obj.__typename);
};
const HazardsVarSampFields_possibleTypes = ['HazardsVarSampFields'];
export const isHazardsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsVarSampFields"');
    return HazardsVarSampFields_possibleTypes.includes(obj.__typename);
};
const HazardsVarianceFields_possibleTypes = ['HazardsVarianceFields'];
export const isHazardsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isHazardsVarianceFields"');
    return HazardsVarianceFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotos_possibleTypes = ['InspectionFindingPhotos'];
export const isInspectionFindingPhotos = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotos"');
    return InspectionFindingPhotos_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosAggregate_possibleTypes = ['InspectionFindingPhotosAggregate'];
export const isInspectionFindingPhotosAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosAggregate"');
    return InspectionFindingPhotosAggregate_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosAggregateFields_possibleTypes = ['InspectionFindingPhotosAggregateFields'];
export const isInspectionFindingPhotosAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosAggregateFields"');
    return InspectionFindingPhotosAggregateFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosAvgFields_possibleTypes = ['InspectionFindingPhotosAvgFields'];
export const isInspectionFindingPhotosAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosAvgFields"');
    return InspectionFindingPhotosAvgFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosMaxFields_possibleTypes = ['InspectionFindingPhotosMaxFields'];
export const isInspectionFindingPhotosMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosMaxFields"');
    return InspectionFindingPhotosMaxFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosMinFields_possibleTypes = ['InspectionFindingPhotosMinFields'];
export const isInspectionFindingPhotosMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosMinFields"');
    return InspectionFindingPhotosMinFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosMutationResponse_possibleTypes = ['InspectionFindingPhotosMutationResponse'];
export const isInspectionFindingPhotosMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosMutationResponse"');
    return InspectionFindingPhotosMutationResponse_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosStddevFields_possibleTypes = ['InspectionFindingPhotosStddevFields'];
export const isInspectionFindingPhotosStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosStddevFields"');
    return InspectionFindingPhotosStddevFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosStddevPopFields_possibleTypes = ['InspectionFindingPhotosStddevPopFields'];
export const isInspectionFindingPhotosStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosStddevPopFields"');
    return InspectionFindingPhotosStddevPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosStddevSampFields_possibleTypes = ['InspectionFindingPhotosStddevSampFields'];
export const isInspectionFindingPhotosStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosStddevSampFields"');
    return InspectionFindingPhotosStddevSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosSumFields_possibleTypes = ['InspectionFindingPhotosSumFields'];
export const isInspectionFindingPhotosSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosSumFields"');
    return InspectionFindingPhotosSumFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosVarPopFields_possibleTypes = ['InspectionFindingPhotosVarPopFields'];
export const isInspectionFindingPhotosVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosVarPopFields"');
    return InspectionFindingPhotosVarPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosVarSampFields_possibleTypes = ['InspectionFindingPhotosVarSampFields'];
export const isInspectionFindingPhotosVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosVarSampFields"');
    return InspectionFindingPhotosVarSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingPhotosVarianceFields_possibleTypes = ['InspectionFindingPhotosVarianceFields'];
export const isInspectionFindingPhotosVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingPhotosVarianceFields"');
    return InspectionFindingPhotosVarianceFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindings_possibleTypes = ['InspectionFindings'];
export const isInspectionFindings = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindings"');
    return InspectionFindings_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsAggregate_possibleTypes = ['InspectionFindingsAggregate'];
export const isInspectionFindingsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsAggregate"');
    return InspectionFindingsAggregate_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsAggregateFields_possibleTypes = ['InspectionFindingsAggregateFields'];
export const isInspectionFindingsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsAggregateFields"');
    return InspectionFindingsAggregateFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsAvgFields_possibleTypes = ['InspectionFindingsAvgFields'];
export const isInspectionFindingsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsAvgFields"');
    return InspectionFindingsAvgFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsMaxFields_possibleTypes = ['InspectionFindingsMaxFields'];
export const isInspectionFindingsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsMaxFields"');
    return InspectionFindingsMaxFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsMinFields_possibleTypes = ['InspectionFindingsMinFields'];
export const isInspectionFindingsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsMinFields"');
    return InspectionFindingsMinFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsMutationResponse_possibleTypes = ['InspectionFindingsMutationResponse'];
export const isInspectionFindingsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsMutationResponse"');
    return InspectionFindingsMutationResponse_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsStddevFields_possibleTypes = ['InspectionFindingsStddevFields'];
export const isInspectionFindingsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsStddevFields"');
    return InspectionFindingsStddevFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsStddevPopFields_possibleTypes = ['InspectionFindingsStddevPopFields'];
export const isInspectionFindingsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsStddevPopFields"');
    return InspectionFindingsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsStddevSampFields_possibleTypes = ['InspectionFindingsStddevSampFields'];
export const isInspectionFindingsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsStddevSampFields"');
    return InspectionFindingsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsSumFields_possibleTypes = ['InspectionFindingsSumFields'];
export const isInspectionFindingsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsSumFields"');
    return InspectionFindingsSumFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsVarPopFields_possibleTypes = ['InspectionFindingsVarPopFields'];
export const isInspectionFindingsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsVarPopFields"');
    return InspectionFindingsVarPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsVarSampFields_possibleTypes = ['InspectionFindingsVarSampFields'];
export const isInspectionFindingsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsVarSampFields"');
    return InspectionFindingsVarSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionFindingsVarianceFields_possibleTypes = ['InspectionFindingsVarianceFields'];
export const isInspectionFindingsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionFindingsVarianceFields"');
    return InspectionFindingsVarianceFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotos_possibleTypes = ['InspectionItemPhotos'];
export const isInspectionItemPhotos = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotos"');
    return InspectionItemPhotos_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosAggregate_possibleTypes = ['InspectionItemPhotosAggregate'];
export const isInspectionItemPhotosAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosAggregate"');
    return InspectionItemPhotosAggregate_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosAggregateFields_possibleTypes = ['InspectionItemPhotosAggregateFields'];
export const isInspectionItemPhotosAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosAggregateFields"');
    return InspectionItemPhotosAggregateFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosAvgFields_possibleTypes = ['InspectionItemPhotosAvgFields'];
export const isInspectionItemPhotosAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosAvgFields"');
    return InspectionItemPhotosAvgFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosMaxFields_possibleTypes = ['InspectionItemPhotosMaxFields'];
export const isInspectionItemPhotosMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosMaxFields"');
    return InspectionItemPhotosMaxFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosMinFields_possibleTypes = ['InspectionItemPhotosMinFields'];
export const isInspectionItemPhotosMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosMinFields"');
    return InspectionItemPhotosMinFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosMutationResponse_possibleTypes = ['InspectionItemPhotosMutationResponse'];
export const isInspectionItemPhotosMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosMutationResponse"');
    return InspectionItemPhotosMutationResponse_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosStddevFields_possibleTypes = ['InspectionItemPhotosStddevFields'];
export const isInspectionItemPhotosStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosStddevFields"');
    return InspectionItemPhotosStddevFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosStddevPopFields_possibleTypes = ['InspectionItemPhotosStddevPopFields'];
export const isInspectionItemPhotosStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosStddevPopFields"');
    return InspectionItemPhotosStddevPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosStddevSampFields_possibleTypes = ['InspectionItemPhotosStddevSampFields'];
export const isInspectionItemPhotosStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosStddevSampFields"');
    return InspectionItemPhotosStddevSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosSumFields_possibleTypes = ['InspectionItemPhotosSumFields'];
export const isInspectionItemPhotosSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosSumFields"');
    return InspectionItemPhotosSumFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosVarPopFields_possibleTypes = ['InspectionItemPhotosVarPopFields'];
export const isInspectionItemPhotosVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosVarPopFields"');
    return InspectionItemPhotosVarPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosVarSampFields_possibleTypes = ['InspectionItemPhotosVarSampFields'];
export const isInspectionItemPhotosVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosVarSampFields"');
    return InspectionItemPhotosVarSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemPhotosVarianceFields_possibleTypes = ['InspectionItemPhotosVarianceFields'];
export const isInspectionItemPhotosVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemPhotosVarianceFields"');
    return InspectionItemPhotosVarianceFields_possibleTypes.includes(obj.__typename);
};
const InspectionItems_possibleTypes = ['InspectionItems'];
export const isInspectionItems = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItems"');
    return InspectionItems_possibleTypes.includes(obj.__typename);
};
const InspectionItemsAggregate_possibleTypes = ['InspectionItemsAggregate'];
export const isInspectionItemsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsAggregate"');
    return InspectionItemsAggregate_possibleTypes.includes(obj.__typename);
};
const InspectionItemsAggregateFields_possibleTypes = ['InspectionItemsAggregateFields'];
export const isInspectionItemsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsAggregateFields"');
    return InspectionItemsAggregateFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsAvgFields_possibleTypes = ['InspectionItemsAvgFields'];
export const isInspectionItemsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsAvgFields"');
    return InspectionItemsAvgFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsMaxFields_possibleTypes = ['InspectionItemsMaxFields'];
export const isInspectionItemsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsMaxFields"');
    return InspectionItemsMaxFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsMinFields_possibleTypes = ['InspectionItemsMinFields'];
export const isInspectionItemsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsMinFields"');
    return InspectionItemsMinFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsMutationResponse_possibleTypes = ['InspectionItemsMutationResponse'];
export const isInspectionItemsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsMutationResponse"');
    return InspectionItemsMutationResponse_possibleTypes.includes(obj.__typename);
};
const InspectionItemsStddevFields_possibleTypes = ['InspectionItemsStddevFields'];
export const isInspectionItemsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsStddevFields"');
    return InspectionItemsStddevFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsStddevPopFields_possibleTypes = ['InspectionItemsStddevPopFields'];
export const isInspectionItemsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsStddevPopFields"');
    return InspectionItemsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsStddevSampFields_possibleTypes = ['InspectionItemsStddevSampFields'];
export const isInspectionItemsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsStddevSampFields"');
    return InspectionItemsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsSumFields_possibleTypes = ['InspectionItemsSumFields'];
export const isInspectionItemsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsSumFields"');
    return InspectionItemsSumFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsVarPopFields_possibleTypes = ['InspectionItemsVarPopFields'];
export const isInspectionItemsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsVarPopFields"');
    return InspectionItemsVarPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsVarSampFields_possibleTypes = ['InspectionItemsVarSampFields'];
export const isInspectionItemsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsVarSampFields"');
    return InspectionItemsVarSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionItemsVarianceFields_possibleTypes = ['InspectionItemsVarianceFields'];
export const isInspectionItemsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionItemsVarianceFields"');
    return InspectionItemsVarianceFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasons_possibleTypes = ['InspectionReasons'];
export const isInspectionReasons = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasons"');
    return InspectionReasons_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsAggregate_possibleTypes = ['InspectionReasonsAggregate'];
export const isInspectionReasonsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsAggregate"');
    return InspectionReasonsAggregate_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsAggregateFields_possibleTypes = ['InspectionReasonsAggregateFields'];
export const isInspectionReasonsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsAggregateFields"');
    return InspectionReasonsAggregateFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsAvgFields_possibleTypes = ['InspectionReasonsAvgFields'];
export const isInspectionReasonsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsAvgFields"');
    return InspectionReasonsAvgFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsMaxFields_possibleTypes = ['InspectionReasonsMaxFields'];
export const isInspectionReasonsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsMaxFields"');
    return InspectionReasonsMaxFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsMinFields_possibleTypes = ['InspectionReasonsMinFields'];
export const isInspectionReasonsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsMinFields"');
    return InspectionReasonsMinFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsMutationResponse_possibleTypes = ['InspectionReasonsMutationResponse'];
export const isInspectionReasonsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsMutationResponse"');
    return InspectionReasonsMutationResponse_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsStddevFields_possibleTypes = ['InspectionReasonsStddevFields'];
export const isInspectionReasonsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsStddevFields"');
    return InspectionReasonsStddevFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsStddevPopFields_possibleTypes = ['InspectionReasonsStddevPopFields'];
export const isInspectionReasonsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsStddevPopFields"');
    return InspectionReasonsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsStddevSampFields_possibleTypes = ['InspectionReasonsStddevSampFields'];
export const isInspectionReasonsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsStddevSampFields"');
    return InspectionReasonsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsSumFields_possibleTypes = ['InspectionReasonsSumFields'];
export const isInspectionReasonsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsSumFields"');
    return InspectionReasonsSumFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsVarPopFields_possibleTypes = ['InspectionReasonsVarPopFields'];
export const isInspectionReasonsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsVarPopFields"');
    return InspectionReasonsVarPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsVarSampFields_possibleTypes = ['InspectionReasonsVarSampFields'];
export const isInspectionReasonsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsVarSampFields"');
    return InspectionReasonsVarSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionReasonsVarianceFields_possibleTypes = ['InspectionReasonsVarianceFields'];
export const isInspectionReasonsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionReasonsVarianceFields"');
    return InspectionReasonsVarianceFields_possibleTypes.includes(obj.__typename);
};
const Inspections_possibleTypes = ['Inspections'];
export const isInspections = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspections"');
    return Inspections_possibleTypes.includes(obj.__typename);
};
const InspectionsAggregate_possibleTypes = ['InspectionsAggregate'];
export const isInspectionsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsAggregate"');
    return InspectionsAggregate_possibleTypes.includes(obj.__typename);
};
const InspectionsAggregateFields_possibleTypes = ['InspectionsAggregateFields'];
export const isInspectionsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsAggregateFields"');
    return InspectionsAggregateFields_possibleTypes.includes(obj.__typename);
};
const InspectionsAvgFields_possibleTypes = ['InspectionsAvgFields'];
export const isInspectionsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsAvgFields"');
    return InspectionsAvgFields_possibleTypes.includes(obj.__typename);
};
const InspectionsMaxFields_possibleTypes = ['InspectionsMaxFields'];
export const isInspectionsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsMaxFields"');
    return InspectionsMaxFields_possibleTypes.includes(obj.__typename);
};
const InspectionsMinFields_possibleTypes = ['InspectionsMinFields'];
export const isInspectionsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsMinFields"');
    return InspectionsMinFields_possibleTypes.includes(obj.__typename);
};
const InspectionsMutationResponse_possibleTypes = ['InspectionsMutationResponse'];
export const isInspectionsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsMutationResponse"');
    return InspectionsMutationResponse_possibleTypes.includes(obj.__typename);
};
const InspectionsStddevFields_possibleTypes = ['InspectionsStddevFields'];
export const isInspectionsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsStddevFields"');
    return InspectionsStddevFields_possibleTypes.includes(obj.__typename);
};
const InspectionsStddevPopFields_possibleTypes = ['InspectionsStddevPopFields'];
export const isInspectionsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsStddevPopFields"');
    return InspectionsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionsStddevSampFields_possibleTypes = ['InspectionsStddevSampFields'];
export const isInspectionsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsStddevSampFields"');
    return InspectionsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionsSumFields_possibleTypes = ['InspectionsSumFields'];
export const isInspectionsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsSumFields"');
    return InspectionsSumFields_possibleTypes.includes(obj.__typename);
};
const InspectionsVarPopFields_possibleTypes = ['InspectionsVarPopFields'];
export const isInspectionsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsVarPopFields"');
    return InspectionsVarPopFields_possibleTypes.includes(obj.__typename);
};
const InspectionsVarSampFields_possibleTypes = ['InspectionsVarSampFields'];
export const isInspectionsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsVarSampFields"');
    return InspectionsVarSampFields_possibleTypes.includes(obj.__typename);
};
const InspectionsVarianceFields_possibleTypes = ['InspectionsVarianceFields'];
export const isInspectionsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isInspectionsVarianceFields"');
    return InspectionsVarianceFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevices_possibleTypes = ['IsolationDevices'];
export const isIsolationDevices = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevices"');
    return IsolationDevices_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesAggregate_possibleTypes = ['IsolationDevicesAggregate'];
export const isIsolationDevicesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesAggregate"');
    return IsolationDevicesAggregate_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesAggregateFields_possibleTypes = ['IsolationDevicesAggregateFields'];
export const isIsolationDevicesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesAggregateFields"');
    return IsolationDevicesAggregateFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesAvgFields_possibleTypes = ['IsolationDevicesAvgFields'];
export const isIsolationDevicesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesAvgFields"');
    return IsolationDevicesAvgFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesMaxFields_possibleTypes = ['IsolationDevicesMaxFields'];
export const isIsolationDevicesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesMaxFields"');
    return IsolationDevicesMaxFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesMinFields_possibleTypes = ['IsolationDevicesMinFields'];
export const isIsolationDevicesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesMinFields"');
    return IsolationDevicesMinFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesMutationResponse_possibleTypes = ['IsolationDevicesMutationResponse'];
export const isIsolationDevicesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesMutationResponse"');
    return IsolationDevicesMutationResponse_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesStddevFields_possibleTypes = ['IsolationDevicesStddevFields'];
export const isIsolationDevicesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesStddevFields"');
    return IsolationDevicesStddevFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesStddevPopFields_possibleTypes = ['IsolationDevicesStddevPopFields'];
export const isIsolationDevicesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesStddevPopFields"');
    return IsolationDevicesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesStddevSampFields_possibleTypes = ['IsolationDevicesStddevSampFields'];
export const isIsolationDevicesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesStddevSampFields"');
    return IsolationDevicesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesSumFields_possibleTypes = ['IsolationDevicesSumFields'];
export const isIsolationDevicesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesSumFields"');
    return IsolationDevicesSumFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesVarPopFields_possibleTypes = ['IsolationDevicesVarPopFields'];
export const isIsolationDevicesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesVarPopFields"');
    return IsolationDevicesVarPopFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesVarSampFields_possibleTypes = ['IsolationDevicesVarSampFields'];
export const isIsolationDevicesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesVarSampFields"');
    return IsolationDevicesVarSampFields_possibleTypes.includes(obj.__typename);
};
const IsolationDevicesVarianceFields_possibleTypes = ['IsolationDevicesVarianceFields'];
export const isIsolationDevicesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isIsolationDevicesVarianceFields"');
    return IsolationDevicesVarianceFields_possibleTypes.includes(obj.__typename);
};
const Languages_possibleTypes = ['Languages'];
export const isLanguages = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLanguages"');
    return Languages_possibleTypes.includes(obj.__typename);
};
const LanguagesMutationResponse_possibleTypes = ['LanguagesMutationResponse'];
export const isLanguagesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLanguagesMutationResponse"');
    return LanguagesMutationResponse_possibleTypes.includes(obj.__typename);
};
const Locations_possibleTypes = ['Locations'];
export const isLocations = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocations"');
    return Locations_possibleTypes.includes(obj.__typename);
};
const LocationsAggregate_possibleTypes = ['LocationsAggregate'];
export const isLocationsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsAggregate"');
    return LocationsAggregate_possibleTypes.includes(obj.__typename);
};
const LocationsAggregateFields_possibleTypes = ['LocationsAggregateFields'];
export const isLocationsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsAggregateFields"');
    return LocationsAggregateFields_possibleTypes.includes(obj.__typename);
};
const LocationsAvgFields_possibleTypes = ['LocationsAvgFields'];
export const isLocationsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsAvgFields"');
    return LocationsAvgFields_possibleTypes.includes(obj.__typename);
};
const LocationsMaxFields_possibleTypes = ['LocationsMaxFields'];
export const isLocationsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsMaxFields"');
    return LocationsMaxFields_possibleTypes.includes(obj.__typename);
};
const LocationsMinFields_possibleTypes = ['LocationsMinFields'];
export const isLocationsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsMinFields"');
    return LocationsMinFields_possibleTypes.includes(obj.__typename);
};
const LocationsMutationResponse_possibleTypes = ['LocationsMutationResponse'];
export const isLocationsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsMutationResponse"');
    return LocationsMutationResponse_possibleTypes.includes(obj.__typename);
};
const LocationsStddevFields_possibleTypes = ['LocationsStddevFields'];
export const isLocationsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsStddevFields"');
    return LocationsStddevFields_possibleTypes.includes(obj.__typename);
};
const LocationsStddevPopFields_possibleTypes = ['LocationsStddevPopFields'];
export const isLocationsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsStddevPopFields"');
    return LocationsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const LocationsStddevSampFields_possibleTypes = ['LocationsStddevSampFields'];
export const isLocationsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsStddevSampFields"');
    return LocationsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const LocationsSumFields_possibleTypes = ['LocationsSumFields'];
export const isLocationsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsSumFields"');
    return LocationsSumFields_possibleTypes.includes(obj.__typename);
};
const LocationsVarPopFields_possibleTypes = ['LocationsVarPopFields'];
export const isLocationsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsVarPopFields"');
    return LocationsVarPopFields_possibleTypes.includes(obj.__typename);
};
const LocationsVarSampFields_possibleTypes = ['LocationsVarSampFields'];
export const isLocationsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsVarSampFields"');
    return LocationsVarSampFields_possibleTypes.includes(obj.__typename);
};
const LocationsVarianceFields_possibleTypes = ['LocationsVarianceFields'];
export const isLocationsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLocationsVarianceFields"');
    return LocationsVarianceFields_possibleTypes.includes(obj.__typename);
};
const Permissions_possibleTypes = ['Permissions'];
export const isPermissions = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isPermissions"');
    return Permissions_possibleTypes.includes(obj.__typename);
};
const ProcedureHazards_possibleTypes = ['ProcedureHazards'];
export const isProcedureHazards = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazards"');
    return ProcedureHazards_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsAggregate_possibleTypes = ['ProcedureHazardsAggregate'];
export const isProcedureHazardsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsAggregate"');
    return ProcedureHazardsAggregate_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsAggregateFields_possibleTypes = ['ProcedureHazardsAggregateFields'];
export const isProcedureHazardsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsAggregateFields"');
    return ProcedureHazardsAggregateFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsAvgFields_possibleTypes = ['ProcedureHazardsAvgFields'];
export const isProcedureHazardsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsAvgFields"');
    return ProcedureHazardsAvgFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsMaxFields_possibleTypes = ['ProcedureHazardsMaxFields'];
export const isProcedureHazardsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsMaxFields"');
    return ProcedureHazardsMaxFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsMinFields_possibleTypes = ['ProcedureHazardsMinFields'];
export const isProcedureHazardsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsMinFields"');
    return ProcedureHazardsMinFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsMutationResponse_possibleTypes = ['ProcedureHazardsMutationResponse'];
export const isProcedureHazardsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsMutationResponse"');
    return ProcedureHazardsMutationResponse_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsStddevFields_possibleTypes = ['ProcedureHazardsStddevFields'];
export const isProcedureHazardsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsStddevFields"');
    return ProcedureHazardsStddevFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsStddevPopFields_possibleTypes = ['ProcedureHazardsStddevPopFields'];
export const isProcedureHazardsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsStddevPopFields"');
    return ProcedureHazardsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsStddevSampFields_possibleTypes = ['ProcedureHazardsStddevSampFields'];
export const isProcedureHazardsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsStddevSampFields"');
    return ProcedureHazardsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsSumFields_possibleTypes = ['ProcedureHazardsSumFields'];
export const isProcedureHazardsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsSumFields"');
    return ProcedureHazardsSumFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsVarPopFields_possibleTypes = ['ProcedureHazardsVarPopFields'];
export const isProcedureHazardsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsVarPopFields"');
    return ProcedureHazardsVarPopFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsVarSampFields_possibleTypes = ['ProcedureHazardsVarSampFields'];
export const isProcedureHazardsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsVarSampFields"');
    return ProcedureHazardsVarSampFields_possibleTypes.includes(obj.__typename);
};
const ProcedureHazardsVarianceFields_possibleTypes = ['ProcedureHazardsVarianceFields'];
export const isProcedureHazardsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureHazardsVarianceFields"');
    return ProcedureHazardsVarianceFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplates_possibleTypes = ['ProcedureTemplates'];
export const isProcedureTemplates = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplates"');
    return ProcedureTemplates_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesAggregate_possibleTypes = ['ProcedureTemplatesAggregate'];
export const isProcedureTemplatesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesAggregate"');
    return ProcedureTemplatesAggregate_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesAggregateFields_possibleTypes = ['ProcedureTemplatesAggregateFields'];
export const isProcedureTemplatesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesAggregateFields"');
    return ProcedureTemplatesAggregateFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesAvgFields_possibleTypes = ['ProcedureTemplatesAvgFields'];
export const isProcedureTemplatesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesAvgFields"');
    return ProcedureTemplatesAvgFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesMaxFields_possibleTypes = ['ProcedureTemplatesMaxFields'];
export const isProcedureTemplatesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesMaxFields"');
    return ProcedureTemplatesMaxFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesMinFields_possibleTypes = ['ProcedureTemplatesMinFields'];
export const isProcedureTemplatesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesMinFields"');
    return ProcedureTemplatesMinFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesMutationResponse_possibleTypes = ['ProcedureTemplatesMutationResponse'];
export const isProcedureTemplatesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesMutationResponse"');
    return ProcedureTemplatesMutationResponse_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesStddevFields_possibleTypes = ['ProcedureTemplatesStddevFields'];
export const isProcedureTemplatesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesStddevFields"');
    return ProcedureTemplatesStddevFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesStddevPopFields_possibleTypes = ['ProcedureTemplatesStddevPopFields'];
export const isProcedureTemplatesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesStddevPopFields"');
    return ProcedureTemplatesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesStddevSampFields_possibleTypes = ['ProcedureTemplatesStddevSampFields'];
export const isProcedureTemplatesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesStddevSampFields"');
    return ProcedureTemplatesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesSumFields_possibleTypes = ['ProcedureTemplatesSumFields'];
export const isProcedureTemplatesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesSumFields"');
    return ProcedureTemplatesSumFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesVarPopFields_possibleTypes = ['ProcedureTemplatesVarPopFields'];
export const isProcedureTemplatesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesVarPopFields"');
    return ProcedureTemplatesVarPopFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesVarSampFields_possibleTypes = ['ProcedureTemplatesVarSampFields'];
export const isProcedureTemplatesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesVarSampFields"');
    return ProcedureTemplatesVarSampFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTemplatesVarianceFields_possibleTypes = ['ProcedureTemplatesVarianceFields'];
export const isProcedureTemplatesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTemplatesVarianceFields"');
    return ProcedureTemplatesVarianceFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypes_possibleTypes = ['ProcedureTypes'];
export const isProcedureTypes = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypes"');
    return ProcedureTypes_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesAggregate_possibleTypes = ['ProcedureTypesAggregate'];
export const isProcedureTypesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesAggregate"');
    return ProcedureTypesAggregate_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesAggregateFields_possibleTypes = ['ProcedureTypesAggregateFields'];
export const isProcedureTypesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesAggregateFields"');
    return ProcedureTypesAggregateFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesAvgFields_possibleTypes = ['ProcedureTypesAvgFields'];
export const isProcedureTypesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesAvgFields"');
    return ProcedureTypesAvgFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesMaxFields_possibleTypes = ['ProcedureTypesMaxFields'];
export const isProcedureTypesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesMaxFields"');
    return ProcedureTypesMaxFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesMinFields_possibleTypes = ['ProcedureTypesMinFields'];
export const isProcedureTypesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesMinFields"');
    return ProcedureTypesMinFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesMutationResponse_possibleTypes = ['ProcedureTypesMutationResponse'];
export const isProcedureTypesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesMutationResponse"');
    return ProcedureTypesMutationResponse_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesStddevFields_possibleTypes = ['ProcedureTypesStddevFields'];
export const isProcedureTypesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesStddevFields"');
    return ProcedureTypesStddevFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesStddevPopFields_possibleTypes = ['ProcedureTypesStddevPopFields'];
export const isProcedureTypesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesStddevPopFields"');
    return ProcedureTypesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesStddevSampFields_possibleTypes = ['ProcedureTypesStddevSampFields'];
export const isProcedureTypesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesStddevSampFields"');
    return ProcedureTypesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesSumFields_possibleTypes = ['ProcedureTypesSumFields'];
export const isProcedureTypesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesSumFields"');
    return ProcedureTypesSumFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesVarPopFields_possibleTypes = ['ProcedureTypesVarPopFields'];
export const isProcedureTypesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesVarPopFields"');
    return ProcedureTypesVarPopFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesVarSampFields_possibleTypes = ['ProcedureTypesVarSampFields'];
export const isProcedureTypesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesVarSampFields"');
    return ProcedureTypesVarSampFields_possibleTypes.includes(obj.__typename);
};
const ProcedureTypesVarianceFields_possibleTypes = ['ProcedureTypesVarianceFields'];
export const isProcedureTypesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedureTypesVarianceFields"');
    return ProcedureTypesVarianceFields_possibleTypes.includes(obj.__typename);
};
const Procedures_possibleTypes = ['Procedures'];
export const isProcedures = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProcedures"');
    return Procedures_possibleTypes.includes(obj.__typename);
};
const ProceduresAggregate_possibleTypes = ['ProceduresAggregate'];
export const isProceduresAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresAggregate"');
    return ProceduresAggregate_possibleTypes.includes(obj.__typename);
};
const ProceduresAggregateFields_possibleTypes = ['ProceduresAggregateFields'];
export const isProceduresAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresAggregateFields"');
    return ProceduresAggregateFields_possibleTypes.includes(obj.__typename);
};
const ProceduresAvgFields_possibleTypes = ['ProceduresAvgFields'];
export const isProceduresAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresAvgFields"');
    return ProceduresAvgFields_possibleTypes.includes(obj.__typename);
};
const ProceduresMaxFields_possibleTypes = ['ProceduresMaxFields'];
export const isProceduresMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresMaxFields"');
    return ProceduresMaxFields_possibleTypes.includes(obj.__typename);
};
const ProceduresMinFields_possibleTypes = ['ProceduresMinFields'];
export const isProceduresMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresMinFields"');
    return ProceduresMinFields_possibleTypes.includes(obj.__typename);
};
const ProceduresMutationResponse_possibleTypes = ['ProceduresMutationResponse'];
export const isProceduresMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresMutationResponse"');
    return ProceduresMutationResponse_possibleTypes.includes(obj.__typename);
};
const ProceduresStddevFields_possibleTypes = ['ProceduresStddevFields'];
export const isProceduresStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresStddevFields"');
    return ProceduresStddevFields_possibleTypes.includes(obj.__typename);
};
const ProceduresStddevPopFields_possibleTypes = ['ProceduresStddevPopFields'];
export const isProceduresStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresStddevPopFields"');
    return ProceduresStddevPopFields_possibleTypes.includes(obj.__typename);
};
const ProceduresStddevSampFields_possibleTypes = ['ProceduresStddevSampFields'];
export const isProceduresStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresStddevSampFields"');
    return ProceduresStddevSampFields_possibleTypes.includes(obj.__typename);
};
const ProceduresSumFields_possibleTypes = ['ProceduresSumFields'];
export const isProceduresSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresSumFields"');
    return ProceduresSumFields_possibleTypes.includes(obj.__typename);
};
const ProceduresVarPopFields_possibleTypes = ['ProceduresVarPopFields'];
export const isProceduresVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresVarPopFields"');
    return ProceduresVarPopFields_possibleTypes.includes(obj.__typename);
};
const ProceduresVarSampFields_possibleTypes = ['ProceduresVarSampFields'];
export const isProceduresVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresVarSampFields"');
    return ProceduresVarSampFields_possibleTypes.includes(obj.__typename);
};
const ProceduresVarianceFields_possibleTypes = ['ProceduresVarianceFields'];
export const isProceduresVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isProceduresVarianceFields"');
    return ProceduresVarianceFields_possibleTypes.includes(obj.__typename);
};
const Roles_possibleTypes = ['Roles'];
export const isRoles = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isRoles"');
    return Roles_possibleTypes.includes(obj.__typename);
};
const Severities_possibleTypes = ['Severities'];
export const isSeverities = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isSeverities"');
    return Severities_possibleTypes.includes(obj.__typename);
};
const SeveritiesMutationResponse_possibleTypes = ['SeveritiesMutationResponse'];
export const isSeveritiesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isSeveritiesMutationResponse"');
    return SeveritiesMutationResponse_possibleTypes.includes(obj.__typename);
};
const Statuses_possibleTypes = ['Statuses'];
export const isStatuses = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatuses"');
    return Statuses_possibleTypes.includes(obj.__typename);
};
const StatusesAggregate_possibleTypes = ['StatusesAggregate'];
export const isStatusesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesAggregate"');
    return StatusesAggregate_possibleTypes.includes(obj.__typename);
};
const StatusesAggregateFields_possibleTypes = ['StatusesAggregateFields'];
export const isStatusesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesAggregateFields"');
    return StatusesAggregateFields_possibleTypes.includes(obj.__typename);
};
const StatusesAvgFields_possibleTypes = ['StatusesAvgFields'];
export const isStatusesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesAvgFields"');
    return StatusesAvgFields_possibleTypes.includes(obj.__typename);
};
const StatusesMaxFields_possibleTypes = ['StatusesMaxFields'];
export const isStatusesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesMaxFields"');
    return StatusesMaxFields_possibleTypes.includes(obj.__typename);
};
const StatusesMinFields_possibleTypes = ['StatusesMinFields'];
export const isStatusesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesMinFields"');
    return StatusesMinFields_possibleTypes.includes(obj.__typename);
};
const StatusesMutationResponse_possibleTypes = ['StatusesMutationResponse'];
export const isStatusesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesMutationResponse"');
    return StatusesMutationResponse_possibleTypes.includes(obj.__typename);
};
const StatusesStddevFields_possibleTypes = ['StatusesStddevFields'];
export const isStatusesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesStddevFields"');
    return StatusesStddevFields_possibleTypes.includes(obj.__typename);
};
const StatusesStddevPopFields_possibleTypes = ['StatusesStddevPopFields'];
export const isStatusesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesStddevPopFields"');
    return StatusesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const StatusesStddevSampFields_possibleTypes = ['StatusesStddevSampFields'];
export const isStatusesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesStddevSampFields"');
    return StatusesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const StatusesSumFields_possibleTypes = ['StatusesSumFields'];
export const isStatusesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesSumFields"');
    return StatusesSumFields_possibleTypes.includes(obj.__typename);
};
const StatusesVarPopFields_possibleTypes = ['StatusesVarPopFields'];
export const isStatusesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesVarPopFields"');
    return StatusesVarPopFields_possibleTypes.includes(obj.__typename);
};
const StatusesVarSampFields_possibleTypes = ['StatusesVarSampFields'];
export const isStatusesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesVarSampFields"');
    return StatusesVarSampFields_possibleTypes.includes(obj.__typename);
};
const StatusesVarianceFields_possibleTypes = ['StatusesVarianceFields'];
export const isStatusesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isStatusesVarianceFields"');
    return StatusesVarianceFields_possibleTypes.includes(obj.__typename);
};
const Templates_possibleTypes = ['Templates'];
export const isTemplates = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplates"');
    return Templates_possibleTypes.includes(obj.__typename);
};
const TemplatesAggregate_possibleTypes = ['TemplatesAggregate'];
export const isTemplatesAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesAggregate"');
    return TemplatesAggregate_possibleTypes.includes(obj.__typename);
};
const TemplatesAggregateFields_possibleTypes = ['TemplatesAggregateFields'];
export const isTemplatesAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesAggregateFields"');
    return TemplatesAggregateFields_possibleTypes.includes(obj.__typename);
};
const TemplatesAvgFields_possibleTypes = ['TemplatesAvgFields'];
export const isTemplatesAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesAvgFields"');
    return TemplatesAvgFields_possibleTypes.includes(obj.__typename);
};
const TemplatesMaxFields_possibleTypes = ['TemplatesMaxFields'];
export const isTemplatesMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesMaxFields"');
    return TemplatesMaxFields_possibleTypes.includes(obj.__typename);
};
const TemplatesMinFields_possibleTypes = ['TemplatesMinFields'];
export const isTemplatesMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesMinFields"');
    return TemplatesMinFields_possibleTypes.includes(obj.__typename);
};
const TemplatesMutationResponse_possibleTypes = ['TemplatesMutationResponse'];
export const isTemplatesMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesMutationResponse"');
    return TemplatesMutationResponse_possibleTypes.includes(obj.__typename);
};
const TemplatesStddevFields_possibleTypes = ['TemplatesStddevFields'];
export const isTemplatesStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesStddevFields"');
    return TemplatesStddevFields_possibleTypes.includes(obj.__typename);
};
const TemplatesStddevPopFields_possibleTypes = ['TemplatesStddevPopFields'];
export const isTemplatesStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesStddevPopFields"');
    return TemplatesStddevPopFields_possibleTypes.includes(obj.__typename);
};
const TemplatesStddevSampFields_possibleTypes = ['TemplatesStddevSampFields'];
export const isTemplatesStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesStddevSampFields"');
    return TemplatesStddevSampFields_possibleTypes.includes(obj.__typename);
};
const TemplatesSumFields_possibleTypes = ['TemplatesSumFields'];
export const isTemplatesSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesSumFields"');
    return TemplatesSumFields_possibleTypes.includes(obj.__typename);
};
const TemplatesVarPopFields_possibleTypes = ['TemplatesVarPopFields'];
export const isTemplatesVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesVarPopFields"');
    return TemplatesVarPopFields_possibleTypes.includes(obj.__typename);
};
const TemplatesVarSampFields_possibleTypes = ['TemplatesVarSampFields'];
export const isTemplatesVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesVarSampFields"');
    return TemplatesVarSampFields_possibleTypes.includes(obj.__typename);
};
const TemplatesVarianceFields_possibleTypes = ['TemplatesVarianceFields'];
export const isTemplatesVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTemplatesVarianceFields"');
    return TemplatesVarianceFields_possibleTypes.includes(obj.__typename);
};
const Translations_possibleTypes = ['Translations'];
export const isTranslations = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTranslations"');
    return Translations_possibleTypes.includes(obj.__typename);
};
const TranslationsMutationResponse_possibleTypes = ['TranslationsMutationResponse'];
export const isTranslationsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTranslationsMutationResponse"');
    return TranslationsMutationResponse_possibleTypes.includes(obj.__typename);
};
const UpOutput_possibleTypes = ['UpOutput'];
export const isUpOutput = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUpOutput"');
    return UpOutput_possibleTypes.includes(obj.__typename);
};
const UpOutputItem_possibleTypes = ['UpOutputItem'];
export const isUpOutputItem = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUpOutputItem"');
    return UpOutputItem_possibleTypes.includes(obj.__typename);
};
const Uploads_possibleTypes = ['Uploads'];
export const isUploads = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploads"');
    return Uploads_possibleTypes.includes(obj.__typename);
};
const UploadsAggregate_possibleTypes = ['UploadsAggregate'];
export const isUploadsAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsAggregate"');
    return UploadsAggregate_possibleTypes.includes(obj.__typename);
};
const UploadsAggregateFields_possibleTypes = ['UploadsAggregateFields'];
export const isUploadsAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsAggregateFields"');
    return UploadsAggregateFields_possibleTypes.includes(obj.__typename);
};
const UploadsAvgFields_possibleTypes = ['UploadsAvgFields'];
export const isUploadsAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsAvgFields"');
    return UploadsAvgFields_possibleTypes.includes(obj.__typename);
};
const UploadsMaxFields_possibleTypes = ['UploadsMaxFields'];
export const isUploadsMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsMaxFields"');
    return UploadsMaxFields_possibleTypes.includes(obj.__typename);
};
const UploadsMinFields_possibleTypes = ['UploadsMinFields'];
export const isUploadsMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsMinFields"');
    return UploadsMinFields_possibleTypes.includes(obj.__typename);
};
const UploadsMutationResponse_possibleTypes = ['UploadsMutationResponse'];
export const isUploadsMutationResponse = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsMutationResponse"');
    return UploadsMutationResponse_possibleTypes.includes(obj.__typename);
};
const UploadsStddevFields_possibleTypes = ['UploadsStddevFields'];
export const isUploadsStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsStddevFields"');
    return UploadsStddevFields_possibleTypes.includes(obj.__typename);
};
const UploadsStddevPopFields_possibleTypes = ['UploadsStddevPopFields'];
export const isUploadsStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsStddevPopFields"');
    return UploadsStddevPopFields_possibleTypes.includes(obj.__typename);
};
const UploadsStddevSampFields_possibleTypes = ['UploadsStddevSampFields'];
export const isUploadsStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsStddevSampFields"');
    return UploadsStddevSampFields_possibleTypes.includes(obj.__typename);
};
const UploadsSumFields_possibleTypes = ['UploadsSumFields'];
export const isUploadsSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsSumFields"');
    return UploadsSumFields_possibleTypes.includes(obj.__typename);
};
const UploadsVarPopFields_possibleTypes = ['UploadsVarPopFields'];
export const isUploadsVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsVarPopFields"');
    return UploadsVarPopFields_possibleTypes.includes(obj.__typename);
};
const UploadsVarSampFields_possibleTypes = ['UploadsVarSampFields'];
export const isUploadsVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsVarSampFields"');
    return UploadsVarSampFields_possibleTypes.includes(obj.__typename);
};
const UploadsVarianceFields_possibleTypes = ['UploadsVarianceFields'];
export const isUploadsVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUploadsVarianceFields"');
    return UploadsVarianceFields_possibleTypes.includes(obj.__typename);
};
const Users_possibleTypes = ['Users'];
export const isUsers = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsers"');
    return Users_possibleTypes.includes(obj.__typename);
};
const UsersAggregate_possibleTypes = ['UsersAggregate'];
export const isUsersAggregate = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersAggregate"');
    return UsersAggregate_possibleTypes.includes(obj.__typename);
};
const UsersAggregateFields_possibleTypes = ['UsersAggregateFields'];
export const isUsersAggregateFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersAggregateFields"');
    return UsersAggregateFields_possibleTypes.includes(obj.__typename);
};
const UsersAvgFields_possibleTypes = ['UsersAvgFields'];
export const isUsersAvgFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersAvgFields"');
    return UsersAvgFields_possibleTypes.includes(obj.__typename);
};
const UsersMaxFields_possibleTypes = ['UsersMaxFields'];
export const isUsersMaxFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersMaxFields"');
    return UsersMaxFields_possibleTypes.includes(obj.__typename);
};
const UsersMinFields_possibleTypes = ['UsersMinFields'];
export const isUsersMinFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersMinFields"');
    return UsersMinFields_possibleTypes.includes(obj.__typename);
};
const UsersStddevFields_possibleTypes = ['UsersStddevFields'];
export const isUsersStddevFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersStddevFields"');
    return UsersStddevFields_possibleTypes.includes(obj.__typename);
};
const UsersStddevPopFields_possibleTypes = ['UsersStddevPopFields'];
export const isUsersStddevPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersStddevPopFields"');
    return UsersStddevPopFields_possibleTypes.includes(obj.__typename);
};
const UsersStddevSampFields_possibleTypes = ['UsersStddevSampFields'];
export const isUsersStddevSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersStddevSampFields"');
    return UsersStddevSampFields_possibleTypes.includes(obj.__typename);
};
const UsersSumFields_possibleTypes = ['UsersSumFields'];
export const isUsersSumFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersSumFields"');
    return UsersSumFields_possibleTypes.includes(obj.__typename);
};
const UsersVarPopFields_possibleTypes = ['UsersVarPopFields'];
export const isUsersVarPopFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersVarPopFields"');
    return UsersVarPopFields_possibleTypes.includes(obj.__typename);
};
const UsersVarSampFields_possibleTypes = ['UsersVarSampFields'];
export const isUsersVarSampFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersVarSampFields"');
    return UsersVarSampFields_possibleTypes.includes(obj.__typename);
};
const UsersVarianceFields_possibleTypes = ['UsersVarianceFields'];
export const isUsersVarianceFields = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isUsersVarianceFields"');
    return UsersVarianceFields_possibleTypes.includes(obj.__typename);
};
const mutation_root_possibleTypes = ['mutation_root'];
export const ismutation_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "ismutation_root"');
    return mutation_root_possibleTypes.includes(obj.__typename);
};
const query_root_possibleTypes = ['query_root'];
export const isquery_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isquery_root"');
    return query_root_possibleTypes.includes(obj.__typename);
};
const subscription_root_possibleTypes = ['subscription_root'];
export const issubscription_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "issubscription_root"');
    return subscription_root_possibleTypes.includes(obj.__typename);
};
export const enumAssetTypesConstraint = {
    asset_types_pkey: 'asset_types_pkey'
};
export const enumAssetTypesSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAssetTypesSelectColumnAssetTypesAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumAssetTypesSelectColumnAssetTypesAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumAssetTypesUpdateColumn = {
    archived: 'archived',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAssetsConstraint = {
    assets_pkey: 'assets_pkey'
};
export const enumAssetsSelectColumn = {
    archived: 'archived',
    barCode: 'barCode',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    customFields: 'customFields',
    id: 'id',
    lastActivity: 'lastActivity',
    locationId: 'locationId',
    manufacturer: 'manufacturer',
    model: 'model',
    name: 'name',
    notes: 'notes',
    photoId: 'photoId',
    pin: 'pin',
    ratedAmperage: 'ratedAmperage',
    serial: 'serial',
    typeId: 'typeId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    voltage: 'voltage'
};
export const enumAssetsSelectColumnAssetsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumAssetsSelectColumnAssetsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumAssetsUpdateColumn = {
    archived: 'archived',
    barCode: 'barCode',
    customFields: 'customFields',
    locationId: 'locationId',
    manufacturer: 'manufacturer',
    model: 'model',
    name: 'name',
    notes: 'notes',
    photoId: 'photoId',
    pin: 'pin',
    ratedAmperage: 'ratedAmperage',
    serial: 'serial',
    typeId: 'typeId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    voltage: 'voltage'
};
export const enumAuditFindingPhotosConstraint = {
    audit_finding_photos_pkey: 'audit_finding_photos_pkey'
};
export const enumAuditFindingPhotosSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    findingId: 'findingId',
    lastActivity: 'lastActivity',
    photoId: 'photoId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditFindingPhotosUpdateColumn = {
    archived: 'archived',
    findingId: 'findingId',
    lastActivity: 'lastActivity',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditFindingsConstraint = {
    audit_findings_pkey: 'audit_findings_pkey'
};
export const enumAuditFindingsSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    itemId: 'itemId',
    lastActivity: 'lastActivity',
    notes: 'notes',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditFindingsUpdateColumn = {
    archived: 'archived',
    lastActivity: 'lastActivity',
    notes: 'notes',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditItemsConstraint = {
    audit_items_pkey: 'audit_items_pkey'
};
export const enumAuditItemsSelectColumn = {
    archived: 'archived',
    assetId: 'assetId',
    auditId: 'auditId',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    lastActivity: 'lastActivity',
    notes: 'notes',
    status: 'status',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditItemsUpdateColumn = {
    archived: 'archived',
    assetId: 'assetId',
    auditId: 'auditId',
    id: 'id',
    notes: 'notes',
    status: 'status',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditsConstraint = {
    audits_pkey: 'audits_pkey'
};
export const enumAuditsSelectColumn = {
    active: 'active',
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    label: 'label',
    lastActivity: 'lastActivity',
    locationId: 'locationId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumAuditsUpdateColumn = {
    active: 'active',
    archived: 'archived',
    id: 'id',
    label: 'label',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumCompaniesSelectColumn = {
    id: 'id',
    name: 'name'
};
export const enumCompanySettingsConstraint = {
    company_settings_pkey: 'company_settings_pkey'
};
export const enumCompanySettingsSelectColumn = {
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    defaultStatusId: 'defaultStatusId',
    defaultTemplateId: 'defaultTemplateId',
    lastActivity: 'lastActivity',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumCompanySettingsUpdateColumn = {
    defaultStatusId: 'defaultStatusId',
    defaultTemplateId: 'defaultTemplateId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumControlPointTemplatesConstraint = {
    control_point_templates_pkey: 'control_point_templates_pkey'
};
export const enumControlPointTemplatesSelectColumn = {
    archived: 'archived',
    barCode: 'barCode',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    customFields: 'customFields',
    energyMagnitude: 'energyMagnitude',
    energyMeasureId: 'energyMeasureId',
    energySourceId: 'energySourceId',
    id: 'id',
    isolationDeviceId: 'isolationDeviceId',
    isolationLocation: 'isolationLocation',
    lastActivity: 'lastActivity',
    methodOverride: 'methodOverride',
    number: 'number',
    photoId: 'photoId',
    procedureTemplateId: 'procedureTemplateId',
    step: 'step',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    verificationOverride: 'verificationOverride'
};
export const enumControlPointTemplatesUpdateColumn = {
    archived: 'archived',
    barCode: 'barCode',
    customFields: 'customFields',
    energyMagnitude: 'energyMagnitude',
    energyMeasureId: 'energyMeasureId',
    energySourceId: 'energySourceId',
    isolationDeviceId: 'isolationDeviceId',
    isolationLocation: 'isolationLocation',
    methodOverride: 'methodOverride',
    number: 'number',
    photoId: 'photoId',
    procedureTemplateId: 'procedureTemplateId',
    step: 'step',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    verificationOverride: 'verificationOverride'
};
export const enumControlPointsConstraint = {
    control_points_pkey: 'control_points_pkey'
};
export const enumControlPointsSelectColumn = {
    archived: 'archived',
    barCode: 'barCode',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    customFields: 'customFields',
    energyMagnitude: 'energyMagnitude',
    energyMeasureId: 'energyMeasureId',
    energySourceId: 'energySourceId',
    id: 'id',
    isolationDeviceId: 'isolationDeviceId',
    isolationLocation: 'isolationLocation',
    lastActivity: 'lastActivity',
    methodOverride: 'methodOverride',
    number: 'number',
    photoId: 'photoId',
    procedureId: 'procedureId',
    step: 'step',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    verificationOverride: 'verificationOverride'
};
export const enumControlPointsSelectColumnControlPointsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumControlPointsSelectColumnControlPointsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumControlPointsUpdateColumn = {
    archived: 'archived',
    barCode: 'barCode',
    customFields: 'customFields',
    energyMagnitude: 'energyMagnitude',
    energyMeasureId: 'energyMeasureId',
    energySourceId: 'energySourceId',
    isolationDeviceId: 'isolationDeviceId',
    isolationLocation: 'isolationLocation',
    methodOverride: 'methodOverride',
    number: 'number',
    photoId: 'photoId',
    step: 'step',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    verificationOverride: 'verificationOverride'
};
export const enumCursorOrdering = {
    ASC: 'ASC',
    DESC: 'DESC'
};
export const enumCustomFieldSubjectsEnum = {
    ASSET: 'ASSET',
    CONTROL_POINT: 'CONTROL_POINT',
    LOCATION: 'LOCATION',
    PROCEDURE: 'PROCEDURE'
};
export const enumCustomFieldTypesEnum = {
    BOOLEAN: 'BOOLEAN',
    CHOICES: 'CHOICES',
    FILE: 'FILE',
    FILES: 'FILES',
    NUMBER: 'NUMBER',
    PHOTO: 'PHOTO',
    PHOTOS: 'PHOTOS',
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA'
};
export const enumCustomFieldsConstraint = {
    custom_fields_pkey: 'custom_fields_pkey'
};
export const enumCustomFieldsSelectColumn = {
    archived: 'archived',
    choices: 'choices',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    fileTypes: 'fileTypes',
    hide: 'hide',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    regex: 'regex',
    required: 'required',
    subject: 'subject',
    type: 'type',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumCustomFieldsSelectColumnCustomFieldsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived',
    hide: 'hide',
    required: 'required'
};
export const enumCustomFieldsSelectColumnCustomFieldsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived',
    hide: 'hide',
    required: 'required'
};
export const enumCustomFieldsUpdateColumn = {
    archived: 'archived',
    choices: 'choices',
    fileTypes: 'fileTypes',
    hide: 'hide',
    name: 'name',
    regex: 'regex',
    required: 'required',
    type: 'type',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumEnergyMeasuresConstraint = {
    energy_measures_id_company_id_energy_source_id_key: 'energy_measures_id_company_id_energy_source_id_key',
    energy_measures_pkey: 'energy_measures_pkey'
};
export const enumEnergyMeasuresSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    energySourceId: 'energySourceId',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumEnergyMeasuresSelectColumnEnergyMeasuresAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumEnergyMeasuresSelectColumnEnergyMeasuresAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumEnergyMeasuresUpdateColumn = {
    archived: 'archived',
    energySourceId: 'energySourceId',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumEnergySourcesConstraint = {
    energy_sources_pkey: 'energy_sources_pkey'
};
export const enumEnergySourcesSelectColumn = {
    archived: 'archived',
    color: 'color',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    iconId: 'iconId',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    prefix: 'prefix',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumEnergySourcesSelectColumnEnergySourcesAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumEnergySourcesSelectColumnEnergySourcesAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumEnergySourcesUpdateColumn = {
    archived: 'archived',
    color: 'color',
    iconId: 'iconId',
    name: 'name',
    prefix: 'prefix',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumHazardsConstraint = {
    hazards_pkey: 'hazards_pkey'
};
export const enumHazardsSelectColumn = {
    archived: 'archived',
    color: 'color',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    iconId: 'iconId',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    statement: 'statement',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumHazardsSelectColumnHazardsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumHazardsSelectColumnHazardsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumHazardsUpdateColumn = {
    archived: 'archived',
    color: 'color',
    iconId: 'iconId',
    name: 'name',
    statement: 'statement',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionFindingPhotosConstraint = {
    inspection_finding_photos_pkey: 'inspection_finding_photos_pkey'
};
export const enumInspectionFindingPhotosSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    inspectionFindingId: 'inspectionFindingId',
    lastActivity: 'lastActivity',
    photoId: 'photoId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionFindingPhotosUpdateColumn = {
    archived: 'archived',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionFindingsConstraint = {
    inspection_findings_pkey: 'inspection_findings_pkey'
};
export const enumInspectionFindingsSelectColumn = {
    archived: 'archived',
    circuit: 'circuit',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    inspectionItemId: 'inspectionItemId',
    lastActivity: 'lastActivity',
    notes: 'notes',
    phase: 'phase',
    reasonId: 'reasonId',
    severityId: 'severityId',
    temperatureAmbient: 'temperatureAmbient',
    temperatureHigh: 'temperatureHigh',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionFindingsUpdateColumn = {
    archived: 'archived',
    circuit: 'circuit',
    notes: 'notes',
    phase: 'phase',
    reasonId: 'reasonId',
    severityId: 'severityId',
    temperatureAmbient: 'temperatureAmbient',
    temperatureHigh: 'temperatureHigh',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionItemPhotosConstraint = {
    inspection_item_photos_pkey: 'inspection_item_photos_pkey'
};
export const enumInspectionItemPhotosSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    inspectionItemId: 'inspectionItemId',
    lastActivity: 'lastActivity',
    photoId: 'photoId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionItemPhotosUpdateColumn = {
    archived: 'archived',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionItemsConstraint = {
    inspection_items_pkey: 'inspection_items_pkey'
};
export const enumInspectionItemsSelectColumn = {
    amperageA: 'amperageA',
    amperageB: 'amperageB',
    amperageC: 'amperageC',
    amperageN: 'amperageN',
    archived: 'archived',
    assetId: 'assetId',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    inspectionId: 'inspectionId',
    lastActivity: 'lastActivity',
    notes: 'notes',
    status: 'status',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionItemsUpdateColumn = {
    amperageA: 'amperageA',
    amperageB: 'amperageB',
    amperageC: 'amperageC',
    amperageN: 'amperageN',
    archived: 'archived',
    notes: 'notes',
    status: 'status',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionReasonsConstraint = {
    inspection_reasons_pkey: 'inspection_reasons_pkey'
};
export const enumInspectionReasonsSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    description: 'description',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionReasonsUpdateColumn = {
    archived: 'archived',
    description: 'description',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionsConstraint = {
    inspections_pkey: 'inspections_pkey'
};
export const enumInspectionsSelectColumn = {
    active: 'active',
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    label: 'label',
    lastActivity: 'lastActivity',
    locationId: 'locationId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumInspectionsUpdateColumn = {
    active: 'active',
    archived: 'archived',
    label: 'label',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumIsolationDevicesConstraint = {
    isolation_devices_id_company_id_energy_source_id_key: 'isolation_devices_id_company_id_energy_source_id_key',
    isolation_devices_pkey: 'isolation_devices_pkey'
};
export const enumIsolationDevicesSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    energySourceId: 'energySourceId',
    iconId: 'iconId',
    id: 'id',
    lastActivity: 'lastActivity',
    methodStatement: 'methodStatement',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    verificationStatement: 'verificationStatement',
    videoId: 'videoId'
};
export const enumIsolationDevicesSelectColumnIsolationDevicesAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumIsolationDevicesSelectColumnIsolationDevicesAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumIsolationDevicesUpdateColumn = {
    archived: 'archived',
    energySourceId: 'energySourceId',
    iconId: 'iconId',
    methodStatement: 'methodStatement',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    verificationStatement: 'verificationStatement',
    videoId: 'videoId'
};
export const enumLanguagesConstraint = {
    languages_pkey: 'languages_pkey'
};
export const enumLanguagesSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumLanguagesUpdateColumn = {
    archived: 'archived',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumLocationsConstraint = {
    locations_pkey: 'locations_pkey'
};
export const enumLocationsSelectColumn = {
    address: 'address',
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    customFields: 'customFields',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumLocationsSelectColumnLocationsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumLocationsSelectColumnLocationsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumLocationsUpdateColumn = {
    address: 'address',
    archived: 'archived',
    customFields: 'customFields',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumOrderBy = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST'
};
export const enumPermissionsSelectColumn = {
    companyId: 'companyId',
    role: 'role',
    userId: 'userId'
};
export const enumProcedureHazardsConstraint = {
    procedure_hazards_pkey: 'procedure_hazards_pkey'
};
export const enumProcedureHazardsSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    hazardId: 'hazardId',
    lastActivity: 'lastActivity',
    procedureId: 'procedureId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProcedureHazardsSelectColumnProcedureHazardsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumProcedureHazardsSelectColumnProcedureHazardsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumProcedureHazardsUpdateColumn = {
    archived: 'archived',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProcedureTemplatesConstraint = {
    procedure_templates_pkey: 'procedure_templates_pkey'
};
export const enumProcedureTemplatesSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    customFields: 'customFields',
    description: 'description',
    id: 'id',
    label: 'label',
    lastActivity: 'lastActivity',
    name: 'name',
    notes: 'notes',
    showInMenu: 'showInMenu',
    typeId: 'typeId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProcedureTemplatesUpdateColumn = {
    archived: 'archived',
    customFields: 'customFields',
    description: 'description',
    label: 'label',
    name: 'name',
    notes: 'notes',
    showInMenu: 'showInMenu',
    typeId: 'typeId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProcedureTypesConstraint = {
    procedure_types_pkey: 'procedure_types_pkey'
};
export const enumProcedureTypesSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProcedureTypesSelectColumnProcedureTypesAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumProcedureTypesSelectColumnProcedureTypesAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumProcedureTypesUpdateColumn = {
    archived: 'archived',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProceduresConstraint = {
    procedures_pkey: 'procedures_pkey'
};
export const enumProceduresSelectColumn = {
    archived: 'archived',
    assetId: 'assetId',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    customFields: 'customFields',
    fromAudit: 'fromAudit',
    id: 'id',
    label: 'label',
    lastActivity: 'lastActivity',
    notes: 'notes',
    statusId: 'statusId',
    typeId: 'typeId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumProceduresSelectColumnProceduresAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived',
    fromAudit: 'fromAudit'
};
export const enumProceduresSelectColumnProceduresAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived',
    fromAudit: 'fromAudit'
};
export const enumProceduresUpdateColumn = {
    archived: 'archived',
    assetId: 'assetId',
    customFields: 'customFields',
    label: 'label',
    notes: 'notes',
    statusId: 'statusId',
    typeId: 'typeId',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumRolesEnum = {
    BULK_EDIT: 'BULK_EDIT',
    BULK_EDIT_WITHIN_LOCATION: 'BULK_EDIT_WITHIN_LOCATION',
    EDIT: 'EDIT',
    MANAGE_SETTINGS: 'MANAGE_SETTINGS',
    PRINT: 'PRINT',
    VIEW: 'VIEW'
};
export const enumRolesSelectColumn = {
    role: 'role'
};
export const enumSeveritiesConstraint = {
    severities_pkey: 'severities_pkey'
};
export const enumSeveritiesSelectColumn = {
    archived: 'archived',
    color: 'color',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumSeveritiesUpdateColumn = {
    archived: 'archived',
    color: 'color',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumStatusesConstraint = {
    statuses_pkey: 'statuses_pkey'
};
export const enumStatusesSelectColumn = {
    archived: 'archived',
    color: 'color',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    public: 'public',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumStatusesSelectColumnStatusesAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived',
    public: 'public'
};
export const enumStatusesSelectColumnStatusesAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived',
    public: 'public'
};
export const enumStatusesUpdateColumn = {
    archived: 'archived',
    color: 'color',
    name: 'name',
    public: 'public',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumTemplatesConstraint = {
    templates_pkey: 'templates_pkey'
};
export const enumTemplatesSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    css: 'css',
    html: 'html',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    type: 'type',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumTemplatesSelectColumnTemplatesAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived'
};
export const enumTemplatesSelectColumnTemplatesAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived'
};
export const enumTemplatesUpdateColumn = {
    archived: 'archived',
    css: 'css',
    html: 'html',
    name: 'name',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumTranslationsConstraint = {
    translations_id_company_id_language_id_key: 'translations_id_company_id_language_id_key',
    translations_pkey: 'translations_pkey'
};
export const enumTranslationsSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    id: 'id',
    languageId: 'languageId',
    lastActivity: 'lastActivity',
    pattern: 'pattern',
    replacement: 'replacement',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumTranslationsUpdateColumn = {
    archived: 'archived',
    pattern: 'pattern',
    replacement: 'replacement',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn'
};
export const enumUploadsConstraint = {
    uploads_pkey: 'uploads_pkey'
};
export const enumUploadsSelectColumn = {
    archived: 'archived',
    companyId: 'companyId',
    createdById: 'createdById',
    createdOn: 'createdOn',
    height: 'height',
    id: 'id',
    lastActivity: 'lastActivity',
    name: 'name',
    present: 'present',
    resized: 'resized',
    size: 'size',
    type: 'type',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    width: 'width'
};
export const enumUploadsSelectColumnUploadsAggregateBoolExpBoolAndArgumentsColumns = {
    archived: 'archived',
    present: 'present',
    resized: 'resized'
};
export const enumUploadsSelectColumnUploadsAggregateBoolExpBoolOrArgumentsColumns = {
    archived: 'archived',
    present: 'present',
    resized: 'resized'
};
export const enumUploadsUpdateColumn = {
    archived: 'archived',
    updatedById: 'updatedById',
    updatedOn: 'updatedOn',
    uploadRequested: 'uploadRequested'
};
export const enumUsersSelectColumn = {
    id: 'id',
    name: 'name'
};
