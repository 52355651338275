export default {
    "scalars": [
        8,
        20,
        21,
        22,
        34,
        52,
        68,
        69,
        70,
        82,
        95,
        104,
        112,
        122,
        131,
        139,
        149,
        158,
        166,
        176,
        185,
        193,
        198,
        208,
        227,
        238,
        250,
        264,
        277,
        285,
        300,
        315,
        316,
        317,
        329,
        337,
        338,
        340,
        350,
        361,
        362,
        363,
        375,
        392,
        404,
        405,
        406,
        418,
        435,
        447,
        448,
        449,
        461,
        469,
        479,
        491,
        492,
        493,
        505,
        518,
        527,
        535,
        545,
        554,
        562,
        572,
        581,
        589,
        599,
        608,
        616,
        626,
        635,
        643,
        653,
        662,
        670,
        675,
        686,
        698,
        699,
        700,
        712,
        724,
        732,
        736,
        747,
        763,
        764,
        765,
        777,
        786,
        794,
        813,
        824,
        825,
        826,
        838,
        852,
        865,
        873,
        886,
        898,
        899,
        900,
        912,
        930,
        946,
        947,
        948,
        960,
        970,
        973,
        978,
        985,
        989,
        999,
        1011,
        1012,
        1013,
        1025,
        1033,
        1043,
        1055,
        1056,
        1057,
        1069,
        1083,
        1092,
        1100,
        1116,
        1128,
        1129,
        1130,
        1142,
        1158,
        1194,
        1198,
        1214,
        1218
    ],
    "types": {
        "AssetTypes": {
            "archived": [
                198
            ],
            "assets": [
                42,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsAggregate": [
                43,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesAggregate": {
            "aggregate": [
                3
            ],
            "nodes": [
                0
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesAggregateBoolExp": {
            "bool_and": [
                1169
            ],
            "bool_or": [
                1170
            ],
            "count": [
                1171
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesAggregateFields": {
            "avg": [
                5
            ],
            "count": [
                675,
                {
                    "columns": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                11
            ],
            "min": [
                13
            ],
            "stddev": [
                24
            ],
            "stddevPop": [
                26
            ],
            "stddevSamp": [
                28
            ],
            "sum": [
                32
            ],
            "varPop": [
                36
            ],
            "varSamp": [
                38
            ],
            "variance": [
                40
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesAggregateOrderBy": {
            "avg": [
                6
            ],
            "count": [
                786
            ],
            "max": [
                12
            ],
            "min": [
                14
            ],
            "stddev": [
                25
            ],
            "stddevPop": [
                27
            ],
            "stddevSamp": [
                29
            ],
            "sum": [
                33
            ],
            "varPop": [
                37
            ],
            "varSamp": [
                39
            ],
            "variance": [
                41
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesBoolExp": {
            "_and": [
                7
            ],
            "_not": [
                7
            ],
            "_or": [
                7
            ],
            "archived": [
                199
            ],
            "assets": [
                51
            ],
            "assetsAggregate": [
                44
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesConstraint": {},
        "AssetTypesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesInsertInput": {
            "archived": [
                198
            ],
            "assets": [
                48
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                0
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesObjRelInsertInput": {
            "data": [
                10
            ],
            "onConflict": [
                17
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesOnConflict": {
            "constraint": [
                8
            ],
            "updateColumns": [
                34
            ],
            "where": [
                7
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesOrderBy": {
            "archived": [
                786
            ],
            "assetsAggregate": [
                46
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesSelectColumn": {},
        "AssetTypesSelectColumnAssetTypesAggregateBoolExpBool_andArgumentsColumns": {},
        "AssetTypesSelectColumnAssetTypesAggregateBoolExpBool_orArgumentsColumns": {},
        "AssetTypesSetInput": {
            "archived": [
                198
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStreamCursorInput": {
            "initialValue": [
                31
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesUpdateColumn": {},
        "AssetTypesUpdates": {
            "_inc": [
                9
            ],
            "_set": [
                23
            ],
            "where": [
                7
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetTypesVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "Assets": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194,
                {
                    "path": [
                        1033
                    ]
                }
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "location": [
                738
            ],
            "locationId": [
                1218
            ],
            "manufacturer": [
                1033
            ],
            "model": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "photo": [
                1108
            ],
            "photoId": [
                1218
            ],
            "pin": [
                1033
            ],
            "procedures": [
                920,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresAggregate": [
                921,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "ratedAmperage": [
                1198
            ],
            "serial": [
                1033
            ],
            "type": [
                0
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAggregate": {
            "aggregate": [
                45
            ],
            "nodes": [
                42
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAggregateBoolExp": {
            "bool_and": [
                1172
            ],
            "bool_or": [
                1173
            ],
            "count": [
                1174
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAggregateFields": {
            "avg": [
                49
            ],
            "count": [
                675,
                {
                    "columns": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                58
            ],
            "min": [
                60
            ],
            "stddev": [
                72
            ],
            "stddevPop": [
                74
            ],
            "stddevSamp": [
                76
            ],
            "sum": [
                80
            ],
            "varPop": [
                84
            ],
            "varSamp": [
                86
            ],
            "variance": [
                88
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAggregateOrderBy": {
            "avg": [
                50
            ],
            "count": [
                786
            ],
            "max": [
                59
            ],
            "min": [
                61
            ],
            "stddev": [
                73
            ],
            "stddevPop": [
                75
            ],
            "stddevSamp": [
                77
            ],
            "sum": [
                81
            ],
            "varPop": [
                85
            ],
            "varSamp": [
                87
            ],
            "variance": [
                89
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAppendInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsArrRelInsertInput": {
            "data": [
                57
            ],
            "onConflict": [
                64
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsBoolExp": {
            "_and": [
                51
            ],
            "_not": [
                51
            ],
            "_or": [
                51
            ],
            "archived": [
                199
            ],
            "barCode": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "customFields": [
                721
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "location": [
                746
            ],
            "locationId": [
                1168
            ],
            "manufacturer": [
                1034
            ],
            "model": [
                1034
            ],
            "name": [
                1034
            ],
            "notes": [
                1034
            ],
            "photo": [
                1115
            ],
            "photoId": [
                1168
            ],
            "pin": [
                1034
            ],
            "procedures": [
                929
            ],
            "proceduresAggregate": [
                922
            ],
            "ratedAmperage": [
                785
            ],
            "serial": [
                1034
            ],
            "type": [
                7
            ],
            "typeId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "voltage": [
                785
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsConstraint": {},
        "AssetsDeleteAtPathInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsDeleteElemInput": {
            "customFields": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsDeleteKeyInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsIncInput": {
            "ratedAmperage": [
                1198
            ],
            "updatedById": [
                675
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsInsertInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "id": [
                1218
            ],
            "location": [
                758
            ],
            "locationId": [
                1218
            ],
            "manufacturer": [
                1033
            ],
            "model": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "photo": [
                1124
            ],
            "photoId": [
                1218
            ],
            "pin": [
                1033
            ],
            "procedures": [
                926
            ],
            "ratedAmperage": [
                1198
            ],
            "serial": [
                1033
            ],
            "type": [
                16
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsMaxFields": {
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "manufacturer": [
                1033
            ],
            "model": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "photoId": [
                1218
            ],
            "pin": [
                1033
            ],
            "ratedAmperage": [
                1198
            ],
            "serial": [
                1033
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsMaxOrderBy": {
            "barCode": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "locationId": [
                786
            ],
            "manufacturer": [
                786
            ],
            "model": [
                786
            ],
            "name": [
                786
            ],
            "notes": [
                786
            ],
            "photoId": [
                786
            ],
            "pin": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "serial": [
                786
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsMinFields": {
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "manufacturer": [
                1033
            ],
            "model": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "photoId": [
                1218
            ],
            "pin": [
                1033
            ],
            "ratedAmperage": [
                1198
            ],
            "serial": [
                1033
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsMinOrderBy": {
            "barCode": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "locationId": [
                786
            ],
            "manufacturer": [
                786
            ],
            "model": [
                786
            ],
            "name": [
                786
            ],
            "notes": [
                786
            ],
            "photoId": [
                786
            ],
            "pin": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "serial": [
                786
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                42
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsObjRelInsertInput": {
            "data": [
                57
            ],
            "onConflict": [
                64
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsOnConflict": {
            "constraint": [
                52
            ],
            "updateColumns": [
                82
            ],
            "where": [
                51
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsOrderBy": {
            "archived": [
                786
            ],
            "barCode": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "customFields": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "location": [
                760
            ],
            "locationId": [
                786
            ],
            "manufacturer": [
                786
            ],
            "model": [
                786
            ],
            "name": [
                786
            ],
            "notes": [
                786
            ],
            "photo": [
                1126
            ],
            "photoId": [
                786
            ],
            "pin": [
                786
            ],
            "proceduresAggregate": [
                924
            ],
            "ratedAmperage": [
                786
            ],
            "serial": [
                786
            ],
            "type": [
                18
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsPrependInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsSelectColumn": {},
        "AssetsSelectColumnAssetsAggregateBoolExpBool_andArgumentsColumns": {},
        "AssetsSelectColumnAssetsAggregateBoolExpBool_orArgumentsColumns": {},
        "AssetsSetInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "customFields": [
                1194
            ],
            "locationId": [
                1218
            ],
            "manufacturer": [
                1033
            ],
            "model": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "photoId": [
                1218
            ],
            "pin": [
                1033
            ],
            "ratedAmperage": [
                1198
            ],
            "serial": [
                1033
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStreamCursorInput": {
            "initialValue": [
                79
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "manufacturer": [
                1033
            ],
            "model": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "photoId": [
                1218
            ],
            "pin": [
                1033
            ],
            "ratedAmperage": [
                1198
            ],
            "serial": [
                1033
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "ratedAmperage": [
                1198
            ],
            "updatedById": [
                675
            ],
            "voltage": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsUpdateColumn": {},
        "AssetsUpdates": {
            "_append": [
                47
            ],
            "_deleteAtPath": [
                53
            ],
            "_deleteElem": [
                54
            ],
            "_deleteKey": [
                55
            ],
            "_inc": [
                56
            ],
            "_prepend": [
                67
            ],
            "_set": [
                71
            ],
            "where": [
                51
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "ratedAmperage": [
                469
            ],
            "updatedById": [
                469
            ],
            "voltage": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AssetsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "ratedAmperage": [
                786
            ],
            "updatedById": [
                786
            ],
            "voltage": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotos": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "findingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosAggregate": {
            "aggregate": [
                92
            ],
            "nodes": [
                90
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosAggregateFields": {
            "avg": [
                93
            ],
            "count": [
                675,
                {
                    "columns": [
                        104,
                        "[AuditFindingPhotosSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                98
            ],
            "min": [
                99
            ],
            "stddev": [
                106
            ],
            "stddevPop": [
                107
            ],
            "stddevSamp": [
                108
            ],
            "sum": [
                111
            ],
            "varPop": [
                114
            ],
            "varSamp": [
                115
            ],
            "variance": [
                116
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosBoolExp": {
            "_and": [
                94
            ],
            "_not": [
                94
            ],
            "_or": [
                94
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "findingId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "photoId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosConstraint": {},
        "AuditFindingPhotosIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "findingId": [
                1218
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "findingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "findingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                90
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosOnConflict": {
            "constraint": [
                95
            ],
            "updateColumns": [
                112
            ],
            "where": [
                94
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "findingId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "photoId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosPkColumnsInput": {
            "companyId": [
                675
            ],
            "findingId": [
                1218
            ],
            "photoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosSelectColumn": {},
        "AuditFindingPhotosSetInput": {
            "archived": [
                198
            ],
            "findingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosStreamCursorInput": {
            "initialValue": [
                110
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "findingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosUpdateColumn": {},
        "AuditFindingPhotosUpdates": {
            "_inc": [
                96
            ],
            "_set": [
                105
            ],
            "where": [
                94
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingPhotosVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindings": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "itemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsAggregate": {
            "aggregate": [
                119
            ],
            "nodes": [
                117
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsAggregateFields": {
            "avg": [
                120
            ],
            "count": [
                675,
                {
                    "columns": [
                        131,
                        "[AuditFindingsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                125
            ],
            "min": [
                126
            ],
            "stddev": [
                133
            ],
            "stddevPop": [
                134
            ],
            "stddevSamp": [
                135
            ],
            "sum": [
                138
            ],
            "varPop": [
                141
            ],
            "varSamp": [
                142
            ],
            "variance": [
                143
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsBoolExp": {
            "_and": [
                121
            ],
            "_not": [
                121
            ],
            "_or": [
                121
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "itemId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "notes": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsConstraint": {},
        "AuditFindingsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "itemId": [
                1218
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "itemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "itemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                117
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsOnConflict": {
            "constraint": [
                122
            ],
            "updateColumns": [
                139
            ],
            "where": [
                121
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "itemId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsSelectColumn": {},
        "AuditFindingsSetInput": {
            "archived": [
                198
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsStreamCursorInput": {
            "initialValue": [
                137
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "itemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsUpdateColumn": {},
        "AuditFindingsUpdates": {
            "_inc": [
                123
            ],
            "_set": [
                132
            ],
            "where": [
                121
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditFindingsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItems": {
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "auditId": [
                1218
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "status": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsAggregate": {
            "aggregate": [
                146
            ],
            "nodes": [
                144
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsAggregateFields": {
            "avg": [
                147
            ],
            "count": [
                675,
                {
                    "columns": [
                        158,
                        "[AuditItemsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                152
            ],
            "min": [
                153
            ],
            "stddev": [
                160
            ],
            "stddevPop": [
                161
            ],
            "stddevSamp": [
                162
            ],
            "sum": [
                165
            ],
            "varPop": [
                168
            ],
            "varSamp": [
                169
            ],
            "variance": [
                170
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsBoolExp": {
            "_and": [
                148
            ],
            "_not": [
                148
            ],
            "_or": [
                148
            ],
            "archived": [
                199
            ],
            "assetId": [
                1168
            ],
            "auditId": [
                1168
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "notes": [
                1034
            ],
            "status": [
                199
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsConstraint": {},
        "AuditItemsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsInsertInput": {
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "auditId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "notes": [
                1033
            ],
            "status": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsMaxFields": {
            "assetId": [
                1218
            ],
            "auditId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsMinFields": {
            "assetId": [
                1218
            ],
            "auditId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                144
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsOnConflict": {
            "constraint": [
                149
            ],
            "updateColumns": [
                166
            ],
            "where": [
                148
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsOrderBy": {
            "archived": [
                786
            ],
            "assetId": [
                786
            ],
            "auditId": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "status": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsSelectColumn": {},
        "AuditItemsSetInput": {
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "auditId": [
                1218
            ],
            "id": [
                1218
            ],
            "notes": [
                1033
            ],
            "status": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsStreamCursorInput": {
            "initialValue": [
                164
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "auditId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "status": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsUpdateColumn": {},
        "AuditItemsUpdates": {
            "_inc": [
                150
            ],
            "_set": [
                159
            ],
            "where": [
                148
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditItemsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "Audits": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "location": [
                738
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsAggregate": {
            "aggregate": [
                173
            ],
            "nodes": [
                171
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsAggregateFields": {
            "avg": [
                174
            ],
            "count": [
                675,
                {
                    "columns": [
                        185,
                        "[AuditsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                179
            ],
            "min": [
                180
            ],
            "stddev": [
                187
            ],
            "stddevPop": [
                188
            ],
            "stddevSamp": [
                189
            ],
            "sum": [
                192
            ],
            "varPop": [
                195
            ],
            "varSamp": [
                196
            ],
            "variance": [
                197
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsBoolExp": {
            "_and": [
                175
            ],
            "_not": [
                175
            ],
            "_or": [
                175
            ],
            "active": [
                199
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "label": [
                1034
            ],
            "lastActivity": [
                1077
            ],
            "location": [
                746
            ],
            "locationId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsConstraint": {},
        "AuditsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsInsertInput": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "location": [
                758
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                171
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsOnConflict": {
            "constraint": [
                176
            ],
            "updateColumns": [
                193
            ],
            "where": [
                175
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsOrderBy": {
            "active": [
                786
            ],
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "label": [
                786
            ],
            "lastActivity": [
                786
            ],
            "location": [
                760
            ],
            "locationId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsSelectColumn": {},
        "AuditsSetInput": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsStreamCursorInput": {
            "initialValue": [
                191
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsStreamCursorValueInput": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsUpdateColumn": {},
        "AuditsUpdates": {
            "_inc": [
                177
            ],
            "_set": [
                186
            ],
            "where": [
                175
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "AuditsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "Boolean": {},
        "BooleanComparisonExp": {
            "_eq": [
                198
            ],
            "_gt": [
                198
            ],
            "_gte": [
                198
            ],
            "_in": [
                198
            ],
            "_isNull": [
                198
            ],
            "_lt": [
                198
            ],
            "_lte": [
                198
            ],
            "_neq": [
                198
            ],
            "_nin": [
                198
            ],
            "__typename": [
                1033
            ]
        },
        "Companies": {
            "assetTypes": [
                0,
                {
                    "distinctOn": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        18,
                        "[AssetTypesOrderBy!]"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assetTypesAggregate": [
                1,
                {
                    "distinctOn": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        18,
                        "[AssetTypesOrderBy!]"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assets": [
                42,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsAggregate": [
                43,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "createdBy": [
                1150
            ],
            "customFields": [
                342,
                {
                    "distinctOn": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        359,
                        "[CustomFieldsOrderBy!]"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "customFieldsAggregate": [
                343,
                {
                    "distinctOn": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        359,
                        "[CustomFieldsOrderBy!]"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "energyMeasures": [
                383,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energyMeasuresAggregate": [
                384,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energySources": [
                426,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "energySourcesAggregate": [
                427,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "files": [
                1108,
                {
                    "distinctOn": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1126,
                        "[UploadsOrderBy!]"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "filesAggregate": [
                1109,
                {
                    "distinctOn": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1126,
                        "[UploadsOrderBy!]"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "id": [
                675
            ],
            "isolationDevices": [
                677,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesAggregate": [
                678,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "locations": [
                738,
                {
                    "distinctOn": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        760,
                        "[LocationsOrderBy!]"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "locationsAggregate": [
                739,
                {
                    "distinctOn": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        760,
                        "[LocationsOrderBy!]"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "name": [
                1033
            ],
            "permissions": [
                787,
                {
                    "distinctOn": [
                        794,
                        "[PermissionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        793,
                        "[PermissionsOrderBy!]"
                    ],
                    "where": [
                        790
                    ]
                }
            ],
            "procedureTypes": [
                878,
                {
                    "distinctOn": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        896,
                        "[ProcedureTypesOrderBy!]"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedureTypesAggregate": [
                879,
                {
                    "distinctOn": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        896,
                        "[ProcedureTypesOrderBy!]"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedures": [
                920,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresAggregate": [
                921,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "settings": [
                218
            ],
            "statuses": [
                991,
                {
                    "distinctOn": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1009,
                        "[StatusesOrderBy!]"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "statusesAggregate": [
                992,
                {
                    "distinctOn": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1009,
                        "[StatusesOrderBy!]"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "templates": [
                1035,
                {
                    "distinctOn": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1053,
                        "[TemplatesOrderBy!]"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "templatesAggregate": [
                1036,
                {
                    "distinctOn": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1053,
                        "[TemplatesOrderBy!]"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "updatedBy": [
                1150
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesAggregate": {
            "aggregate": [
                202
            ],
            "nodes": [
                200
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesAggregateFields": {
            "avg": [
                203
            ],
            "count": [
                675,
                {
                    "columns": [
                        208,
                        "[CompaniesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                205
            ],
            "min": [
                206
            ],
            "stddev": [
                209
            ],
            "stddevPop": [
                210
            ],
            "stddevSamp": [
                211
            ],
            "sum": [
                214
            ],
            "varPop": [
                215
            ],
            "varSamp": [
                216
            ],
            "variance": [
                217
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesAvgFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesBoolExp": {
            "_and": [
                204
            ],
            "_not": [
                204
            ],
            "_or": [
                204
            ],
            "assetTypes": [
                7
            ],
            "assetTypesAggregate": [
                2
            ],
            "assets": [
                51
            ],
            "assetsAggregate": [
                44
            ],
            "controlPoints": [
                299
            ],
            "controlPointsAggregate": [
                292
            ],
            "createdBy": [
                1154
            ],
            "customFields": [
                349
            ],
            "customFieldsAggregate": [
                344
            ],
            "energyMeasures": [
                391
            ],
            "energyMeasuresAggregate": [
                385
            ],
            "energySources": [
                434
            ],
            "energySourcesAggregate": [
                428
            ],
            "files": [
                1115
            ],
            "filesAggregate": [
                1110
            ],
            "id": [
                676
            ],
            "isolationDevices": [
                685
            ],
            "isolationDevicesAggregate": [
                679
            ],
            "locations": [
                746
            ],
            "locationsAggregate": [
                740
            ],
            "name": [
                1034
            ],
            "permissions": [
                790
            ],
            "procedureTypes": [
                885
            ],
            "procedureTypesAggregate": [
                880
            ],
            "procedures": [
                929
            ],
            "proceduresAggregate": [
                922
            ],
            "settings": [
                226
            ],
            "statuses": [
                998
            ],
            "statusesAggregate": [
                993
            ],
            "templates": [
                1042
            ],
            "templatesAggregate": [
                1037
            ],
            "updatedBy": [
                1154
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesMaxFields": {
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesMinFields": {
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesOrderBy": {
            "assetTypesAggregate": [
                4
            ],
            "assetsAggregate": [
                46
            ],
            "controlPointsAggregate": [
                294
            ],
            "createdBy": [
                1157
            ],
            "customFieldsAggregate": [
                346
            ],
            "energyMeasuresAggregate": [
                387
            ],
            "energySourcesAggregate": [
                430
            ],
            "filesAggregate": [
                1112
            ],
            "id": [
                786
            ],
            "isolationDevicesAggregate": [
                681
            ],
            "locationsAggregate": [
                742
            ],
            "name": [
                786
            ],
            "permissionsAggregate": [
                788
            ],
            "procedureTypesAggregate": [
                882
            ],
            "proceduresAggregate": [
                924
            ],
            "settings": [
                236
            ],
            "statusesAggregate": [
                995
            ],
            "templatesAggregate": [
                1039
            ],
            "updatedBy": [
                1157
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesSelectColumn": {},
        "CompaniesStddevFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesStddevPopFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesStddevSampFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesStreamCursorInput": {
            "initialValue": [
                213
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesStreamCursorValueInput": {
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesSumFields": {
            "id": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesVarPopFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesVarSampFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompaniesVarianceFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettings": {
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "defaultStatus": [
                991
            ],
            "defaultStatusId": [
                1218
            ],
            "defaultTemplate": [
                1035
            ],
            "defaultTemplateId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsAggregate": {
            "aggregate": [
                221
            ],
            "nodes": [
                218
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsAggregateBoolExp": {
            "count": [
                1175
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsAggregateFields": {
            "avg": [
                224
            ],
            "count": [
                675,
                {
                    "columns": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                230
            ],
            "min": [
                232
            ],
            "stddev": [
                240
            ],
            "stddevPop": [
                242
            ],
            "stddevSamp": [
                244
            ],
            "sum": [
                248
            ],
            "varPop": [
                252
            ],
            "varSamp": [
                254
            ],
            "variance": [
                256
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsAggregateOrderBy": {
            "avg": [
                225
            ],
            "count": [
                786
            ],
            "max": [
                231
            ],
            "min": [
                233
            ],
            "stddev": [
                241
            ],
            "stddevPop": [
                243
            ],
            "stddevSamp": [
                245
            ],
            "sum": [
                249
            ],
            "varPop": [
                253
            ],
            "varSamp": [
                255
            ],
            "variance": [
                257
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsArrRelInsertInput": {
            "data": [
                229
            ],
            "onConflict": [
                235
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsBoolExp": {
            "_and": [
                226
            ],
            "_not": [
                226
            ],
            "_or": [
                226
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "defaultStatus": [
                998
            ],
            "defaultStatusId": [
                1168
            ],
            "defaultTemplate": [
                1042
            ],
            "defaultTemplateId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsConstraint": {},
        "CompanySettingsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsInsertInput": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "defaultStatus": [
                1007
            ],
            "defaultStatusId": [
                1218
            ],
            "defaultTemplate": [
                1051
            ],
            "defaultTemplateId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "defaultStatusId": [
                1218
            ],
            "defaultTemplateId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "defaultStatusId": [
                786
            ],
            "defaultTemplateId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "defaultStatusId": [
                1218
            ],
            "defaultTemplateId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "defaultStatusId": [
                786
            ],
            "defaultTemplateId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                218
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsOnConflict": {
            "constraint": [
                227
            ],
            "updateColumns": [
                250
            ],
            "where": [
                226
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsOrderBy": {
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "defaultStatus": [
                1009
            ],
            "defaultStatusId": [
                786
            ],
            "defaultTemplate": [
                1053
            ],
            "defaultTemplateId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsPkColumnsInput": {
            "companyId": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsSelectColumn": {},
        "CompanySettingsSetInput": {
            "defaultStatusId": [
                1218
            ],
            "defaultTemplateId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStreamCursorInput": {
            "initialValue": [
                247
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsStreamCursorValueInput": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "defaultStatusId": [
                1218
            ],
            "defaultTemplateId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsUpdateColumn": {},
        "CompanySettingsUpdates": {
            "_inc": [
                228
            ],
            "_set": [
                239
            ],
            "where": [
                226
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CompanySettingsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplates": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194,
                {
                    "path": [
                        1033
                    ]
                }
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureTemplateId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesAggregate": {
            "aggregate": [
                260
            ],
            "nodes": [
                258
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesAggregateFields": {
            "avg": [
                262
            ],
            "count": [
                675,
                {
                    "columns": [
                        277,
                        "[ControlPointTemplatesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                270
            ],
            "min": [
                271
            ],
            "stddev": [
                279
            ],
            "stddevPop": [
                280
            ],
            "stddevSamp": [
                281
            ],
            "sum": [
                284
            ],
            "varPop": [
                287
            ],
            "varSamp": [
                288
            ],
            "variance": [
                289
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesAppendInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesBoolExp": {
            "_and": [
                263
            ],
            "_not": [
                263
            ],
            "_or": [
                263
            ],
            "archived": [
                199
            ],
            "barCode": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "customFields": [
                721
            ],
            "energyMagnitude": [
                676
            ],
            "energyMeasureId": [
                1168
            ],
            "energySourceId": [
                1168
            ],
            "id": [
                1168
            ],
            "isolationDeviceId": [
                1168
            ],
            "isolationLocation": [
                1034
            ],
            "lastActivity": [
                1077
            ],
            "methodOverride": [
                1034
            ],
            "number": [
                1034
            ],
            "photoId": [
                1168
            ],
            "procedureTemplateId": [
                1168
            ],
            "step": [
                676
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "verificationOverride": [
                1034
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesConstraint": {},
        "ControlPointTemplatesDeleteAtPathInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesDeleteElemInput": {
            "customFields": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesDeleteKeyInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesIncInput": {
            "energyMagnitude": [
                675
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesInsertInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureTemplateId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesMaxFields": {
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureTemplateId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesMinFields": {
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureTemplateId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                258
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesOnConflict": {
            "constraint": [
                264
            ],
            "updateColumns": [
                285
            ],
            "where": [
                263
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesOrderBy": {
            "archived": [
                786
            ],
            "barCode": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "customFields": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "energyMeasureId": [
                786
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "isolationDeviceId": [
                786
            ],
            "isolationLocation": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodOverride": [
                786
            ],
            "number": [
                786
            ],
            "photoId": [
                786
            ],
            "procedureTemplateId": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationOverride": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesPrependInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesSelectColumn": {},
        "ControlPointTemplatesSetInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "customFields": [
                1194
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureTemplateId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesStreamCursorInput": {
            "initialValue": [
                283
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureTemplateId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "energyMagnitude": [
                675
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesUpdateColumn": {},
        "ControlPointTemplatesUpdates": {
            "_append": [
                261
            ],
            "_deleteAtPath": [
                265
            ],
            "_deleteElem": [
                266
            ],
            "_deleteKey": [
                267
            ],
            "_inc": [
                268
            ],
            "_prepend": [
                276
            ],
            "_set": [
                278
            ],
            "where": [
                263
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointTemplatesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPoints": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194,
                {
                    "path": [
                        1033
                    ]
                }
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasure": [
                383
            ],
            "energyMeasureId": [
                1218
            ],
            "energySource": [
                426
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDevice": [
                677
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photo": [
                1108
            ],
            "photoId": [
                1218
            ],
            "procedure": [
                920
            ],
            "procedureId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAggregate": {
            "aggregate": [
                293
            ],
            "nodes": [
                290
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAggregateBoolExp": {
            "bool_and": [
                1176
            ],
            "bool_or": [
                1177
            ],
            "count": [
                1178
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAggregateFields": {
            "avg": [
                297
            ],
            "count": [
                675,
                {
                    "columns": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                306
            ],
            "min": [
                308
            ],
            "stddev": [
                319
            ],
            "stddevPop": [
                321
            ],
            "stddevSamp": [
                323
            ],
            "sum": [
                327
            ],
            "varPop": [
                331
            ],
            "varSamp": [
                333
            ],
            "variance": [
                335
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAggregateOrderBy": {
            "avg": [
                298
            ],
            "count": [
                786
            ],
            "max": [
                307
            ],
            "min": [
                309
            ],
            "stddev": [
                320
            ],
            "stddevPop": [
                322
            ],
            "stddevSamp": [
                324
            ],
            "sum": [
                328
            ],
            "varPop": [
                332
            ],
            "varSamp": [
                334
            ],
            "variance": [
                336
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAppendInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsArrRelInsertInput": {
            "data": [
                305
            ],
            "onConflict": [
                311
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsBoolExp": {
            "_and": [
                299
            ],
            "_not": [
                299
            ],
            "_or": [
                299
            ],
            "archived": [
                199
            ],
            "barCode": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "customFields": [
                721
            ],
            "energyMagnitude": [
                676
            ],
            "energyMeasure": [
                391
            ],
            "energyMeasureId": [
                1168
            ],
            "energySource": [
                434
            ],
            "energySourceId": [
                1168
            ],
            "id": [
                1168
            ],
            "isolationDevice": [
                685
            ],
            "isolationDeviceId": [
                1168
            ],
            "isolationLocation": [
                1034
            ],
            "lastActivity": [
                1077
            ],
            "methodOverride": [
                1034
            ],
            "number": [
                1034
            ],
            "photo": [
                1115
            ],
            "photoId": [
                1168
            ],
            "procedure": [
                929
            ],
            "procedureId": [
                1168
            ],
            "step": [
                676
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "verificationOverride": [
                1034
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsConstraint": {},
        "ControlPointsDeleteAtPathInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsDeleteElemInput": {
            "customFields": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsDeleteKeyInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsIncInput": {
            "energyMagnitude": [
                675
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsInsertInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasure": [
                400
            ],
            "energyMeasureId": [
                1218
            ],
            "energySource": [
                443
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDevice": [
                694
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photo": [
                1124
            ],
            "photoId": [
                1218
            ],
            "procedure": [
                941
            ],
            "procedureId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsMaxFields": {
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsMaxOrderBy": {
            "barCode": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "energyMeasureId": [
                786
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "isolationDeviceId": [
                786
            ],
            "isolationLocation": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodOverride": [
                786
            ],
            "number": [
                786
            ],
            "photoId": [
                786
            ],
            "procedureId": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationOverride": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsMinFields": {
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsMinOrderBy": {
            "barCode": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "energyMeasureId": [
                786
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "isolationDeviceId": [
                786
            ],
            "isolationLocation": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodOverride": [
                786
            ],
            "number": [
                786
            ],
            "photoId": [
                786
            ],
            "procedureId": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationOverride": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                290
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsOnConflict": {
            "constraint": [
                300
            ],
            "updateColumns": [
                329
            ],
            "where": [
                299
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsOrderBy": {
            "archived": [
                786
            ],
            "barCode": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "customFields": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "energyMeasure": [
                402
            ],
            "energyMeasureId": [
                786
            ],
            "energySource": [
                445
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "isolationDevice": [
                696
            ],
            "isolationDeviceId": [
                786
            ],
            "isolationLocation": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodOverride": [
                786
            ],
            "number": [
                786
            ],
            "photo": [
                1126
            ],
            "photoId": [
                786
            ],
            "procedure": [
                943
            ],
            "procedureId": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationOverride": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsPrependInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsSelectColumn": {},
        "ControlPointsSelectColumnControlPointsAggregateBoolExpBool_andArgumentsColumns": {},
        "ControlPointsSelectColumnControlPointsAggregateBoolExpBool_orArgumentsColumns": {},
        "ControlPointsSetInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "customFields": [
                1194
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStreamCursorInput": {
            "initialValue": [
                326
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "barCode": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "energyMagnitude": [
                675
            ],
            "energyMeasureId": [
                1218
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDeviceId": [
                1218
            ],
            "isolationLocation": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "methodOverride": [
                1033
            ],
            "number": [
                1033
            ],
            "photoId": [
                1218
            ],
            "procedureId": [
                1218
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationOverride": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "energyMagnitude": [
                675
            ],
            "step": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsUpdateColumn": {},
        "ControlPointsUpdates": {
            "_append": [
                295
            ],
            "_deleteAtPath": [
                301
            ],
            "_deleteElem": [
                302
            ],
            "_deleteKey": [
                303
            ],
            "_inc": [
                304
            ],
            "_prepend": [
                314
            ],
            "_set": [
                318
            ],
            "where": [
                299
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "energyMagnitude": [
                469
            ],
            "step": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ControlPointsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "energyMagnitude": [
                786
            ],
            "step": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CursorOrdering": {},
        "CustomFieldSubjectsEnum": {},
        "CustomFieldSubjectsEnumComparisonExp": {
            "_eq": [
                338
            ],
            "_in": [
                338
            ],
            "_isNull": [
                198
            ],
            "_neq": [
                338
            ],
            "_nin": [
                338
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldTypesEnum": {},
        "CustomFieldTypesEnumComparisonExp": {
            "_eq": [
                340
            ],
            "_in": [
                340
            ],
            "_isNull": [
                198
            ],
            "_neq": [
                340
            ],
            "_nin": [
                340
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFields": {
            "archived": [
                198
            ],
            "choices": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "fileTypes": [
                1033
            ],
            "hide": [
                198
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "regex": [
                1033
            ],
            "required": [
                198
            ],
            "subject": [
                338
            ],
            "type": [
                340
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsAggregate": {
            "aggregate": [
                345
            ],
            "nodes": [
                342
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsAggregateBoolExp": {
            "bool_and": [
                1179
            ],
            "bool_or": [
                1180
            ],
            "count": [
                1181
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsAggregateFields": {
            "avg": [
                347
            ],
            "count": [
                675,
                {
                    "columns": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                353
            ],
            "min": [
                355
            ],
            "stddev": [
                365
            ],
            "stddevPop": [
                367
            ],
            "stddevSamp": [
                369
            ],
            "sum": [
                373
            ],
            "varPop": [
                377
            ],
            "varSamp": [
                379
            ],
            "variance": [
                381
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsAggregateOrderBy": {
            "avg": [
                348
            ],
            "count": [
                786
            ],
            "max": [
                354
            ],
            "min": [
                356
            ],
            "stddev": [
                366
            ],
            "stddevPop": [
                368
            ],
            "stddevSamp": [
                370
            ],
            "sum": [
                374
            ],
            "varPop": [
                378
            ],
            "varSamp": [
                380
            ],
            "variance": [
                382
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsBoolExp": {
            "_and": [
                349
            ],
            "_not": [
                349
            ],
            "_or": [
                349
            ],
            "archived": [
                199
            ],
            "choices": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "fileTypes": [
                1034
            ],
            "hide": [
                199
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "regex": [
                1034
            ],
            "required": [
                199
            ],
            "subject": [
                339
            ],
            "type": [
                341
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsConstraint": {},
        "CustomFieldsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsInsertInput": {
            "archived": [
                198
            ],
            "choices": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "fileTypes": [
                1033
            ],
            "hide": [
                198
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "regex": [
                1033
            ],
            "required": [
                198
            ],
            "subject": [
                338
            ],
            "type": [
                340
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsMaxFields": {
            "choices": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "fileTypes": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "regex": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsMaxOrderBy": {
            "choices": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "fileTypes": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "regex": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsMinFields": {
            "choices": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "fileTypes": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "regex": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsMinOrderBy": {
            "choices": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "fileTypes": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "regex": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                342
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsOnConflict": {
            "constraint": [
                350
            ],
            "updateColumns": [
                375
            ],
            "where": [
                349
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsOrderBy": {
            "archived": [
                786
            ],
            "choices": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "fileTypes": [
                786
            ],
            "hide": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "regex": [
                786
            ],
            "required": [
                786
            ],
            "subject": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsSelectColumn": {},
        "CustomFieldsSelectColumnCustomFieldsAggregateBoolExpBool_andArgumentsColumns": {},
        "CustomFieldsSelectColumnCustomFieldsAggregateBoolExpBool_orArgumentsColumns": {},
        "CustomFieldsSetInput": {
            "archived": [
                198
            ],
            "choices": [
                1033
            ],
            "fileTypes": [
                1033
            ],
            "hide": [
                198
            ],
            "name": [
                1033
            ],
            "regex": [
                1033
            ],
            "required": [
                198
            ],
            "type": [
                340
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStreamCursorInput": {
            "initialValue": [
                372
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "choices": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "fileTypes": [
                1033
            ],
            "hide": [
                198
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "regex": [
                1033
            ],
            "required": [
                198
            ],
            "subject": [
                338
            ],
            "type": [
                340
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsUpdateColumn": {},
        "CustomFieldsUpdates": {
            "_inc": [
                351
            ],
            "_set": [
                364
            ],
            "where": [
                349
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "CustomFieldsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasures": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySource": [
                426
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresAggregate": {
            "aggregate": [
                386
            ],
            "nodes": [
                383
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresAggregateBoolExp": {
            "bool_and": [
                1182
            ],
            "bool_or": [
                1183
            ],
            "count": [
                1184
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresAggregateFields": {
            "avg": [
                389
            ],
            "count": [
                675,
                {
                    "columns": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                395
            ],
            "min": [
                397
            ],
            "stddev": [
                408
            ],
            "stddevPop": [
                410
            ],
            "stddevSamp": [
                412
            ],
            "sum": [
                416
            ],
            "varPop": [
                420
            ],
            "varSamp": [
                422
            ],
            "variance": [
                424
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresAggregateOrderBy": {
            "avg": [
                390
            ],
            "count": [
                786
            ],
            "max": [
                396
            ],
            "min": [
                398
            ],
            "stddev": [
                409
            ],
            "stddevPop": [
                411
            ],
            "stddevSamp": [
                413
            ],
            "sum": [
                417
            ],
            "varPop": [
                421
            ],
            "varSamp": [
                423
            ],
            "variance": [
                425
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresArrRelInsertInput": {
            "data": [
                394
            ],
            "onConflict": [
                401
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresBoolExp": {
            "_and": [
                391
            ],
            "_not": [
                391
            ],
            "_or": [
                391
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "controlPoints": [
                299
            ],
            "controlPointsAggregate": [
                292
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "energySource": [
                434
            ],
            "energySourceId": [
                1168
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresConstraint": {},
        "EnergyMeasuresIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                296
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySource": [
                443
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                383
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresObjRelInsertInput": {
            "data": [
                394
            ],
            "onConflict": [
                401
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresOnConflict": {
            "constraint": [
                392
            ],
            "updateColumns": [
                418
            ],
            "where": [
                391
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "controlPointsAggregate": [
                294
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energySource": [
                445
            ],
            "energySourceId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresSelectColumn": {},
        "EnergyMeasuresSelectColumnEnergyMeasuresAggregateBoolExpBool_andArgumentsColumns": {},
        "EnergyMeasuresSelectColumnEnergyMeasuresAggregateBoolExpBool_orArgumentsColumns": {},
        "EnergyMeasuresSetInput": {
            "archived": [
                198
            ],
            "energySourceId": [
                1218
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStreamCursorInput": {
            "initialValue": [
                415
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourceId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresUpdateColumn": {},
        "EnergyMeasuresUpdates": {
            "_inc": [
                393
            ],
            "_set": [
                407
            ],
            "where": [
                391
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergyMeasuresVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySources": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energyMeasures": [
                383,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energyMeasuresAggregate": [
                384,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "icon": [
                1108
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDevices": [
                677,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesAggregate": [
                678,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "prefix": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesAggregate": {
            "aggregate": [
                429
            ],
            "nodes": [
                426
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesAggregateBoolExp": {
            "bool_and": [
                1185
            ],
            "bool_or": [
                1186
            ],
            "count": [
                1187
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesAggregateFields": {
            "avg": [
                432
            ],
            "count": [
                675,
                {
                    "columns": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                438
            ],
            "min": [
                440
            ],
            "stddev": [
                451
            ],
            "stddevPop": [
                453
            ],
            "stddevSamp": [
                455
            ],
            "sum": [
                459
            ],
            "varPop": [
                463
            ],
            "varSamp": [
                465
            ],
            "variance": [
                467
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesAggregateOrderBy": {
            "avg": [
                433
            ],
            "count": [
                786
            ],
            "max": [
                439
            ],
            "min": [
                441
            ],
            "stddev": [
                452
            ],
            "stddevPop": [
                454
            ],
            "stddevSamp": [
                456
            ],
            "sum": [
                460
            ],
            "varPop": [
                464
            ],
            "varSamp": [
                466
            ],
            "variance": [
                468
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesArrRelInsertInput": {
            "data": [
                437
            ],
            "onConflict": [
                444
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesBoolExp": {
            "_and": [
                434
            ],
            "_not": [
                434
            ],
            "_or": [
                434
            ],
            "archived": [
                199
            ],
            "color": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "controlPoints": [
                299
            ],
            "controlPointsAggregate": [
                292
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "energyMeasures": [
                391
            ],
            "energyMeasuresAggregate": [
                385
            ],
            "icon": [
                1115
            ],
            "iconId": [
                1168
            ],
            "id": [
                1168
            ],
            "isolationDevices": [
                685
            ],
            "isolationDevicesAggregate": [
                679
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "prefix": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesConstraint": {},
        "EnergySourcesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesInsertInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                296
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energyMeasures": [
                388
            ],
            "icon": [
                1124
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "isolationDevices": [
                682
            ],
            "name": [
                1033
            ],
            "prefix": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesMaxFields": {
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "prefix": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesMaxOrderBy": {
            "color": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "prefix": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesMinFields": {
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "prefix": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesMinOrderBy": {
            "color": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "prefix": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                426
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesObjRelInsertInput": {
            "data": [
                437
            ],
            "onConflict": [
                444
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesOnConflict": {
            "constraint": [
                435
            ],
            "updateColumns": [
                461
            ],
            "where": [
                434
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesOrderBy": {
            "archived": [
                786
            ],
            "color": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "controlPointsAggregate": [
                294
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energyMeasuresAggregate": [
                387
            ],
            "icon": [
                1126
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "isolationDevicesAggregate": [
                681
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "prefix": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesSelectColumn": {},
        "EnergySourcesSelectColumnEnergySourcesAggregateBoolExpBool_andArgumentsColumns": {},
        "EnergySourcesSelectColumnEnergySourcesAggregateBoolExpBool_orArgumentsColumns": {},
        "EnergySourcesSetInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "iconId": [
                1218
            ],
            "name": [
                1033
            ],
            "prefix": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStreamCursorInput": {
            "initialValue": [
                458
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "prefix": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesUpdateColumn": {},
        "EnergySourcesUpdates": {
            "_inc": [
                436
            ],
            "_set": [
                450
            ],
            "where": [
                434
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "EnergySourcesVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "Float": {},
        "Hazards": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "icon": [
                1108
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "procedureHazards": [
                804,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureHazardsAggregate": [
                805,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "statement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsAggregate": {
            "aggregate": [
                473
            ],
            "nodes": [
                470
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsAggregateBoolExp": {
            "bool_and": [
                1188
            ],
            "bool_or": [
                1189
            ],
            "count": [
                1190
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsAggregateFields": {
            "avg": [
                476
            ],
            "count": [
                675,
                {
                    "columns": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                482
            ],
            "min": [
                484
            ],
            "stddev": [
                495
            ],
            "stddevPop": [
                497
            ],
            "stddevSamp": [
                499
            ],
            "sum": [
                503
            ],
            "varPop": [
                507
            ],
            "varSamp": [
                509
            ],
            "variance": [
                511
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsAggregateOrderBy": {
            "avg": [
                477
            ],
            "count": [
                786
            ],
            "max": [
                483
            ],
            "min": [
                485
            ],
            "stddev": [
                496
            ],
            "stddevPop": [
                498
            ],
            "stddevSamp": [
                500
            ],
            "sum": [
                504
            ],
            "varPop": [
                508
            ],
            "varSamp": [
                510
            ],
            "variance": [
                512
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsArrRelInsertInput": {
            "data": [
                481
            ],
            "onConflict": [
                488
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsBoolExp": {
            "_and": [
                478
            ],
            "_not": [
                478
            ],
            "_or": [
                478
            ],
            "archived": [
                199
            ],
            "color": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "icon": [
                1115
            ],
            "iconId": [
                1168
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "procedureHazards": [
                812
            ],
            "procedureHazardsAggregate": [
                806
            ],
            "statement": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsConstraint": {},
        "HazardsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsInsertInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "icon": [
                1124
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "procedureHazards": [
                809
            ],
            "statement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsMaxFields": {
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "statement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsMaxOrderBy": {
            "color": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "statement": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsMinFields": {
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "statement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsMinOrderBy": {
            "color": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "statement": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                470
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsObjRelInsertInput": {
            "data": [
                481
            ],
            "onConflict": [
                488
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsOnConflict": {
            "constraint": [
                479
            ],
            "updateColumns": [
                505
            ],
            "where": [
                478
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsOrderBy": {
            "archived": [
                786
            ],
            "color": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "icon": [
                1126
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "procedureHazardsAggregate": [
                808
            ],
            "statement": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsSelectColumn": {},
        "HazardsSelectColumnHazardsAggregateBoolExpBool_andArgumentsColumns": {},
        "HazardsSelectColumnHazardsAggregateBoolExpBool_orArgumentsColumns": {},
        "HazardsSetInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "iconId": [
                1218
            ],
            "name": [
                1033
            ],
            "statement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStreamCursorInput": {
            "initialValue": [
                502
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "statement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsUpdateColumn": {},
        "HazardsUpdates": {
            "_inc": [
                480
            ],
            "_set": [
                494
            ],
            "where": [
                478
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "HazardsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotos": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionFindingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosAggregate": {
            "aggregate": [
                515
            ],
            "nodes": [
                513
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosAggregateFields": {
            "avg": [
                516
            ],
            "count": [
                675,
                {
                    "columns": [
                        527,
                        "[InspectionFindingPhotosSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                521
            ],
            "min": [
                522
            ],
            "stddev": [
                529
            ],
            "stddevPop": [
                530
            ],
            "stddevSamp": [
                531
            ],
            "sum": [
                534
            ],
            "varPop": [
                537
            ],
            "varSamp": [
                538
            ],
            "variance": [
                539
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosBoolExp": {
            "_and": [
                517
            ],
            "_not": [
                517
            ],
            "_or": [
                517
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "inspectionFindingId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "photoId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosConstraint": {},
        "InspectionFindingPhotosIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionFindingId": [
                1218
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionFindingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionFindingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                513
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosOnConflict": {
            "constraint": [
                518
            ],
            "updateColumns": [
                535
            ],
            "where": [
                517
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "inspectionFindingId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "photoId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosPkColumnsInput": {
            "companyId": [
                675
            ],
            "inspectionFindingId": [
                1218
            ],
            "photoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosSelectColumn": {},
        "InspectionFindingPhotosSetInput": {
            "archived": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosStreamCursorInput": {
            "initialValue": [
                533
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionFindingId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosUpdateColumn": {},
        "InspectionFindingPhotosUpdates": {
            "_inc": [
                519
            ],
            "_set": [
                528
            ],
            "where": [
                517
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingPhotosVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindings": {
            "archived": [
                198
            ],
            "circuit": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "phase": [
                1033
            ],
            "reasonId": [
                1218
            ],
            "severityId": [
                1218
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsAggregate": {
            "aggregate": [
                542
            ],
            "nodes": [
                540
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsAggregateFields": {
            "avg": [
                543
            ],
            "count": [
                675,
                {
                    "columns": [
                        554,
                        "[InspectionFindingsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                548
            ],
            "min": [
                549
            ],
            "stddev": [
                556
            ],
            "stddevPop": [
                557
            ],
            "stddevSamp": [
                558
            ],
            "sum": [
                561
            ],
            "varPop": [
                564
            ],
            "varSamp": [
                565
            ],
            "variance": [
                566
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsBoolExp": {
            "_and": [
                544
            ],
            "_not": [
                544
            ],
            "_or": [
                544
            ],
            "archived": [
                199
            ],
            "circuit": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "inspectionItemId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "notes": [
                1034
            ],
            "phase": [
                1034
            ],
            "reasonId": [
                1168
            ],
            "severityId": [
                1168
            ],
            "temperatureAmbient": [
                785
            ],
            "temperatureHigh": [
                785
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsConstraint": {},
        "InspectionFindingsIncInput": {
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsInsertInput": {
            "archived": [
                198
            ],
            "circuit": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionItemId": [
                1218
            ],
            "notes": [
                1033
            ],
            "phase": [
                1033
            ],
            "reasonId": [
                1218
            ],
            "severityId": [
                1218
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsMaxFields": {
            "circuit": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "phase": [
                1033
            ],
            "reasonId": [
                1218
            ],
            "severityId": [
                1218
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsMinFields": {
            "circuit": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "phase": [
                1033
            ],
            "reasonId": [
                1218
            ],
            "severityId": [
                1218
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                540
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsOnConflict": {
            "constraint": [
                545
            ],
            "updateColumns": [
                562
            ],
            "where": [
                544
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsOrderBy": {
            "archived": [
                786
            ],
            "circuit": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "inspectionItemId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "phase": [
                786
            ],
            "reasonId": [
                786
            ],
            "severityId": [
                786
            ],
            "temperatureAmbient": [
                786
            ],
            "temperatureHigh": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsSelectColumn": {},
        "InspectionFindingsSetInput": {
            "archived": [
                198
            ],
            "circuit": [
                1033
            ],
            "notes": [
                1033
            ],
            "phase": [
                1033
            ],
            "reasonId": [
                1218
            ],
            "severityId": [
                1218
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsStreamCursorInput": {
            "initialValue": [
                560
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "circuit": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "phase": [
                1033
            ],
            "reasonId": [
                1218
            ],
            "severityId": [
                1218
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "temperatureAmbient": [
                1198
            ],
            "temperatureHigh": [
                1198
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsUpdateColumn": {},
        "InspectionFindingsUpdates": {
            "_inc": [
                546
            ],
            "_set": [
                555
            ],
            "where": [
                544
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionFindingsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "temperatureAmbient": [
                469
            ],
            "temperatureHigh": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotos": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosAggregate": {
            "aggregate": [
                569
            ],
            "nodes": [
                567
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosAggregateFields": {
            "avg": [
                570
            ],
            "count": [
                675,
                {
                    "columns": [
                        581,
                        "[InspectionItemPhotosSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                575
            ],
            "min": [
                576
            ],
            "stddev": [
                583
            ],
            "stddevPop": [
                584
            ],
            "stddevSamp": [
                585
            ],
            "sum": [
                588
            ],
            "varPop": [
                591
            ],
            "varSamp": [
                592
            ],
            "variance": [
                593
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosBoolExp": {
            "_and": [
                571
            ],
            "_not": [
                571
            ],
            "_or": [
                571
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "inspectionItemId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "photoId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosConstraint": {},
        "InspectionItemPhotosIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionItemId": [
                1218
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                567
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosOnConflict": {
            "constraint": [
                572
            ],
            "updateColumns": [
                589
            ],
            "where": [
                571
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "inspectionItemId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "photoId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosPkColumnsInput": {
            "companyId": [
                675
            ],
            "inspectionItemId": [
                1218
            ],
            "photoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosSelectColumn": {},
        "InspectionItemPhotosSetInput": {
            "archived": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosStreamCursorInput": {
            "initialValue": [
                587
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "inspectionItemId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "photoId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosUpdateColumn": {},
        "InspectionItemPhotosUpdates": {
            "_inc": [
                573
            ],
            "_set": [
                582
            ],
            "where": [
                571
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemPhotosVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItems": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "status": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsAggregate": {
            "aggregate": [
                596
            ],
            "nodes": [
                594
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsAggregateFields": {
            "avg": [
                597
            ],
            "count": [
                675,
                {
                    "columns": [
                        608,
                        "[InspectionItemsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                602
            ],
            "min": [
                603
            ],
            "stddev": [
                610
            ],
            "stddevPop": [
                611
            ],
            "stddevSamp": [
                612
            ],
            "sum": [
                615
            ],
            "varPop": [
                618
            ],
            "varSamp": [
                619
            ],
            "variance": [
                620
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsAvgFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsBoolExp": {
            "_and": [
                598
            ],
            "_not": [
                598
            ],
            "_or": [
                598
            ],
            "amperageA": [
                785
            ],
            "amperageB": [
                785
            ],
            "amperageC": [
                785
            ],
            "amperageN": [
                785
            ],
            "archived": [
                199
            ],
            "assetId": [
                1168
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "inspectionId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "notes": [
                1034
            ],
            "status": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsConstraint": {},
        "InspectionItemsIncInput": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsInsertInput": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionId": [
                1218
            ],
            "notes": [
                1033
            ],
            "status": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsMaxFields": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "status": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsMinFields": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "status": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                594
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsOnConflict": {
            "constraint": [
                599
            ],
            "updateColumns": [
                616
            ],
            "where": [
                598
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsOrderBy": {
            "amperageA": [
                786
            ],
            "amperageB": [
                786
            ],
            "amperageC": [
                786
            ],
            "amperageN": [
                786
            ],
            "archived": [
                786
            ],
            "assetId": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "inspectionId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "status": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsSelectColumn": {},
        "InspectionItemsSetInput": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "archived": [
                198
            ],
            "notes": [
                1033
            ],
            "status": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsStddevFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsStddevPopFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsStddevSampFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsStreamCursorInput": {
            "initialValue": [
                614
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsStreamCursorValueInput": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "inspectionId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "status": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsSumFields": {
            "amperageA": [
                1198
            ],
            "amperageB": [
                1198
            ],
            "amperageC": [
                1198
            ],
            "amperageN": [
                1198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsUpdateColumn": {},
        "InspectionItemsUpdates": {
            "_inc": [
                600
            ],
            "_set": [
                609
            ],
            "where": [
                598
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsVarPopFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsVarSampFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionItemsVarianceFields": {
            "amperageA": [
                469
            ],
            "amperageB": [
                469
            ],
            "amperageC": [
                469
            ],
            "amperageN": [
                469
            ],
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasons": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsAggregate": {
            "aggregate": [
                623
            ],
            "nodes": [
                621
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsAggregateFields": {
            "avg": [
                624
            ],
            "count": [
                675,
                {
                    "columns": [
                        635,
                        "[InspectionReasonsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                629
            ],
            "min": [
                630
            ],
            "stddev": [
                637
            ],
            "stddevPop": [
                638
            ],
            "stddevSamp": [
                639
            ],
            "sum": [
                642
            ],
            "varPop": [
                645
            ],
            "varSamp": [
                646
            ],
            "variance": [
                647
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsBoolExp": {
            "_and": [
                625
            ],
            "_not": [
                625
            ],
            "_or": [
                625
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "description": [
                1034
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsConstraint": {},
        "InspectionReasonsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                621
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsOnConflict": {
            "constraint": [
                626
            ],
            "updateColumns": [
                643
            ],
            "where": [
                625
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "description": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsSelectColumn": {},
        "InspectionReasonsSetInput": {
            "archived": [
                198
            ],
            "description": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsStreamCursorInput": {
            "initialValue": [
                641
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsUpdateColumn": {},
        "InspectionReasonsUpdates": {
            "_inc": [
                627
            ],
            "_set": [
                636
            ],
            "where": [
                625
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionReasonsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "Inspections": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsAggregate": {
            "aggregate": [
                650
            ],
            "nodes": [
                648
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsAggregateFields": {
            "avg": [
                651
            ],
            "count": [
                675,
                {
                    "columns": [
                        662,
                        "[InspectionsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                656
            ],
            "min": [
                657
            ],
            "stddev": [
                664
            ],
            "stddevPop": [
                665
            ],
            "stddevSamp": [
                666
            ],
            "sum": [
                669
            ],
            "varPop": [
                672
            ],
            "varSamp": [
                673
            ],
            "variance": [
                674
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsBoolExp": {
            "_and": [
                652
            ],
            "_not": [
                652
            ],
            "_or": [
                652
            ],
            "active": [
                199
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "label": [
                1034
            ],
            "lastActivity": [
                1077
            ],
            "locationId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsConstraint": {},
        "InspectionsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsInsertInput": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                648
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsOnConflict": {
            "constraint": [
                653
            ],
            "updateColumns": [
                670
            ],
            "where": [
                652
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsOrderBy": {
            "active": [
                786
            ],
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "label": [
                786
            ],
            "lastActivity": [
                786
            ],
            "locationId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsSelectColumn": {},
        "InspectionsSetInput": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "label": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsStreamCursorInput": {
            "initialValue": [
                668
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsStreamCursorValueInput": {
            "active": [
                198
            ],
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "locationId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsUpdateColumn": {},
        "InspectionsUpdates": {
            "_inc": [
                654
            ],
            "_set": [
                663
            ],
            "where": [
                652
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "InspectionsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "Int": {},
        "IntComparisonExp": {
            "_eq": [
                675
            ],
            "_gt": [
                675
            ],
            "_gte": [
                675
            ],
            "_in": [
                675
            ],
            "_isNull": [
                198
            ],
            "_lt": [
                675
            ],
            "_lte": [
                675
            ],
            "_neq": [
                675
            ],
            "_nin": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevices": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySource": [
                426
            ],
            "energySourceId": [
                1218
            ],
            "icon": [
                1108
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "methodStatement": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationStatement": [
                1033
            ],
            "video": [
                1108
            ],
            "videoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesAggregate": {
            "aggregate": [
                680
            ],
            "nodes": [
                677
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesAggregateBoolExp": {
            "bool_and": [
                1191
            ],
            "bool_or": [
                1192
            ],
            "count": [
                1193
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesAggregateFields": {
            "avg": [
                683
            ],
            "count": [
                675,
                {
                    "columns": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                689
            ],
            "min": [
                691
            ],
            "stddev": [
                702
            ],
            "stddevPop": [
                704
            ],
            "stddevSamp": [
                706
            ],
            "sum": [
                710
            ],
            "varPop": [
                714
            ],
            "varSamp": [
                716
            ],
            "variance": [
                718
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesAggregateOrderBy": {
            "avg": [
                684
            ],
            "count": [
                786
            ],
            "max": [
                690
            ],
            "min": [
                692
            ],
            "stddev": [
                703
            ],
            "stddevPop": [
                705
            ],
            "stddevSamp": [
                707
            ],
            "sum": [
                711
            ],
            "varPop": [
                715
            ],
            "varSamp": [
                717
            ],
            "variance": [
                719
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesArrRelInsertInput": {
            "data": [
                688
            ],
            "onConflict": [
                695
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesBoolExp": {
            "_and": [
                685
            ],
            "_not": [
                685
            ],
            "_or": [
                685
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "controlPoints": [
                299
            ],
            "controlPointsAggregate": [
                292
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "energySource": [
                434
            ],
            "energySourceId": [
                1168
            ],
            "icon": [
                1115
            ],
            "iconId": [
                1168
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "methodStatement": [
                1034
            ],
            "name": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "verificationStatement": [
                1034
            ],
            "video": [
                1115
            ],
            "videoId": [
                1168
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesConstraint": {},
        "IsolationDevicesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                296
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySource": [
                443
            ],
            "energySourceId": [
                1218
            ],
            "icon": [
                1124
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "methodStatement": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationStatement": [
                1033
            ],
            "video": [
                1124
            ],
            "videoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourceId": [
                1218
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "methodStatement": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationStatement": [
                1033
            ],
            "videoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energySourceId": [
                786
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodStatement": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationStatement": [
                786
            ],
            "videoId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourceId": [
                1218
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "methodStatement": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationStatement": [
                1033
            ],
            "videoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energySourceId": [
                786
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodStatement": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationStatement": [
                786
            ],
            "videoId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                677
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesObjRelInsertInput": {
            "data": [
                688
            ],
            "onConflict": [
                695
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesOnConflict": {
            "constraint": [
                686
            ],
            "updateColumns": [
                712
            ],
            "where": [
                685
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "controlPointsAggregate": [
                294
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energySource": [
                445
            ],
            "energySourceId": [
                786
            ],
            "icon": [
                1126
            ],
            "iconId": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "methodStatement": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "verificationStatement": [
                786
            ],
            "video": [
                1126
            ],
            "videoId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesSelectColumn": {},
        "IsolationDevicesSelectColumnIsolationDevicesAggregateBoolExpBool_andArgumentsColumns": {},
        "IsolationDevicesSelectColumnIsolationDevicesAggregateBoolExpBool_orArgumentsColumns": {},
        "IsolationDevicesSetInput": {
            "archived": [
                198
            ],
            "energySourceId": [
                1218
            ],
            "iconId": [
                1218
            ],
            "methodStatement": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationStatement": [
                1033
            ],
            "videoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStreamCursorInput": {
            "initialValue": [
                709
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourceId": [
                1218
            ],
            "iconId": [
                1218
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "methodStatement": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "verificationStatement": [
                1033
            ],
            "videoId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesUpdateColumn": {},
        "IsolationDevicesUpdates": {
            "_inc": [
                687
            ],
            "_set": [
                701
            ],
            "where": [
                685
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "IsolationDevicesVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "JsonbCastExp": {
            "String": [
                1034
            ],
            "__typename": [
                1033
            ]
        },
        "JsonbComparisonExp": {
            "_cast": [
                720
            ],
            "_containedIn": [
                1194
            ],
            "_contains": [
                1194
            ],
            "_eq": [
                1194
            ],
            "_gt": [
                1194
            ],
            "_gte": [
                1194
            ],
            "_hasKey": [
                1033
            ],
            "_hasKeysAll": [
                1033
            ],
            "_hasKeysAny": [
                1033
            ],
            "_in": [
                1194
            ],
            "_isNull": [
                198
            ],
            "_lt": [
                1194
            ],
            "_lte": [
                1194
            ],
            "_neq": [
                1194
            ],
            "_nin": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "Languages": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "created_by": [
                1150
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "translations": [
                1078,
                {
                    "distinctOn": [
                        1092,
                        "[TranslationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1090,
                        "[TranslationsOrderBy!]"
                    ],
                    "where": [
                        1082
                    ]
                }
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "updated_by": [
                1150
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesBoolExp": {
            "_and": [
                723
            ],
            "_not": [
                723
            ],
            "_or": [
                723
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "created_by": [
                1154
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "translations": [
                1082
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "updated_by": [
                1154
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesConstraint": {},
        "LanguagesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "translations": [
                1080
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                722
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesObjRelInsertInput": {
            "data": [
                726
            ],
            "onConflict": [
                729
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesOnConflict": {
            "constraint": [
                724
            ],
            "updateColumns": [
                736
            ],
            "where": [
                723
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "created_by": [
                1157
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "translationsAggregate": [
                1079
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "updated_by": [
                1157
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesSelectColumn": {},
        "LanguagesSetInput": {
            "archived": [
                198
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesStreamCursorInput": {
            "initialValue": [
                735
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LanguagesUpdateColumn": {},
        "LanguagesUpdates": {
            "_inc": [
                725
            ],
            "_set": [
                733
            ],
            "where": [
                723
            ],
            "__typename": [
                1033
            ]
        },
        "Locations": {
            "address": [
                1033
            ],
            "archived": [
                198
            ],
            "assets": [
                42,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsAggregate": [
                43,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194,
                {
                    "path": [
                        1033
                    ]
                }
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAggregate": {
            "aggregate": [
                741
            ],
            "nodes": [
                738
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAggregateBoolExp": {
            "bool_and": [
                1195
            ],
            "bool_or": [
                1196
            ],
            "count": [
                1197
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAggregateFields": {
            "avg": [
                744
            ],
            "count": [
                675,
                {
                    "columns": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                753
            ],
            "min": [
                755
            ],
            "stddev": [
                767
            ],
            "stddevPop": [
                769
            ],
            "stddevSamp": [
                771
            ],
            "sum": [
                775
            ],
            "varPop": [
                779
            ],
            "varSamp": [
                781
            ],
            "variance": [
                783
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAggregateOrderBy": {
            "avg": [
                745
            ],
            "count": [
                786
            ],
            "max": [
                754
            ],
            "min": [
                756
            ],
            "stddev": [
                768
            ],
            "stddevPop": [
                770
            ],
            "stddevSamp": [
                772
            ],
            "sum": [
                776
            ],
            "varPop": [
                780
            ],
            "varSamp": [
                782
            ],
            "variance": [
                784
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAppendInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsBoolExp": {
            "_and": [
                746
            ],
            "_not": [
                746
            ],
            "_or": [
                746
            ],
            "address": [
                1034
            ],
            "archived": [
                199
            ],
            "assets": [
                51
            ],
            "assetsAggregate": [
                44
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "customFields": [
                721
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsConstraint": {},
        "LocationsDeleteAtPathInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsDeleteElemInput": {
            "customFields": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsDeleteKeyInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsInsertInput": {
            "address": [
                1033
            ],
            "archived": [
                198
            ],
            "assets": [
                48
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsMaxFields": {
            "address": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsMaxOrderBy": {
            "address": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsMinFields": {
            "address": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsMinOrderBy": {
            "address": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                738
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsObjRelInsertInput": {
            "data": [
                752
            ],
            "onConflict": [
                759
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsOnConflict": {
            "constraint": [
                747
            ],
            "updateColumns": [
                777
            ],
            "where": [
                746
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsOrderBy": {
            "address": [
                786
            ],
            "archived": [
                786
            ],
            "assetsAggregate": [
                46
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "customFields": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsPrependInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsSelectColumn": {},
        "LocationsSelectColumnLocationsAggregateBoolExpBool_andArgumentsColumns": {},
        "LocationsSelectColumnLocationsAggregateBoolExpBool_orArgumentsColumns": {},
        "LocationsSetInput": {
            "address": [
                1033
            ],
            "archived": [
                198
            ],
            "customFields": [
                1194
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStreamCursorInput": {
            "initialValue": [
                774
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsStreamCursorValueInput": {
            "address": [
                1033
            ],
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsUpdateColumn": {},
        "LocationsUpdates": {
            "_append": [
                743
            ],
            "_deleteAtPath": [
                748
            ],
            "_deleteElem": [
                749
            ],
            "_deleteKey": [
                750
            ],
            "_inc": [
                751
            ],
            "_prepend": [
                762
            ],
            "_set": [
                766
            ],
            "where": [
                746
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "LocationsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "NumericComparisonExp": {
            "_eq": [
                1198
            ],
            "_gt": [
                1198
            ],
            "_gte": [
                1198
            ],
            "_in": [
                1198
            ],
            "_isNull": [
                198
            ],
            "_lt": [
                1198
            ],
            "_lte": [
                1198
            ],
            "_neq": [
                1198
            ],
            "_nin": [
                1198
            ],
            "__typename": [
                1033
            ]
        },
        "OrderBy": {},
        "Permissions": {
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "role": [
                970
            ],
            "user": [
                1150
            ],
            "userId": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsAggregateOrderBy": {
            "avg": [
                789
            ],
            "count": [
                786
            ],
            "max": [
                791
            ],
            "min": [
                792
            ],
            "stddev": [
                795
            ],
            "stddevPop": [
                796
            ],
            "stddevSamp": [
                797
            ],
            "sum": [
                800
            ],
            "varPop": [
                801
            ],
            "varSamp": [
                802
            ],
            "variance": [
                803
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsAvgOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsBoolExp": {
            "_and": [
                790
            ],
            "_not": [
                790
            ],
            "_or": [
                790
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "role": [
                971
            ],
            "user": [
                1154
            ],
            "userId": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsMaxOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsMinOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsOrderBy": {
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "role": [
                786
            ],
            "user": [
                1157
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsSelectColumn": {},
        "PermissionsStddevOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsStreamCursorInput": {
            "initialValue": [
                799
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsStreamCursorValueInput": {
            "companyId": [
                675
            ],
            "role": [
                970
            ],
            "userId": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsSumOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "PermissionsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "userId": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazards": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "hazard": [
                470
            ],
            "hazardId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "procedure": [
                920
            ],
            "procedureId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsAggregate": {
            "aggregate": [
                807
            ],
            "nodes": [
                804
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsAggregateBoolExp": {
            "bool_and": [
                1199
            ],
            "bool_or": [
                1200
            ],
            "count": [
                1201
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsAggregateFields": {
            "avg": [
                810
            ],
            "count": [
                675,
                {
                    "columns": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                816
            ],
            "min": [
                818
            ],
            "stddev": [
                828
            ],
            "stddevPop": [
                830
            ],
            "stddevSamp": [
                832
            ],
            "sum": [
                836
            ],
            "varPop": [
                840
            ],
            "varSamp": [
                842
            ],
            "variance": [
                844
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsAggregateOrderBy": {
            "avg": [
                811
            ],
            "count": [
                786
            ],
            "max": [
                817
            ],
            "min": [
                819
            ],
            "stddev": [
                829
            ],
            "stddevPop": [
                831
            ],
            "stddevSamp": [
                833
            ],
            "sum": [
                837
            ],
            "varPop": [
                841
            ],
            "varSamp": [
                843
            ],
            "variance": [
                845
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsArrRelInsertInput": {
            "data": [
                815
            ],
            "onConflict": [
                821
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsBoolExp": {
            "_and": [
                812
            ],
            "_not": [
                812
            ],
            "_or": [
                812
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "hazard": [
                478
            ],
            "hazardId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "procedure": [
                929
            ],
            "procedureId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsConstraint": {},
        "ProcedureHazardsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "hazard": [
                487
            ],
            "hazardId": [
                1218
            ],
            "procedure": [
                941
            ],
            "procedureId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "hazardId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "procedureId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "hazardId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "procedureId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "hazardId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "procedureId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "hazardId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "procedureId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                804
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsOnConflict": {
            "constraint": [
                813
            ],
            "updateColumns": [
                838
            ],
            "where": [
                812
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "hazard": [
                489
            ],
            "hazardId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "procedure": [
                943
            ],
            "procedureId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsPkColumnsInput": {
            "companyId": [
                675
            ],
            "hazardId": [
                1218
            ],
            "procedureId": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsSelectColumn": {},
        "ProcedureHazardsSelectColumnProcedureHazardsAggregateBoolExpBool_andArgumentsColumns": {},
        "ProcedureHazardsSelectColumnProcedureHazardsAggregateBoolExpBool_orArgumentsColumns": {},
        "ProcedureHazardsSetInput": {
            "archived": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStreamCursorInput": {
            "initialValue": [
                835
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "hazardId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "procedureId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsUpdateColumn": {},
        "ProcedureHazardsUpdates": {
            "_inc": [
                814
            ],
            "_set": [
                827
            ],
            "where": [
                812
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureHazardsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplates": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194,
                {
                    "path": [
                        1033
                    ]
                }
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "showInMenu": [
                198
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesAggregate": {
            "aggregate": [
                848
            ],
            "nodes": [
                846
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesAggregateFields": {
            "avg": [
                850
            ],
            "count": [
                675,
                {
                    "columns": [
                        865,
                        "[ProcedureTemplatesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                858
            ],
            "min": [
                859
            ],
            "stddev": [
                867
            ],
            "stddevPop": [
                868
            ],
            "stddevSamp": [
                869
            ],
            "sum": [
                872
            ],
            "varPop": [
                875
            ],
            "varSamp": [
                876
            ],
            "variance": [
                877
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesAppendInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesBoolExp": {
            "_and": [
                851
            ],
            "_not": [
                851
            ],
            "_or": [
                851
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "customFields": [
                721
            ],
            "description": [
                1034
            ],
            "id": [
                1168
            ],
            "label": [
                1034
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "notes": [
                1034
            ],
            "showInMenu": [
                199
            ],
            "typeId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesConstraint": {},
        "ProcedureTemplatesDeleteAtPathInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesDeleteElemInput": {
            "customFields": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesDeleteKeyInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "showInMenu": [
                198
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                846
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesOnConflict": {
            "constraint": [
                852
            ],
            "updateColumns": [
                873
            ],
            "where": [
                851
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "customFields": [
                786
            ],
            "description": [
                786
            ],
            "id": [
                786
            ],
            "label": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "notes": [
                786
            ],
            "showInMenu": [
                786
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesPrependInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesSelectColumn": {},
        "ProcedureTemplatesSetInput": {
            "archived": [
                198
            ],
            "customFields": [
                1194
            ],
            "description": [
                1033
            ],
            "label": [
                1033
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "showInMenu": [
                198
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesStreamCursorInput": {
            "initialValue": [
                871
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "description": [
                1033
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "notes": [
                1033
            ],
            "showInMenu": [
                198
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesUpdateColumn": {},
        "ProcedureTemplatesUpdates": {
            "_append": [
                849
            ],
            "_deleteAtPath": [
                853
            ],
            "_deleteElem": [
                854
            ],
            "_deleteKey": [
                855
            ],
            "_inc": [
                856
            ],
            "_prepend": [
                864
            ],
            "_set": [
                866
            ],
            "where": [
                851
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTemplatesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypes": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "procedures": [
                920,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresAggregate": [
                921,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesAggregate": {
            "aggregate": [
                881
            ],
            "nodes": [
                878
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesAggregateBoolExp": {
            "bool_and": [
                1202
            ],
            "bool_or": [
                1203
            ],
            "count": [
                1204
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesAggregateFields": {
            "avg": [
                883
            ],
            "count": [
                675,
                {
                    "columns": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                889
            ],
            "min": [
                891
            ],
            "stddev": [
                902
            ],
            "stddevPop": [
                904
            ],
            "stddevSamp": [
                906
            ],
            "sum": [
                910
            ],
            "varPop": [
                914
            ],
            "varSamp": [
                916
            ],
            "variance": [
                918
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesAggregateOrderBy": {
            "avg": [
                884
            ],
            "count": [
                786
            ],
            "max": [
                890
            ],
            "min": [
                892
            ],
            "stddev": [
                903
            ],
            "stddevPop": [
                905
            ],
            "stddevSamp": [
                907
            ],
            "sum": [
                911
            ],
            "varPop": [
                915
            ],
            "varSamp": [
                917
            ],
            "variance": [
                919
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesBoolExp": {
            "_and": [
                885
            ],
            "_not": [
                885
            ],
            "_or": [
                885
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "procedures": [
                929
            ],
            "proceduresAggregate": [
                922
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesConstraint": {},
        "ProcedureTypesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "procedures": [
                926
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                878
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesObjRelInsertInput": {
            "data": [
                888
            ],
            "onConflict": [
                895
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesOnConflict": {
            "constraint": [
                886
            ],
            "updateColumns": [
                912
            ],
            "where": [
                885
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "proceduresAggregate": [
                924
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesSelectColumn": {},
        "ProcedureTypesSelectColumnProcedureTypesAggregateBoolExpBool_andArgumentsColumns": {},
        "ProcedureTypesSelectColumnProcedureTypesAggregateBoolExpBool_orArgumentsColumns": {},
        "ProcedureTypesSetInput": {
            "archived": [
                198
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStreamCursorInput": {
            "initialValue": [
                909
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesUpdateColumn": {},
        "ProcedureTypesUpdates": {
            "_inc": [
                887
            ],
            "_set": [
                901
            ],
            "where": [
                885
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProcedureTypesVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "Procedures": {
            "archived": [
                198
            ],
            "asset": [
                42
            ],
            "assetId": [
                1218
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194,
                {
                    "path": [
                        1033
                    ]
                }
            ],
            "fromAudit": [
                198
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "procedureHazards": [
                804,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureHazardsAggregate": [
                805,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "status": [
                991
            ],
            "statusId": [
                1218
            ],
            "type": [
                878
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAggregate": {
            "aggregate": [
                923
            ],
            "nodes": [
                920
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAggregateBoolExp": {
            "bool_and": [
                1205
            ],
            "bool_or": [
                1206
            ],
            "count": [
                1207
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAggregateFields": {
            "avg": [
                927
            ],
            "count": [
                675,
                {
                    "columns": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                936
            ],
            "min": [
                938
            ],
            "stddev": [
                950
            ],
            "stddevPop": [
                952
            ],
            "stddevSamp": [
                954
            ],
            "sum": [
                958
            ],
            "varPop": [
                962
            ],
            "varSamp": [
                964
            ],
            "variance": [
                966
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAggregateOrderBy": {
            "avg": [
                928
            ],
            "count": [
                786
            ],
            "max": [
                937
            ],
            "min": [
                939
            ],
            "stddev": [
                951
            ],
            "stddevPop": [
                953
            ],
            "stddevSamp": [
                955
            ],
            "sum": [
                959
            ],
            "varPop": [
                963
            ],
            "varSamp": [
                965
            ],
            "variance": [
                967
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAppendInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresArrRelInsertInput": {
            "data": [
                935
            ],
            "onConflict": [
                942
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresBoolExp": {
            "_and": [
                929
            ],
            "_not": [
                929
            ],
            "_or": [
                929
            ],
            "archived": [
                199
            ],
            "asset": [
                51
            ],
            "assetId": [
                1168
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "controlPoints": [
                299
            ],
            "controlPointsAggregate": [
                292
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "customFields": [
                721
            ],
            "fromAudit": [
                199
            ],
            "id": [
                1168
            ],
            "label": [
                1034
            ],
            "lastActivity": [
                1077
            ],
            "notes": [
                1034
            ],
            "procedureHazards": [
                812
            ],
            "procedureHazardsAggregate": [
                806
            ],
            "status": [
                998
            ],
            "statusId": [
                1168
            ],
            "type": [
                885
            ],
            "typeId": [
                1168
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresConstraint": {},
        "ProceduresDeleteAtPathInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresDeleteElemInput": {
            "customFields": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresDeleteKeyInput": {
            "customFields": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresInsertInput": {
            "archived": [
                198
            ],
            "asset": [
                63
            ],
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "controlPoints": [
                296
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "fromAudit": [
                198
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "notes": [
                1033
            ],
            "procedureHazards": [
                809
            ],
            "status": [
                1007
            ],
            "statusId": [
                1218
            ],
            "type": [
                894
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresMaxFields": {
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "statusId": [
                1218
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresMaxOrderBy": {
            "assetId": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "label": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "statusId": [
                786
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresMinFields": {
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "statusId": [
                1218
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresMinOrderBy": {
            "assetId": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "label": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "statusId": [
                786
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                920
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresObjRelInsertInput": {
            "data": [
                935
            ],
            "onConflict": [
                942
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresOnConflict": {
            "constraint": [
                930
            ],
            "updateColumns": [
                960
            ],
            "where": [
                929
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresOrderBy": {
            "archived": [
                786
            ],
            "asset": [
                65
            ],
            "assetId": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "controlPointsAggregate": [
                294
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "customFields": [
                786
            ],
            "fromAudit": [
                786
            ],
            "id": [
                786
            ],
            "label": [
                786
            ],
            "lastActivity": [
                786
            ],
            "notes": [
                786
            ],
            "procedureHazardsAggregate": [
                808
            ],
            "status": [
                1009
            ],
            "statusId": [
                786
            ],
            "type": [
                896
            ],
            "typeId": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresPrependInput": {
            "customFields": [
                1194
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresSelectColumn": {},
        "ProceduresSelectColumnProceduresAggregateBoolExpBool_andArgumentsColumns": {},
        "ProceduresSelectColumnProceduresAggregateBoolExpBool_orArgumentsColumns": {},
        "ProceduresSetInput": {
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "customFields": [
                1194
            ],
            "label": [
                1033
            ],
            "notes": [
                1033
            ],
            "statusId": [
                1218
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStreamCursorInput": {
            "initialValue": [
                957
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresStreamCursorValueInput": {
            "archived": [
                198
            ],
            "assetId": [
                1218
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "customFields": [
                1194
            ],
            "fromAudit": [
                198
            ],
            "id": [
                1218
            ],
            "label": [
                1033
            ],
            "lastActivity": [
                1214
            ],
            "notes": [
                1033
            ],
            "statusId": [
                1218
            ],
            "typeId": [
                1218
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresUpdateColumn": {},
        "ProceduresUpdates": {
            "_append": [
                925
            ],
            "_deleteAtPath": [
                931
            ],
            "_deleteElem": [
                932
            ],
            "_deleteKey": [
                933
            ],
            "_inc": [
                934
            ],
            "_prepend": [
                945
            ],
            "_set": [
                949
            ],
            "where": [
                929
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "ProceduresVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "Roles": {
            "role": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "RolesBoolExp": {
            "_and": [
                969
            ],
            "_not": [
                969
            ],
            "_or": [
                969
            ],
            "role": [
                1034
            ],
            "__typename": [
                1033
            ]
        },
        "RolesEnum": {},
        "RolesEnumComparisonExp": {
            "_eq": [
                970
            ],
            "_in": [
                970
            ],
            "_isNull": [
                198
            ],
            "_neq": [
                970
            ],
            "_nin": [
                970
            ],
            "__typename": [
                1033
            ]
        },
        "RolesOrderBy": {
            "role": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "RolesSelectColumn": {},
        "RolesStreamCursorInput": {
            "initialValue": [
                975
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "RolesStreamCursorValueInput": {
            "role": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "Severities": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesBoolExp": {
            "_and": [
                977
            ],
            "_not": [
                977
            ],
            "_or": [
                977
            ],
            "archived": [
                199
            ],
            "color": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesConstraint": {},
        "SeveritiesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesInsertInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                976
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesOnConflict": {
            "constraint": [
                978
            ],
            "updateColumns": [
                989
            ],
            "where": [
                977
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesOrderBy": {
            "archived": [
                786
            ],
            "color": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesSelectColumn": {},
        "SeveritiesSetInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesStreamCursorInput": {
            "initialValue": [
                988
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "SeveritiesUpdateColumn": {},
        "SeveritiesUpdates": {
            "_inc": [
                979
            ],
            "_set": [
                986
            ],
            "where": [
                977
            ],
            "__typename": [
                1033
            ]
        },
        "Statuses": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "defaultOfAggregate": [
                219,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "default_of": [
                218,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "procedures": [
                920,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresAggregate": [
                921,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "public": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesAggregate": {
            "aggregate": [
                994
            ],
            "nodes": [
                991
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesAggregateBoolExp": {
            "bool_and": [
                1208
            ],
            "bool_or": [
                1209
            ],
            "count": [
                1210
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesAggregateFields": {
            "avg": [
                996
            ],
            "count": [
                675,
                {
                    "columns": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                1002
            ],
            "min": [
                1004
            ],
            "stddev": [
                1015
            ],
            "stddevPop": [
                1017
            ],
            "stddevSamp": [
                1019
            ],
            "sum": [
                1023
            ],
            "varPop": [
                1027
            ],
            "varSamp": [
                1029
            ],
            "variance": [
                1031
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesAggregateOrderBy": {
            "avg": [
                997
            ],
            "count": [
                786
            ],
            "max": [
                1003
            ],
            "min": [
                1005
            ],
            "stddev": [
                1016
            ],
            "stddevPop": [
                1018
            ],
            "stddevSamp": [
                1020
            ],
            "sum": [
                1024
            ],
            "varPop": [
                1028
            ],
            "varSamp": [
                1030
            ],
            "variance": [
                1032
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesBoolExp": {
            "_and": [
                998
            ],
            "_not": [
                998
            ],
            "_or": [
                998
            ],
            "archived": [
                199
            ],
            "color": [
                1034
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "default_of": [
                226
            ],
            "default_ofAggregate": [
                220
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "procedures": [
                929
            ],
            "proceduresAggregate": [
                922
            ],
            "public": [
                199
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesConstraint": {},
        "StatusesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesInsertInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "default_of": [
                223
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "procedures": [
                926
            ],
            "public": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesMaxFields": {
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesMaxOrderBy": {
            "color": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesMinFields": {
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesMinOrderBy": {
            "color": [
                786
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                991
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesObjRelInsertInput": {
            "data": [
                1001
            ],
            "onConflict": [
                1008
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesOnConflict": {
            "constraint": [
                999
            ],
            "updateColumns": [
                1025
            ],
            "where": [
                998
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesOrderBy": {
            "archived": [
                786
            ],
            "color": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "default_ofAggregate": [
                222
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "proceduresAggregate": [
                924
            ],
            "public": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesSelectColumn": {},
        "StatusesSelectColumnStatusesAggregateBoolExpBool_andArgumentsColumns": {},
        "StatusesSelectColumnStatusesAggregateBoolExpBool_orArgumentsColumns": {},
        "StatusesSetInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "name": [
                1033
            ],
            "public": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStreamCursorInput": {
            "initialValue": [
                1022
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "color": [
                1033
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "public": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesUpdateColumn": {},
        "StatusesUpdates": {
            "_inc": [
                1000
            ],
            "_set": [
                1014
            ],
            "where": [
                998
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "StatusesVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "String": {},
        "StringComparisonExp": {
            "_eq": [
                1033
            ],
            "_gt": [
                1033
            ],
            "_gte": [
                1033
            ],
            "_ilike": [
                1033
            ],
            "_in": [
                1033
            ],
            "_iregex": [
                1033
            ],
            "_isNull": [
                198
            ],
            "_like": [
                1033
            ],
            "_lt": [
                1033
            ],
            "_lte": [
                1033
            ],
            "_neq": [
                1033
            ],
            "_nilike": [
                1033
            ],
            "_nin": [
                1033
            ],
            "_niregex": [
                1033
            ],
            "_nlike": [
                1033
            ],
            "_nregex": [
                1033
            ],
            "_nsimilar": [
                1033
            ],
            "_regex": [
                1033
            ],
            "_similar": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "Templates": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "css": [
                1033
            ],
            "defaultOfAggregate": [
                219,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "default_of": [
                218,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "html": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesAggregate": {
            "aggregate": [
                1038
            ],
            "nodes": [
                1035
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesAggregateBoolExp": {
            "bool_and": [
                1211
            ],
            "bool_or": [
                1212
            ],
            "count": [
                1213
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesAggregateFields": {
            "avg": [
                1040
            ],
            "count": [
                675,
                {
                    "columns": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                1046
            ],
            "min": [
                1048
            ],
            "stddev": [
                1059
            ],
            "stddevPop": [
                1061
            ],
            "stddevSamp": [
                1063
            ],
            "sum": [
                1067
            ],
            "varPop": [
                1071
            ],
            "varSamp": [
                1073
            ],
            "variance": [
                1075
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesAggregateOrderBy": {
            "avg": [
                1041
            ],
            "count": [
                786
            ],
            "max": [
                1047
            ],
            "min": [
                1049
            ],
            "stddev": [
                1060
            ],
            "stddevPop": [
                1062
            ],
            "stddevSamp": [
                1064
            ],
            "sum": [
                1068
            ],
            "varPop": [
                1072
            ],
            "varSamp": [
                1074
            ],
            "variance": [
                1076
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesBoolExp": {
            "_and": [
                1042
            ],
            "_not": [
                1042
            ],
            "_or": [
                1042
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "css": [
                1034
            ],
            "default_of": [
                226
            ],
            "default_ofAggregate": [
                220
            ],
            "html": [
                1034
            ],
            "id": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "type": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesConstraint": {},
        "TemplatesIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "css": [
                1033
            ],
            "default_of": [
                223
            ],
            "html": [
                1033
            ],
            "id": [
                1218
            ],
            "name": [
                1033
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "css": [
                1033
            ],
            "html": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "css": [
                786
            ],
            "html": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "css": [
                1033
            ],
            "html": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "css": [
                786
            ],
            "html": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                1035
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesObjRelInsertInput": {
            "data": [
                1045
            ],
            "onConflict": [
                1052
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesOnConflict": {
            "constraint": [
                1043
            ],
            "updateColumns": [
                1069
            ],
            "where": [
                1042
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "css": [
                786
            ],
            "default_ofAggregate": [
                222
            ],
            "html": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesSelectColumn": {},
        "TemplatesSelectColumnTemplatesAggregateBoolExpBool_andArgumentsColumns": {},
        "TemplatesSelectColumnTemplatesAggregateBoolExpBool_orArgumentsColumns": {},
        "TemplatesSetInput": {
            "archived": [
                198
            ],
            "css": [
                1033
            ],
            "html": [
                1033
            ],
            "name": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStreamCursorInput": {
            "initialValue": [
                1066
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "css": [
                1033
            ],
            "html": [
                1033
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesUpdateColumn": {},
        "TemplatesUpdates": {
            "_inc": [
                1044
            ],
            "_set": [
                1058
            ],
            "where": [
                1042
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "updatedById": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "TemplatesVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TimestamptzComparisonExp": {
            "_eq": [
                1214
            ],
            "_gt": [
                1214
            ],
            "_gte": [
                1214
            ],
            "_in": [
                1214
            ],
            "_isNull": [
                198
            ],
            "_lt": [
                1214
            ],
            "_lte": [
                1214
            ],
            "_neq": [
                1214
            ],
            "_nin": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "Translations": {
            "archived": [
                198
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "created_by": [
                1150
            ],
            "id": [
                1218
            ],
            "language": [
                722
            ],
            "languageId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "pattern": [
                1033
            ],
            "replacement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "updated_by": [
                1150
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsAggregateOrderBy": {
            "avg": [
                1081
            ],
            "count": [
                786
            ],
            "max": [
                1086
            ],
            "min": [
                1087
            ],
            "stddev": [
                1094
            ],
            "stddevPop": [
                1095
            ],
            "stddevSamp": [
                1096
            ],
            "sum": [
                1099
            ],
            "varPop": [
                1102
            ],
            "varSamp": [
                1103
            ],
            "variance": [
                1104
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsArrRelInsertInput": {
            "data": [
                1085
            ],
            "onConflict": [
                1089
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsBoolExp": {
            "_and": [
                1082
            ],
            "_not": [
                1082
            ],
            "_or": [
                1082
            ],
            "archived": [
                199
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "created_by": [
                1154
            ],
            "id": [
                1168
            ],
            "language": [
                723
            ],
            "languageId": [
                1168
            ],
            "lastActivity": [
                1077
            ],
            "pattern": [
                1034
            ],
            "replacement": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "updated_by": [
                1154
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsConstraint": {},
        "TranslationsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsInsertInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "language": [
                728
            ],
            "languageId": [
                1218
            ],
            "pattern": [
                1033
            ],
            "replacement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "languageId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "pattern": [
                786
            ],
            "replacement": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "id": [
                786
            ],
            "languageId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "pattern": [
                786
            ],
            "replacement": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                1078
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsOnConflict": {
            "constraint": [
                1083
            ],
            "updateColumns": [
                1100
            ],
            "where": [
                1082
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsOrderBy": {
            "archived": [
                786
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "created_by": [
                1157
            ],
            "id": [
                786
            ],
            "language": [
                730
            ],
            "languageId": [
                786
            ],
            "lastActivity": [
                786
            ],
            "pattern": [
                786
            ],
            "replacement": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "updated_by": [
                1157
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsSelectColumn": {},
        "TranslationsSetInput": {
            "archived": [
                198
            ],
            "pattern": [
                1033
            ],
            "replacement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsStreamCursorInput": {
            "initialValue": [
                1098
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "id": [
                1218
            ],
            "languageId": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "pattern": [
                1033
            ],
            "replacement": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsUpdateColumn": {},
        "TranslationsUpdates": {
            "_inc": [
                1084
            ],
            "_set": [
                1093
            ],
            "where": [
                1082
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "TranslationsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "updatedById": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UpInput": {
            "companyId": [
                675
            ],
            "id": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "UpOutput": {
            "items": [
                1107
            ],
            "__typename": [
                1033
            ]
        },
        "UpOutputItem": {
            "error": [
                1033
            ],
            "url": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "Uploads": {
            "archived": [
                198
            ],
            "assets": [
                42,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsAggregate": [
                43,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "company": [
                200
            ],
            "companyId": [
                675
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "control_points": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "createdBy": [
                1150
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energySourcesAggregate": [
                427,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "energy_sources": [
                426,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "hazards": [
                470,
                {
                    "distinctOn": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        489,
                        "[HazardsOrderBy!]"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "hazardsAggregate": [
                471,
                {
                    "distinctOn": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        489,
                        "[HazardsOrderBy!]"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "height": [
                675
            ],
            "id": [
                1218
            ],
            "isolationDevicesAggregate": [
                678,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesByCompanyIdVideoId": [
                677,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesByCompanyIdVideoIdAggregate": [
                678,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolation_devices": [
                677,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "present": [
                198
            ],
            "resized": [
                198
            ],
            "size": [
                675
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "updated_by": [
                1150
            ],
            "width": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsAggregate": {
            "aggregate": [
                1111
            ],
            "nodes": [
                1108
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsAggregateBoolExp": {
            "bool_and": [
                1215
            ],
            "bool_or": [
                1216
            ],
            "count": [
                1217
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsAggregateFields": {
            "avg": [
                1113
            ],
            "count": [
                675,
                {
                    "columns": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                1119
            ],
            "min": [
                1121
            ],
            "stddev": [
                1132
            ],
            "stddevPop": [
                1134
            ],
            "stddevSamp": [
                1136
            ],
            "sum": [
                1140
            ],
            "varPop": [
                1144
            ],
            "varSamp": [
                1146
            ],
            "variance": [
                1148
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsAggregateOrderBy": {
            "avg": [
                1114
            ],
            "count": [
                786
            ],
            "max": [
                1120
            ],
            "min": [
                1122
            ],
            "stddev": [
                1133
            ],
            "stddevPop": [
                1135
            ],
            "stddevSamp": [
                1137
            ],
            "sum": [
                1141
            ],
            "varPop": [
                1145
            ],
            "varSamp": [
                1147
            ],
            "variance": [
                1149
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsAvgFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsAvgOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsBoolExp": {
            "_and": [
                1115
            ],
            "_not": [
                1115
            ],
            "_or": [
                1115
            ],
            "archived": [
                199
            ],
            "assets": [
                51
            ],
            "assetsAggregate": [
                44
            ],
            "company": [
                204
            ],
            "companyId": [
                676
            ],
            "control_points": [
                299
            ],
            "control_pointsAggregate": [
                292
            ],
            "createdBy": [
                1154
            ],
            "createdById": [
                676
            ],
            "createdOn": [
                1077
            ],
            "energy_sources": [
                434
            ],
            "energy_sourcesAggregate": [
                428
            ],
            "hazards": [
                478
            ],
            "hazardsAggregate": [
                472
            ],
            "height": [
                676
            ],
            "id": [
                1168
            ],
            "isolationDevicesByCompanyIdVideoId": [
                685
            ],
            "isolationDevicesByCompanyIdVideoIdAggregate": [
                679
            ],
            "isolation_devices": [
                685
            ],
            "isolation_devicesAggregate": [
                679
            ],
            "lastActivity": [
                1077
            ],
            "name": [
                1034
            ],
            "present": [
                199
            ],
            "resized": [
                199
            ],
            "size": [
                676
            ],
            "type": [
                1034
            ],
            "updatedById": [
                676
            ],
            "updatedOn": [
                1077
            ],
            "updated_by": [
                1154
            ],
            "width": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsConstraint": {},
        "UploadsIncInput": {
            "updatedById": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsInsertInput": {
            "archived": [
                198
            ],
            "assets": [
                48
            ],
            "companyId": [
                675
            ],
            "control_points": [
                296
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "energy_sources": [
                431
            ],
            "hazards": [
                475
            ],
            "height": [
                675
            ],
            "id": [
                1218
            ],
            "isolationDevicesByCompanyIdVideoId": [
                682
            ],
            "isolation_devices": [
                682
            ],
            "name": [
                1033
            ],
            "size": [
                675
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "width": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsMaxFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "height": [
                675
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "size": [
                675
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "width": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsMaxOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "height": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "size": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsMinFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "height": [
                675
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "size": [
                675
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "width": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsMinOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "height": [
                786
            ],
            "id": [
                786
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "size": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsMutationResponse": {
            "affectedRows": [
                675
            ],
            "returning": [
                1108
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsObjRelInsertInput": {
            "data": [
                1118
            ],
            "onConflict": [
                1125
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsOnConflict": {
            "constraint": [
                1116
            ],
            "updateColumns": [
                1142
            ],
            "where": [
                1115
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsOrderBy": {
            "archived": [
                786
            ],
            "assetsAggregate": [
                46
            ],
            "company": [
                207
            ],
            "companyId": [
                786
            ],
            "control_pointsAggregate": [
                294
            ],
            "createdBy": [
                1157
            ],
            "createdById": [
                786
            ],
            "createdOn": [
                786
            ],
            "energy_sourcesAggregate": [
                430
            ],
            "hazardsAggregate": [
                474
            ],
            "height": [
                786
            ],
            "id": [
                786
            ],
            "isolationDevicesByCompanyIdVideoIdAggregate": [
                681
            ],
            "isolation_devicesAggregate": [
                681
            ],
            "lastActivity": [
                786
            ],
            "name": [
                786
            ],
            "present": [
                786
            ],
            "resized": [
                786
            ],
            "size": [
                786
            ],
            "type": [
                786
            ],
            "updatedById": [
                786
            ],
            "updatedOn": [
                786
            ],
            "updated_by": [
                1157
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsPkColumnsInput": {
            "companyId": [
                675
            ],
            "id": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsSelectColumn": {},
        "UploadsSelectColumnUploadsAggregateBoolExpBool_andArgumentsColumns": {},
        "UploadsSelectColumnUploadsAggregateBoolExpBool_orArgumentsColumns": {},
        "UploadsSetInput": {
            "archived": [
                198
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "uploadRequested": [
                1214
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStddevFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStddevOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStddevPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStddevPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStddevSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStddevSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStreamCursorInput": {
            "initialValue": [
                1139
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsStreamCursorValueInput": {
            "archived": [
                198
            ],
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "createdOn": [
                1214
            ],
            "height": [
                675
            ],
            "id": [
                1218
            ],
            "lastActivity": [
                1214
            ],
            "name": [
                1033
            ],
            "present": [
                198
            ],
            "resized": [
                198
            ],
            "size": [
                675
            ],
            "type": [
                1033
            ],
            "updatedById": [
                675
            ],
            "updatedOn": [
                1214
            ],
            "width": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsSumFields": {
            "companyId": [
                675
            ],
            "createdById": [
                675
            ],
            "height": [
                675
            ],
            "size": [
                675
            ],
            "updatedById": [
                675
            ],
            "width": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsSumOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsUpdateColumn": {},
        "UploadsUpdates": {
            "_inc": [
                1117
            ],
            "_set": [
                1131
            ],
            "where": [
                1115
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsVarPopFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsVarPopOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsVarSampFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsVarSampOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsVarianceFields": {
            "companyId": [
                469
            ],
            "createdById": [
                469
            ],
            "height": [
                469
            ],
            "size": [
                469
            ],
            "updatedById": [
                469
            ],
            "width": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UploadsVarianceOrderBy": {
            "companyId": [
                786
            ],
            "createdById": [
                786
            ],
            "height": [
                786
            ],
            "size": [
                786
            ],
            "updatedById": [
                786
            ],
            "width": [
                786
            ],
            "__typename": [
                1033
            ]
        },
        "Users": {
            "created_by": [
                1150
            ],
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "permissions": [
                787,
                {
                    "distinctOn": [
                        794,
                        "[PermissionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        793,
                        "[PermissionsOrderBy!]"
                    ],
                    "where": [
                        790
                    ]
                }
            ],
            "updated_by": [
                1150
            ],
            "__typename": [
                1033
            ]
        },
        "UsersAggregate": {
            "aggregate": [
                1152
            ],
            "nodes": [
                1150
            ],
            "__typename": [
                1033
            ]
        },
        "UsersAggregateFields": {
            "avg": [
                1153
            ],
            "count": [
                675,
                {
                    "columns": [
                        1158,
                        "[UsersSelectColumn!]"
                    ],
                    "distinct": [
                        198
                    ]
                }
            ],
            "max": [
                1155
            ],
            "min": [
                1156
            ],
            "stddev": [
                1159
            ],
            "stddevPop": [
                1160
            ],
            "stddevSamp": [
                1161
            ],
            "sum": [
                1164
            ],
            "varPop": [
                1165
            ],
            "varSamp": [
                1166
            ],
            "variance": [
                1167
            ],
            "__typename": [
                1033
            ]
        },
        "UsersAvgFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UsersBoolExp": {
            "_and": [
                1154
            ],
            "_not": [
                1154
            ],
            "_or": [
                1154
            ],
            "created_by": [
                1154
            ],
            "id": [
                676
            ],
            "name": [
                1034
            ],
            "permissions": [
                790
            ],
            "updated_by": [
                1154
            ],
            "__typename": [
                1033
            ]
        },
        "UsersMaxFields": {
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "UsersMinFields": {
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "UsersOrderBy": {
            "created_by": [
                1157
            ],
            "id": [
                786
            ],
            "name": [
                786
            ],
            "permissionsAggregate": [
                788
            ],
            "updated_by": [
                1157
            ],
            "__typename": [
                1033
            ]
        },
        "UsersSelectColumn": {},
        "UsersStddevFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UsersStddevPopFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UsersStddevSampFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UsersStreamCursorInput": {
            "initialValue": [
                1163
            ],
            "ordering": [
                337
            ],
            "__typename": [
                1033
            ]
        },
        "UsersStreamCursorValueInput": {
            "id": [
                675
            ],
            "name": [
                1033
            ],
            "__typename": [
                1033
            ]
        },
        "UsersSumFields": {
            "id": [
                675
            ],
            "__typename": [
                1033
            ]
        },
        "UsersVarPopFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UsersVarSampFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UsersVarianceFields": {
            "id": [
                469
            ],
            "__typename": [
                1033
            ]
        },
        "UuidComparisonExp": {
            "_eq": [
                1218
            ],
            "_gt": [
                1218
            ],
            "_gte": [
                1218
            ],
            "_in": [
                1218
            ],
            "_isNull": [
                198
            ],
            "_lt": [
                1218
            ],
            "_lte": [
                1218
            ],
            "_neq": [
                1218
            ],
            "_nin": [
                1218
            ],
            "__typename": [
                1033
            ]
        },
        "assetTypesAggregateBoolExpBool_and": {
            "arguments": [
                21
            ],
            "distinct": [
                198
            ],
            "filter": [
                7
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "assetTypesAggregateBoolExpBool_or": {
            "arguments": [
                22
            ],
            "distinct": [
                198
            ],
            "filter": [
                7
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "assetTypesAggregateBoolExpCount": {
            "arguments": [
                20
            ],
            "distinct": [
                198
            ],
            "filter": [
                7
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "assetsAggregateBoolExpBool_and": {
            "arguments": [
                69
            ],
            "distinct": [
                198
            ],
            "filter": [
                51
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "assetsAggregateBoolExpBool_or": {
            "arguments": [
                70
            ],
            "distinct": [
                198
            ],
            "filter": [
                51
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "assetsAggregateBoolExpCount": {
            "arguments": [
                68
            ],
            "distinct": [
                198
            ],
            "filter": [
                51
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "companySettingsAggregateBoolExpCount": {
            "arguments": [
                238
            ],
            "distinct": [
                198
            ],
            "filter": [
                226
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "controlPointsAggregateBoolExpBool_and": {
            "arguments": [
                316
            ],
            "distinct": [
                198
            ],
            "filter": [
                299
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "controlPointsAggregateBoolExpBool_or": {
            "arguments": [
                317
            ],
            "distinct": [
                198
            ],
            "filter": [
                299
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "controlPointsAggregateBoolExpCount": {
            "arguments": [
                315
            ],
            "distinct": [
                198
            ],
            "filter": [
                299
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "customFieldsAggregateBoolExpBool_and": {
            "arguments": [
                362
            ],
            "distinct": [
                198
            ],
            "filter": [
                349
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "customFieldsAggregateBoolExpBool_or": {
            "arguments": [
                363
            ],
            "distinct": [
                198
            ],
            "filter": [
                349
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "customFieldsAggregateBoolExpCount": {
            "arguments": [
                361
            ],
            "distinct": [
                198
            ],
            "filter": [
                349
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "energyMeasuresAggregateBoolExpBool_and": {
            "arguments": [
                405
            ],
            "distinct": [
                198
            ],
            "filter": [
                391
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "energyMeasuresAggregateBoolExpBool_or": {
            "arguments": [
                406
            ],
            "distinct": [
                198
            ],
            "filter": [
                391
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "energyMeasuresAggregateBoolExpCount": {
            "arguments": [
                404
            ],
            "distinct": [
                198
            ],
            "filter": [
                391
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "energySourcesAggregateBoolExpBool_and": {
            "arguments": [
                448
            ],
            "distinct": [
                198
            ],
            "filter": [
                434
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "energySourcesAggregateBoolExpBool_or": {
            "arguments": [
                449
            ],
            "distinct": [
                198
            ],
            "filter": [
                434
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "energySourcesAggregateBoolExpCount": {
            "arguments": [
                447
            ],
            "distinct": [
                198
            ],
            "filter": [
                434
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "hazardsAggregateBoolExpBool_and": {
            "arguments": [
                492
            ],
            "distinct": [
                198
            ],
            "filter": [
                478
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "hazardsAggregateBoolExpBool_or": {
            "arguments": [
                493
            ],
            "distinct": [
                198
            ],
            "filter": [
                478
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "hazardsAggregateBoolExpCount": {
            "arguments": [
                491
            ],
            "distinct": [
                198
            ],
            "filter": [
                478
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "isolationDevicesAggregateBoolExpBool_and": {
            "arguments": [
                699
            ],
            "distinct": [
                198
            ],
            "filter": [
                685
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "isolationDevicesAggregateBoolExpBool_or": {
            "arguments": [
                700
            ],
            "distinct": [
                198
            ],
            "filter": [
                685
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "isolationDevicesAggregateBoolExpCount": {
            "arguments": [
                698
            ],
            "distinct": [
                198
            ],
            "filter": [
                685
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "jsonb": {},
        "locationsAggregateBoolExpBool_and": {
            "arguments": [
                764
            ],
            "distinct": [
                198
            ],
            "filter": [
                746
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "locationsAggregateBoolExpBool_or": {
            "arguments": [
                765
            ],
            "distinct": [
                198
            ],
            "filter": [
                746
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "locationsAggregateBoolExpCount": {
            "arguments": [
                763
            ],
            "distinct": [
                198
            ],
            "filter": [
                746
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "numeric": {},
        "procedureHazardsAggregateBoolExpBool_and": {
            "arguments": [
                825
            ],
            "distinct": [
                198
            ],
            "filter": [
                812
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "procedureHazardsAggregateBoolExpBool_or": {
            "arguments": [
                826
            ],
            "distinct": [
                198
            ],
            "filter": [
                812
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "procedureHazardsAggregateBoolExpCount": {
            "arguments": [
                824
            ],
            "distinct": [
                198
            ],
            "filter": [
                812
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "procedureTypesAggregateBoolExpBool_and": {
            "arguments": [
                899
            ],
            "distinct": [
                198
            ],
            "filter": [
                885
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "procedureTypesAggregateBoolExpBool_or": {
            "arguments": [
                900
            ],
            "distinct": [
                198
            ],
            "filter": [
                885
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "procedureTypesAggregateBoolExpCount": {
            "arguments": [
                898
            ],
            "distinct": [
                198
            ],
            "filter": [
                885
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "proceduresAggregateBoolExpBool_and": {
            "arguments": [
                947
            ],
            "distinct": [
                198
            ],
            "filter": [
                929
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "proceduresAggregateBoolExpBool_or": {
            "arguments": [
                948
            ],
            "distinct": [
                198
            ],
            "filter": [
                929
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "proceduresAggregateBoolExpCount": {
            "arguments": [
                946
            ],
            "distinct": [
                198
            ],
            "filter": [
                929
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "statusesAggregateBoolExpBool_and": {
            "arguments": [
                1012
            ],
            "distinct": [
                198
            ],
            "filter": [
                998
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "statusesAggregateBoolExpBool_or": {
            "arguments": [
                1013
            ],
            "distinct": [
                198
            ],
            "filter": [
                998
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "statusesAggregateBoolExpCount": {
            "arguments": [
                1011
            ],
            "distinct": [
                198
            ],
            "filter": [
                998
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "templatesAggregateBoolExpBool_and": {
            "arguments": [
                1056
            ],
            "distinct": [
                198
            ],
            "filter": [
                1042
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "templatesAggregateBoolExpBool_or": {
            "arguments": [
                1057
            ],
            "distinct": [
                198
            ],
            "filter": [
                1042
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "templatesAggregateBoolExpCount": {
            "arguments": [
                1055
            ],
            "distinct": [
                198
            ],
            "filter": [
                1042
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "timestamptz": {},
        "uploadsAggregateBoolExpBool_and": {
            "arguments": [
                1129
            ],
            "distinct": [
                198
            ],
            "filter": [
                1115
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "uploadsAggregateBoolExpBool_or": {
            "arguments": [
                1130
            ],
            "distinct": [
                198
            ],
            "filter": [
                1115
            ],
            "predicate": [
                199
            ],
            "__typename": [
                1033
            ]
        },
        "uploadsAggregateBoolExpCount": {
            "arguments": [
                1128
            ],
            "distinct": [
                198
            ],
            "filter": [
                1115
            ],
            "predicate": [
                676
            ],
            "__typename": [
                1033
            ]
        },
        "uuid": {},
        "Query": {
            "assetTypes": [
                0,
                {
                    "distinctOn": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        18,
                        "[AssetTypesOrderBy!]"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assetTypesAggregate": [
                1,
                {
                    "distinctOn": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        18,
                        "[AssetTypesOrderBy!]"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assetTypesByPk": [
                0,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "assets": [
                42,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsAggregate": [
                43,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsByPk": [
                42,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditFindingPhotos": [
                90,
                {
                    "distinctOn": [
                        104,
                        "[AuditFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        102,
                        "[AuditFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        94
                    ]
                }
            ],
            "auditFindingPhotosAggregate": [
                91,
                {
                    "distinctOn": [
                        104,
                        "[AuditFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        102,
                        "[AuditFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        94
                    ]
                }
            ],
            "auditFindingPhotosByPk": [
                90,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "findingId": [
                        1218,
                        "uuid!"
                    ],
                    "photoId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditFindings": [
                117,
                {
                    "distinctOn": [
                        131,
                        "[AuditFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        129,
                        "[AuditFindingsOrderBy!]"
                    ],
                    "where": [
                        121
                    ]
                }
            ],
            "auditFindingsAggregate": [
                118,
                {
                    "distinctOn": [
                        131,
                        "[AuditFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        129,
                        "[AuditFindingsOrderBy!]"
                    ],
                    "where": [
                        121
                    ]
                }
            ],
            "auditFindingsByPk": [
                117,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditItems": [
                144,
                {
                    "distinctOn": [
                        158,
                        "[AuditItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        156,
                        "[AuditItemsOrderBy!]"
                    ],
                    "where": [
                        148
                    ]
                }
            ],
            "auditItemsAggregate": [
                145,
                {
                    "distinctOn": [
                        158,
                        "[AuditItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        156,
                        "[AuditItemsOrderBy!]"
                    ],
                    "where": [
                        148
                    ]
                }
            ],
            "auditItemsByPk": [
                144,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "audits": [
                171,
                {
                    "distinctOn": [
                        185,
                        "[AuditsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        183,
                        "[AuditsOrderBy!]"
                    ],
                    "where": [
                        175
                    ]
                }
            ],
            "auditsAggregate": [
                172,
                {
                    "distinctOn": [
                        185,
                        "[AuditsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        183,
                        "[AuditsOrderBy!]"
                    ],
                    "where": [
                        175
                    ]
                }
            ],
            "auditsByPk": [
                171,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "companies": [
                200,
                {
                    "distinctOn": [
                        208,
                        "[CompaniesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        207,
                        "[CompaniesOrderBy!]"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "companiesAggregate": [
                201,
                {
                    "distinctOn": [
                        208,
                        "[CompaniesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        207,
                        "[CompaniesOrderBy!]"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "companiesByPk": [
                200,
                {
                    "id": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "companySettings": [
                218,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "companySettingsAggregate": [
                219,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "companySettingsByPk": [
                218,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "controlPointTemplates": [
                258,
                {
                    "distinctOn": [
                        277,
                        "[ControlPointTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        274,
                        "[ControlPointTemplatesOrderBy!]"
                    ],
                    "where": [
                        263
                    ]
                }
            ],
            "controlPointTemplatesAggregate": [
                259,
                {
                    "distinctOn": [
                        277,
                        "[ControlPointTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        274,
                        "[ControlPointTemplatesOrderBy!]"
                    ],
                    "where": [
                        263
                    ]
                }
            ],
            "controlPointTemplatesByPk": [
                258,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsByPk": [
                290,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "customFields": [
                342,
                {
                    "distinctOn": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        359,
                        "[CustomFieldsOrderBy!]"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "customFieldsAggregate": [
                343,
                {
                    "distinctOn": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        359,
                        "[CustomFieldsOrderBy!]"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "customFieldsByPk": [
                342,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "energyMeasures": [
                383,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energyMeasuresAggregate": [
                384,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energyMeasuresByPk": [
                383,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "energySources": [
                426,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "energySourcesAggregate": [
                427,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "energySourcesByPk": [
                426,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "hazards": [
                470,
                {
                    "distinctOn": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        489,
                        "[HazardsOrderBy!]"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "hazardsAggregate": [
                471,
                {
                    "distinctOn": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        489,
                        "[HazardsOrderBy!]"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "hazardsByPk": [
                470,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionFindingPhotos": [
                513,
                {
                    "distinctOn": [
                        527,
                        "[InspectionFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        525,
                        "[InspectionFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        517
                    ]
                }
            ],
            "inspectionFindingPhotosAggregate": [
                514,
                {
                    "distinctOn": [
                        527,
                        "[InspectionFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        525,
                        "[InspectionFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        517
                    ]
                }
            ],
            "inspectionFindingPhotosByPk": [
                513,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "inspectionFindingId": [
                        1218,
                        "uuid!"
                    ],
                    "photoId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionFindings": [
                540,
                {
                    "distinctOn": [
                        554,
                        "[InspectionFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        552,
                        "[InspectionFindingsOrderBy!]"
                    ],
                    "where": [
                        544
                    ]
                }
            ],
            "inspectionFindingsAggregate": [
                541,
                {
                    "distinctOn": [
                        554,
                        "[InspectionFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        552,
                        "[InspectionFindingsOrderBy!]"
                    ],
                    "where": [
                        544
                    ]
                }
            ],
            "inspectionFindingsByPk": [
                540,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionItemPhotos": [
                567,
                {
                    "distinctOn": [
                        581,
                        "[InspectionItemPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        579,
                        "[InspectionItemPhotosOrderBy!]"
                    ],
                    "where": [
                        571
                    ]
                }
            ],
            "inspectionItemPhotosAggregate": [
                568,
                {
                    "distinctOn": [
                        581,
                        "[InspectionItemPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        579,
                        "[InspectionItemPhotosOrderBy!]"
                    ],
                    "where": [
                        571
                    ]
                }
            ],
            "inspectionItemPhotosByPk": [
                567,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "inspectionItemId": [
                        1218,
                        "uuid!"
                    ],
                    "photoId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionItems": [
                594,
                {
                    "distinctOn": [
                        608,
                        "[InspectionItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        606,
                        "[InspectionItemsOrderBy!]"
                    ],
                    "where": [
                        598
                    ]
                }
            ],
            "inspectionItemsAggregate": [
                595,
                {
                    "distinctOn": [
                        608,
                        "[InspectionItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        606,
                        "[InspectionItemsOrderBy!]"
                    ],
                    "where": [
                        598
                    ]
                }
            ],
            "inspectionItemsByPk": [
                594,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionReasons": [
                621,
                {
                    "distinctOn": [
                        635,
                        "[InspectionReasonsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        633,
                        "[InspectionReasonsOrderBy!]"
                    ],
                    "where": [
                        625
                    ]
                }
            ],
            "inspectionReasonsAggregate": [
                622,
                {
                    "distinctOn": [
                        635,
                        "[InspectionReasonsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        633,
                        "[InspectionReasonsOrderBy!]"
                    ],
                    "where": [
                        625
                    ]
                }
            ],
            "inspectionReasonsByPk": [
                621,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspections": [
                648,
                {
                    "distinctOn": [
                        662,
                        "[InspectionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        660,
                        "[InspectionsOrderBy!]"
                    ],
                    "where": [
                        652
                    ]
                }
            ],
            "inspectionsAggregate": [
                649,
                {
                    "distinctOn": [
                        662,
                        "[InspectionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        660,
                        "[InspectionsOrderBy!]"
                    ],
                    "where": [
                        652
                    ]
                }
            ],
            "inspectionsByPk": [
                648,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "isolationDevices": [
                677,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesAggregate": [
                678,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesByPk": [
                677,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "languages": [
                722,
                {
                    "distinctOn": [
                        732,
                        "[LanguagesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        730,
                        "[LanguagesOrderBy!]"
                    ],
                    "where": [
                        723
                    ]
                }
            ],
            "languagesByPk": [
                722,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "locations": [
                738,
                {
                    "distinctOn": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        760,
                        "[LocationsOrderBy!]"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "locationsAggregate": [
                739,
                {
                    "distinctOn": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        760,
                        "[LocationsOrderBy!]"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "locationsByPk": [
                738,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "permissions": [
                787,
                {
                    "distinctOn": [
                        794,
                        "[PermissionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        793,
                        "[PermissionsOrderBy!]"
                    ],
                    "where": [
                        790
                    ]
                }
            ],
            "permissionsByPk": [
                787,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "role": [
                        970,
                        "RolesEnum!"
                    ],
                    "userId": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "procedureHazards": [
                804,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureHazardsAggregate": [
                805,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureHazardsByPk": [
                804,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "hazardId": [
                        1218,
                        "uuid!"
                    ],
                    "procedureId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "procedureTemplates": [
                846,
                {
                    "distinctOn": [
                        865,
                        "[ProcedureTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        862,
                        "[ProcedureTemplatesOrderBy!]"
                    ],
                    "where": [
                        851
                    ]
                }
            ],
            "procedureTemplatesAggregate": [
                847,
                {
                    "distinctOn": [
                        865,
                        "[ProcedureTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        862,
                        "[ProcedureTemplatesOrderBy!]"
                    ],
                    "where": [
                        851
                    ]
                }
            ],
            "procedureTemplatesByPk": [
                846,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "procedureTypes": [
                878,
                {
                    "distinctOn": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        896,
                        "[ProcedureTypesOrderBy!]"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedureTypesAggregate": [
                879,
                {
                    "distinctOn": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        896,
                        "[ProcedureTypesOrderBy!]"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedureTypesByPk": [
                878,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "procedures": [
                920,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresAggregate": [
                921,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresByPk": [
                920,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "roles": [
                968,
                {
                    "distinctOn": [
                        973,
                        "[RolesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        972,
                        "[RolesOrderBy!]"
                    ],
                    "where": [
                        969
                    ]
                }
            ],
            "rolesByPk": [
                968,
                {
                    "role": [
                        1033,
                        "String!"
                    ]
                }
            ],
            "severities": [
                976,
                {
                    "distinctOn": [
                        985,
                        "[SeveritiesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        983,
                        "[SeveritiesOrderBy!]"
                    ],
                    "where": [
                        977
                    ]
                }
            ],
            "severitiesByPk": [
                976,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "statuses": [
                991,
                {
                    "distinctOn": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1009,
                        "[StatusesOrderBy!]"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "statusesAggregate": [
                992,
                {
                    "distinctOn": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1009,
                        "[StatusesOrderBy!]"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "statusesByPk": [
                991,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "templates": [
                1035,
                {
                    "distinctOn": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1053,
                        "[TemplatesOrderBy!]"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "templatesAggregate": [
                1036,
                {
                    "distinctOn": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1053,
                        "[TemplatesOrderBy!]"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "templatesByPk": [
                1035,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "translations": [
                1078,
                {
                    "distinctOn": [
                        1092,
                        "[TranslationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1090,
                        "[TranslationsOrderBy!]"
                    ],
                    "where": [
                        1082
                    ]
                }
            ],
            "translationsByPk": [
                1078,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "upload": [
                1106,
                {
                    "items": [
                        1105,
                        "[UpInput!]!"
                    ]
                }
            ],
            "uploads": [
                1108,
                {
                    "distinctOn": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1126,
                        "[UploadsOrderBy!]"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "uploadsAggregate": [
                1109,
                {
                    "distinctOn": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1126,
                        "[UploadsOrderBy!]"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "uploadsByPk": [
                1108,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "users": [
                1150,
                {
                    "distinctOn": [
                        1158,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1157,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        1154
                    ]
                }
            ],
            "usersAggregate": [
                1151,
                {
                    "distinctOn": [
                        1158,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1157,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        1154
                    ]
                }
            ],
            "usersByPk": [
                1150,
                {
                    "id": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "__typename": [
                1033
            ]
        },
        "Mutation": {
            "insertAssetTypes": [
                15,
                {
                    "objects": [
                        10,
                        "[AssetTypesInsertInput!]!"
                    ],
                    "onConflict": [
                        17
                    ]
                }
            ],
            "insertAssetTypesOne": [
                0,
                {
                    "object": [
                        10,
                        "AssetTypesInsertInput!"
                    ],
                    "onConflict": [
                        17
                    ]
                }
            ],
            "insertAssets": [
                62,
                {
                    "objects": [
                        57,
                        "[AssetsInsertInput!]!"
                    ],
                    "onConflict": [
                        64
                    ]
                }
            ],
            "insertAssetsOne": [
                42,
                {
                    "object": [
                        57,
                        "AssetsInsertInput!"
                    ],
                    "onConflict": [
                        64
                    ]
                }
            ],
            "insertAuditFindingPhotos": [
                100,
                {
                    "objects": [
                        97,
                        "[AuditFindingPhotosInsertInput!]!"
                    ],
                    "onConflict": [
                        101
                    ]
                }
            ],
            "insertAuditFindingPhotosOne": [
                90,
                {
                    "object": [
                        97,
                        "AuditFindingPhotosInsertInput!"
                    ],
                    "onConflict": [
                        101
                    ]
                }
            ],
            "insertAuditFindings": [
                127,
                {
                    "objects": [
                        124,
                        "[AuditFindingsInsertInput!]!"
                    ],
                    "onConflict": [
                        128
                    ]
                }
            ],
            "insertAuditFindingsOne": [
                117,
                {
                    "object": [
                        124,
                        "AuditFindingsInsertInput!"
                    ],
                    "onConflict": [
                        128
                    ]
                }
            ],
            "insertAuditItems": [
                154,
                {
                    "objects": [
                        151,
                        "[AuditItemsInsertInput!]!"
                    ],
                    "onConflict": [
                        155
                    ]
                }
            ],
            "insertAuditItemsOne": [
                144,
                {
                    "object": [
                        151,
                        "AuditItemsInsertInput!"
                    ],
                    "onConflict": [
                        155
                    ]
                }
            ],
            "insertAudits": [
                181,
                {
                    "objects": [
                        178,
                        "[AuditsInsertInput!]!"
                    ],
                    "onConflict": [
                        182
                    ]
                }
            ],
            "insertAuditsOne": [
                171,
                {
                    "object": [
                        178,
                        "AuditsInsertInput!"
                    ],
                    "onConflict": [
                        182
                    ]
                }
            ],
            "insertCompanySettings": [
                234,
                {
                    "objects": [
                        229,
                        "[CompanySettingsInsertInput!]!"
                    ],
                    "onConflict": [
                        235
                    ]
                }
            ],
            "insertCompanySettingsOne": [
                218,
                {
                    "object": [
                        229,
                        "CompanySettingsInsertInput!"
                    ],
                    "onConflict": [
                        235
                    ]
                }
            ],
            "insertControlPointTemplates": [
                272,
                {
                    "objects": [
                        269,
                        "[ControlPointTemplatesInsertInput!]!"
                    ],
                    "onConflict": [
                        273
                    ]
                }
            ],
            "insertControlPointTemplatesOne": [
                258,
                {
                    "object": [
                        269,
                        "ControlPointTemplatesInsertInput!"
                    ],
                    "onConflict": [
                        273
                    ]
                }
            ],
            "insertControlPoints": [
                310,
                {
                    "objects": [
                        305,
                        "[ControlPointsInsertInput!]!"
                    ],
                    "onConflict": [
                        311
                    ]
                }
            ],
            "insertControlPointsOne": [
                290,
                {
                    "object": [
                        305,
                        "ControlPointsInsertInput!"
                    ],
                    "onConflict": [
                        311
                    ]
                }
            ],
            "insertCustomFields": [
                357,
                {
                    "objects": [
                        352,
                        "[CustomFieldsInsertInput!]!"
                    ],
                    "onConflict": [
                        358
                    ]
                }
            ],
            "insertCustomFieldsOne": [
                342,
                {
                    "object": [
                        352,
                        "CustomFieldsInsertInput!"
                    ],
                    "onConflict": [
                        358
                    ]
                }
            ],
            "insertEnergyMeasures": [
                399,
                {
                    "objects": [
                        394,
                        "[EnergyMeasuresInsertInput!]!"
                    ],
                    "onConflict": [
                        401
                    ]
                }
            ],
            "insertEnergyMeasuresOne": [
                383,
                {
                    "object": [
                        394,
                        "EnergyMeasuresInsertInput!"
                    ],
                    "onConflict": [
                        401
                    ]
                }
            ],
            "insertEnergySources": [
                442,
                {
                    "objects": [
                        437,
                        "[EnergySourcesInsertInput!]!"
                    ],
                    "onConflict": [
                        444
                    ]
                }
            ],
            "insertEnergySourcesOne": [
                426,
                {
                    "object": [
                        437,
                        "EnergySourcesInsertInput!"
                    ],
                    "onConflict": [
                        444
                    ]
                }
            ],
            "insertHazards": [
                486,
                {
                    "objects": [
                        481,
                        "[HazardsInsertInput!]!"
                    ],
                    "onConflict": [
                        488
                    ]
                }
            ],
            "insertHazardsOne": [
                470,
                {
                    "object": [
                        481,
                        "HazardsInsertInput!"
                    ],
                    "onConflict": [
                        488
                    ]
                }
            ],
            "insertInspectionFindingPhotos": [
                523,
                {
                    "objects": [
                        520,
                        "[InspectionFindingPhotosInsertInput!]!"
                    ],
                    "onConflict": [
                        524
                    ]
                }
            ],
            "insertInspectionFindingPhotosOne": [
                513,
                {
                    "object": [
                        520,
                        "InspectionFindingPhotosInsertInput!"
                    ],
                    "onConflict": [
                        524
                    ]
                }
            ],
            "insertInspectionFindings": [
                550,
                {
                    "objects": [
                        547,
                        "[InspectionFindingsInsertInput!]!"
                    ],
                    "onConflict": [
                        551
                    ]
                }
            ],
            "insertInspectionFindingsOne": [
                540,
                {
                    "object": [
                        547,
                        "InspectionFindingsInsertInput!"
                    ],
                    "onConflict": [
                        551
                    ]
                }
            ],
            "insertInspectionItemPhotos": [
                577,
                {
                    "objects": [
                        574,
                        "[InspectionItemPhotosInsertInput!]!"
                    ],
                    "onConflict": [
                        578
                    ]
                }
            ],
            "insertInspectionItemPhotosOne": [
                567,
                {
                    "object": [
                        574,
                        "InspectionItemPhotosInsertInput!"
                    ],
                    "onConflict": [
                        578
                    ]
                }
            ],
            "insertInspectionItems": [
                604,
                {
                    "objects": [
                        601,
                        "[InspectionItemsInsertInput!]!"
                    ],
                    "onConflict": [
                        605
                    ]
                }
            ],
            "insertInspectionItemsOne": [
                594,
                {
                    "object": [
                        601,
                        "InspectionItemsInsertInput!"
                    ],
                    "onConflict": [
                        605
                    ]
                }
            ],
            "insertInspectionReasons": [
                631,
                {
                    "objects": [
                        628,
                        "[InspectionReasonsInsertInput!]!"
                    ],
                    "onConflict": [
                        632
                    ]
                }
            ],
            "insertInspectionReasonsOne": [
                621,
                {
                    "object": [
                        628,
                        "InspectionReasonsInsertInput!"
                    ],
                    "onConflict": [
                        632
                    ]
                }
            ],
            "insertInspections": [
                658,
                {
                    "objects": [
                        655,
                        "[InspectionsInsertInput!]!"
                    ],
                    "onConflict": [
                        659
                    ]
                }
            ],
            "insertInspectionsOne": [
                648,
                {
                    "object": [
                        655,
                        "InspectionsInsertInput!"
                    ],
                    "onConflict": [
                        659
                    ]
                }
            ],
            "insertIsolationDevices": [
                693,
                {
                    "objects": [
                        688,
                        "[IsolationDevicesInsertInput!]!"
                    ],
                    "onConflict": [
                        695
                    ]
                }
            ],
            "insertIsolationDevicesOne": [
                677,
                {
                    "object": [
                        688,
                        "IsolationDevicesInsertInput!"
                    ],
                    "onConflict": [
                        695
                    ]
                }
            ],
            "insertLanguages": [
                727,
                {
                    "objects": [
                        726,
                        "[LanguagesInsertInput!]!"
                    ],
                    "onConflict": [
                        729
                    ]
                }
            ],
            "insertLanguagesOne": [
                722,
                {
                    "object": [
                        726,
                        "LanguagesInsertInput!"
                    ],
                    "onConflict": [
                        729
                    ]
                }
            ],
            "insertLocations": [
                757,
                {
                    "objects": [
                        752,
                        "[LocationsInsertInput!]!"
                    ],
                    "onConflict": [
                        759
                    ]
                }
            ],
            "insertLocationsOne": [
                738,
                {
                    "object": [
                        752,
                        "LocationsInsertInput!"
                    ],
                    "onConflict": [
                        759
                    ]
                }
            ],
            "insertProcedureHazards": [
                820,
                {
                    "objects": [
                        815,
                        "[ProcedureHazardsInsertInput!]!"
                    ],
                    "onConflict": [
                        821
                    ]
                }
            ],
            "insertProcedureHazardsOne": [
                804,
                {
                    "object": [
                        815,
                        "ProcedureHazardsInsertInput!"
                    ],
                    "onConflict": [
                        821
                    ]
                }
            ],
            "insertProcedureTemplates": [
                860,
                {
                    "objects": [
                        857,
                        "[ProcedureTemplatesInsertInput!]!"
                    ],
                    "onConflict": [
                        861
                    ]
                }
            ],
            "insertProcedureTemplatesOne": [
                846,
                {
                    "object": [
                        857,
                        "ProcedureTemplatesInsertInput!"
                    ],
                    "onConflict": [
                        861
                    ]
                }
            ],
            "insertProcedureTypes": [
                893,
                {
                    "objects": [
                        888,
                        "[ProcedureTypesInsertInput!]!"
                    ],
                    "onConflict": [
                        895
                    ]
                }
            ],
            "insertProcedureTypesOne": [
                878,
                {
                    "object": [
                        888,
                        "ProcedureTypesInsertInput!"
                    ],
                    "onConflict": [
                        895
                    ]
                }
            ],
            "insertProcedures": [
                940,
                {
                    "objects": [
                        935,
                        "[ProceduresInsertInput!]!"
                    ],
                    "onConflict": [
                        942
                    ]
                }
            ],
            "insertProceduresOne": [
                920,
                {
                    "object": [
                        935,
                        "ProceduresInsertInput!"
                    ],
                    "onConflict": [
                        942
                    ]
                }
            ],
            "insertSeverities": [
                981,
                {
                    "objects": [
                        980,
                        "[SeveritiesInsertInput!]!"
                    ],
                    "onConflict": [
                        982
                    ]
                }
            ],
            "insertSeveritiesOne": [
                976,
                {
                    "object": [
                        980,
                        "SeveritiesInsertInput!"
                    ],
                    "onConflict": [
                        982
                    ]
                }
            ],
            "insertStatuses": [
                1006,
                {
                    "objects": [
                        1001,
                        "[StatusesInsertInput!]!"
                    ],
                    "onConflict": [
                        1008
                    ]
                }
            ],
            "insertStatusesOne": [
                991,
                {
                    "object": [
                        1001,
                        "StatusesInsertInput!"
                    ],
                    "onConflict": [
                        1008
                    ]
                }
            ],
            "insertTemplates": [
                1050,
                {
                    "objects": [
                        1045,
                        "[TemplatesInsertInput!]!"
                    ],
                    "onConflict": [
                        1052
                    ]
                }
            ],
            "insertTemplatesOne": [
                1035,
                {
                    "object": [
                        1045,
                        "TemplatesInsertInput!"
                    ],
                    "onConflict": [
                        1052
                    ]
                }
            ],
            "insertTranslations": [
                1088,
                {
                    "objects": [
                        1085,
                        "[TranslationsInsertInput!]!"
                    ],
                    "onConflict": [
                        1089
                    ]
                }
            ],
            "insertTranslationsOne": [
                1078,
                {
                    "object": [
                        1085,
                        "TranslationsInsertInput!"
                    ],
                    "onConflict": [
                        1089
                    ]
                }
            ],
            "insertUploads": [
                1123,
                {
                    "objects": [
                        1118,
                        "[UploadsInsertInput!]!"
                    ],
                    "onConflict": [
                        1125
                    ]
                }
            ],
            "insertUploadsOne": [
                1108,
                {
                    "object": [
                        1118,
                        "UploadsInsertInput!"
                    ],
                    "onConflict": [
                        1125
                    ]
                }
            ],
            "updateAssetTypes": [
                15,
                {
                    "_inc": [
                        9
                    ],
                    "_set": [
                        23
                    ],
                    "where": [
                        7,
                        "AssetTypesBoolExp!"
                    ]
                }
            ],
            "updateAssetTypesByPk": [
                0,
                {
                    "_inc": [
                        9
                    ],
                    "_set": [
                        23
                    ],
                    "pkColumns": [
                        19,
                        "AssetTypesPkColumnsInput!"
                    ]
                }
            ],
            "updateAssetTypesMany": [
                15,
                {
                    "updates": [
                        35,
                        "[AssetTypesUpdates!]!"
                    ]
                }
            ],
            "updateAssets": [
                62,
                {
                    "_append": [
                        47
                    ],
                    "_deleteAtPath": [
                        53
                    ],
                    "_deleteElem": [
                        54
                    ],
                    "_deleteKey": [
                        55
                    ],
                    "_inc": [
                        56
                    ],
                    "_prepend": [
                        67
                    ],
                    "_set": [
                        71
                    ],
                    "where": [
                        51,
                        "AssetsBoolExp!"
                    ]
                }
            ],
            "updateAssetsByPk": [
                42,
                {
                    "_append": [
                        47
                    ],
                    "_deleteAtPath": [
                        53
                    ],
                    "_deleteElem": [
                        54
                    ],
                    "_deleteKey": [
                        55
                    ],
                    "_inc": [
                        56
                    ],
                    "_prepend": [
                        67
                    ],
                    "_set": [
                        71
                    ],
                    "pkColumns": [
                        66,
                        "AssetsPkColumnsInput!"
                    ]
                }
            ],
            "updateAssetsMany": [
                62,
                {
                    "updates": [
                        83,
                        "[AssetsUpdates!]!"
                    ]
                }
            ],
            "updateAuditFindingPhotos": [
                100,
                {
                    "_inc": [
                        96
                    ],
                    "_set": [
                        105
                    ],
                    "where": [
                        94,
                        "AuditFindingPhotosBoolExp!"
                    ]
                }
            ],
            "updateAuditFindingPhotosByPk": [
                90,
                {
                    "_inc": [
                        96
                    ],
                    "_set": [
                        105
                    ],
                    "pkColumns": [
                        103,
                        "AuditFindingPhotosPkColumnsInput!"
                    ]
                }
            ],
            "updateAuditFindingPhotosMany": [
                100,
                {
                    "updates": [
                        113,
                        "[AuditFindingPhotosUpdates!]!"
                    ]
                }
            ],
            "updateAuditFindings": [
                127,
                {
                    "_inc": [
                        123
                    ],
                    "_set": [
                        132
                    ],
                    "where": [
                        121,
                        "AuditFindingsBoolExp!"
                    ]
                }
            ],
            "updateAuditFindingsByPk": [
                117,
                {
                    "_inc": [
                        123
                    ],
                    "_set": [
                        132
                    ],
                    "pkColumns": [
                        130,
                        "AuditFindingsPkColumnsInput!"
                    ]
                }
            ],
            "updateAuditFindingsMany": [
                127,
                {
                    "updates": [
                        140,
                        "[AuditFindingsUpdates!]!"
                    ]
                }
            ],
            "updateAuditItems": [
                154,
                {
                    "_inc": [
                        150
                    ],
                    "_set": [
                        159
                    ],
                    "where": [
                        148,
                        "AuditItemsBoolExp!"
                    ]
                }
            ],
            "updateAuditItemsByPk": [
                144,
                {
                    "_inc": [
                        150
                    ],
                    "_set": [
                        159
                    ],
                    "pkColumns": [
                        157,
                        "AuditItemsPkColumnsInput!"
                    ]
                }
            ],
            "updateAuditItemsMany": [
                154,
                {
                    "updates": [
                        167,
                        "[AuditItemsUpdates!]!"
                    ]
                }
            ],
            "updateAudits": [
                181,
                {
                    "_inc": [
                        177
                    ],
                    "_set": [
                        186
                    ],
                    "where": [
                        175,
                        "AuditsBoolExp!"
                    ]
                }
            ],
            "updateAuditsByPk": [
                171,
                {
                    "_inc": [
                        177
                    ],
                    "_set": [
                        186
                    ],
                    "pkColumns": [
                        184,
                        "AuditsPkColumnsInput!"
                    ]
                }
            ],
            "updateAuditsMany": [
                181,
                {
                    "updates": [
                        194,
                        "[AuditsUpdates!]!"
                    ]
                }
            ],
            "updateCompanySettings": [
                234,
                {
                    "_inc": [
                        228
                    ],
                    "_set": [
                        239
                    ],
                    "where": [
                        226,
                        "CompanySettingsBoolExp!"
                    ]
                }
            ],
            "updateCompanySettingsByPk": [
                218,
                {
                    "_inc": [
                        228
                    ],
                    "_set": [
                        239
                    ],
                    "pkColumns": [
                        237,
                        "CompanySettingsPkColumnsInput!"
                    ]
                }
            ],
            "updateCompanySettingsMany": [
                234,
                {
                    "updates": [
                        251,
                        "[CompanySettingsUpdates!]!"
                    ]
                }
            ],
            "updateControlPointTemplates": [
                272,
                {
                    "_append": [
                        261
                    ],
                    "_deleteAtPath": [
                        265
                    ],
                    "_deleteElem": [
                        266
                    ],
                    "_deleteKey": [
                        267
                    ],
                    "_inc": [
                        268
                    ],
                    "_prepend": [
                        276
                    ],
                    "_set": [
                        278
                    ],
                    "where": [
                        263,
                        "ControlPointTemplatesBoolExp!"
                    ]
                }
            ],
            "updateControlPointTemplatesByPk": [
                258,
                {
                    "_append": [
                        261
                    ],
                    "_deleteAtPath": [
                        265
                    ],
                    "_deleteElem": [
                        266
                    ],
                    "_deleteKey": [
                        267
                    ],
                    "_inc": [
                        268
                    ],
                    "_prepend": [
                        276
                    ],
                    "_set": [
                        278
                    ],
                    "pkColumns": [
                        275,
                        "ControlPointTemplatesPkColumnsInput!"
                    ]
                }
            ],
            "updateControlPointTemplatesMany": [
                272,
                {
                    "updates": [
                        286,
                        "[ControlPointTemplatesUpdates!]!"
                    ]
                }
            ],
            "updateControlPoints": [
                310,
                {
                    "_append": [
                        295
                    ],
                    "_deleteAtPath": [
                        301
                    ],
                    "_deleteElem": [
                        302
                    ],
                    "_deleteKey": [
                        303
                    ],
                    "_inc": [
                        304
                    ],
                    "_prepend": [
                        314
                    ],
                    "_set": [
                        318
                    ],
                    "where": [
                        299,
                        "ControlPointsBoolExp!"
                    ]
                }
            ],
            "updateControlPointsByPk": [
                290,
                {
                    "_append": [
                        295
                    ],
                    "_deleteAtPath": [
                        301
                    ],
                    "_deleteElem": [
                        302
                    ],
                    "_deleteKey": [
                        303
                    ],
                    "_inc": [
                        304
                    ],
                    "_prepend": [
                        314
                    ],
                    "_set": [
                        318
                    ],
                    "pkColumns": [
                        313,
                        "ControlPointsPkColumnsInput!"
                    ]
                }
            ],
            "updateControlPointsMany": [
                310,
                {
                    "updates": [
                        330,
                        "[ControlPointsUpdates!]!"
                    ]
                }
            ],
            "updateCustomFields": [
                357,
                {
                    "_inc": [
                        351
                    ],
                    "_set": [
                        364
                    ],
                    "where": [
                        349,
                        "CustomFieldsBoolExp!"
                    ]
                }
            ],
            "updateCustomFieldsByPk": [
                342,
                {
                    "_inc": [
                        351
                    ],
                    "_set": [
                        364
                    ],
                    "pkColumns": [
                        360,
                        "CustomFieldsPkColumnsInput!"
                    ]
                }
            ],
            "updateCustomFieldsMany": [
                357,
                {
                    "updates": [
                        376,
                        "[CustomFieldsUpdates!]!"
                    ]
                }
            ],
            "updateEnergyMeasures": [
                399,
                {
                    "_inc": [
                        393
                    ],
                    "_set": [
                        407
                    ],
                    "where": [
                        391,
                        "EnergyMeasuresBoolExp!"
                    ]
                }
            ],
            "updateEnergyMeasuresByPk": [
                383,
                {
                    "_inc": [
                        393
                    ],
                    "_set": [
                        407
                    ],
                    "pkColumns": [
                        403,
                        "EnergyMeasuresPkColumnsInput!"
                    ]
                }
            ],
            "updateEnergyMeasuresMany": [
                399,
                {
                    "updates": [
                        419,
                        "[EnergyMeasuresUpdates!]!"
                    ]
                }
            ],
            "updateEnergySources": [
                442,
                {
                    "_inc": [
                        436
                    ],
                    "_set": [
                        450
                    ],
                    "where": [
                        434,
                        "EnergySourcesBoolExp!"
                    ]
                }
            ],
            "updateEnergySourcesByPk": [
                426,
                {
                    "_inc": [
                        436
                    ],
                    "_set": [
                        450
                    ],
                    "pkColumns": [
                        446,
                        "EnergySourcesPkColumnsInput!"
                    ]
                }
            ],
            "updateEnergySourcesMany": [
                442,
                {
                    "updates": [
                        462,
                        "[EnergySourcesUpdates!]!"
                    ]
                }
            ],
            "updateHazards": [
                486,
                {
                    "_inc": [
                        480
                    ],
                    "_set": [
                        494
                    ],
                    "where": [
                        478,
                        "HazardsBoolExp!"
                    ]
                }
            ],
            "updateHazardsByPk": [
                470,
                {
                    "_inc": [
                        480
                    ],
                    "_set": [
                        494
                    ],
                    "pkColumns": [
                        490,
                        "HazardsPkColumnsInput!"
                    ]
                }
            ],
            "updateHazardsMany": [
                486,
                {
                    "updates": [
                        506,
                        "[HazardsUpdates!]!"
                    ]
                }
            ],
            "updateInspectionFindingPhotos": [
                523,
                {
                    "_inc": [
                        519
                    ],
                    "_set": [
                        528
                    ],
                    "where": [
                        517,
                        "InspectionFindingPhotosBoolExp!"
                    ]
                }
            ],
            "updateInspectionFindingPhotosByPk": [
                513,
                {
                    "_inc": [
                        519
                    ],
                    "_set": [
                        528
                    ],
                    "pkColumns": [
                        526,
                        "InspectionFindingPhotosPkColumnsInput!"
                    ]
                }
            ],
            "updateInspectionFindingPhotosMany": [
                523,
                {
                    "updates": [
                        536,
                        "[InspectionFindingPhotosUpdates!]!"
                    ]
                }
            ],
            "updateInspectionFindings": [
                550,
                {
                    "_inc": [
                        546
                    ],
                    "_set": [
                        555
                    ],
                    "where": [
                        544,
                        "InspectionFindingsBoolExp!"
                    ]
                }
            ],
            "updateInspectionFindingsByPk": [
                540,
                {
                    "_inc": [
                        546
                    ],
                    "_set": [
                        555
                    ],
                    "pkColumns": [
                        553,
                        "InspectionFindingsPkColumnsInput!"
                    ]
                }
            ],
            "updateInspectionFindingsMany": [
                550,
                {
                    "updates": [
                        563,
                        "[InspectionFindingsUpdates!]!"
                    ]
                }
            ],
            "updateInspectionItemPhotos": [
                577,
                {
                    "_inc": [
                        573
                    ],
                    "_set": [
                        582
                    ],
                    "where": [
                        571,
                        "InspectionItemPhotosBoolExp!"
                    ]
                }
            ],
            "updateInspectionItemPhotosByPk": [
                567,
                {
                    "_inc": [
                        573
                    ],
                    "_set": [
                        582
                    ],
                    "pkColumns": [
                        580,
                        "InspectionItemPhotosPkColumnsInput!"
                    ]
                }
            ],
            "updateInspectionItemPhotosMany": [
                577,
                {
                    "updates": [
                        590,
                        "[InspectionItemPhotosUpdates!]!"
                    ]
                }
            ],
            "updateInspectionItems": [
                604,
                {
                    "_inc": [
                        600
                    ],
                    "_set": [
                        609
                    ],
                    "where": [
                        598,
                        "InspectionItemsBoolExp!"
                    ]
                }
            ],
            "updateInspectionItemsByPk": [
                594,
                {
                    "_inc": [
                        600
                    ],
                    "_set": [
                        609
                    ],
                    "pkColumns": [
                        607,
                        "InspectionItemsPkColumnsInput!"
                    ]
                }
            ],
            "updateInspectionItemsMany": [
                604,
                {
                    "updates": [
                        617,
                        "[InspectionItemsUpdates!]!"
                    ]
                }
            ],
            "updateInspectionReasons": [
                631,
                {
                    "_inc": [
                        627
                    ],
                    "_set": [
                        636
                    ],
                    "where": [
                        625,
                        "InspectionReasonsBoolExp!"
                    ]
                }
            ],
            "updateInspectionReasonsByPk": [
                621,
                {
                    "_inc": [
                        627
                    ],
                    "_set": [
                        636
                    ],
                    "pkColumns": [
                        634,
                        "InspectionReasonsPkColumnsInput!"
                    ]
                }
            ],
            "updateInspectionReasonsMany": [
                631,
                {
                    "updates": [
                        644,
                        "[InspectionReasonsUpdates!]!"
                    ]
                }
            ],
            "updateInspections": [
                658,
                {
                    "_inc": [
                        654
                    ],
                    "_set": [
                        663
                    ],
                    "where": [
                        652,
                        "InspectionsBoolExp!"
                    ]
                }
            ],
            "updateInspectionsByPk": [
                648,
                {
                    "_inc": [
                        654
                    ],
                    "_set": [
                        663
                    ],
                    "pkColumns": [
                        661,
                        "InspectionsPkColumnsInput!"
                    ]
                }
            ],
            "updateInspectionsMany": [
                658,
                {
                    "updates": [
                        671,
                        "[InspectionsUpdates!]!"
                    ]
                }
            ],
            "updateIsolationDevices": [
                693,
                {
                    "_inc": [
                        687
                    ],
                    "_set": [
                        701
                    ],
                    "where": [
                        685,
                        "IsolationDevicesBoolExp!"
                    ]
                }
            ],
            "updateIsolationDevicesByPk": [
                677,
                {
                    "_inc": [
                        687
                    ],
                    "_set": [
                        701
                    ],
                    "pkColumns": [
                        697,
                        "IsolationDevicesPkColumnsInput!"
                    ]
                }
            ],
            "updateIsolationDevicesMany": [
                693,
                {
                    "updates": [
                        713,
                        "[IsolationDevicesUpdates!]!"
                    ]
                }
            ],
            "updateLanguages": [
                727,
                {
                    "_inc": [
                        725
                    ],
                    "_set": [
                        733
                    ],
                    "where": [
                        723,
                        "LanguagesBoolExp!"
                    ]
                }
            ],
            "updateLanguagesByPk": [
                722,
                {
                    "_inc": [
                        725
                    ],
                    "_set": [
                        733
                    ],
                    "pkColumns": [
                        731,
                        "LanguagesPkColumnsInput!"
                    ]
                }
            ],
            "updateLanguagesMany": [
                727,
                {
                    "updates": [
                        737,
                        "[LanguagesUpdates!]!"
                    ]
                }
            ],
            "updateLocations": [
                757,
                {
                    "_append": [
                        743
                    ],
                    "_deleteAtPath": [
                        748
                    ],
                    "_deleteElem": [
                        749
                    ],
                    "_deleteKey": [
                        750
                    ],
                    "_inc": [
                        751
                    ],
                    "_prepend": [
                        762
                    ],
                    "_set": [
                        766
                    ],
                    "where": [
                        746,
                        "LocationsBoolExp!"
                    ]
                }
            ],
            "updateLocationsByPk": [
                738,
                {
                    "_append": [
                        743
                    ],
                    "_deleteAtPath": [
                        748
                    ],
                    "_deleteElem": [
                        749
                    ],
                    "_deleteKey": [
                        750
                    ],
                    "_inc": [
                        751
                    ],
                    "_prepend": [
                        762
                    ],
                    "_set": [
                        766
                    ],
                    "pkColumns": [
                        761,
                        "LocationsPkColumnsInput!"
                    ]
                }
            ],
            "updateLocationsMany": [
                757,
                {
                    "updates": [
                        778,
                        "[LocationsUpdates!]!"
                    ]
                }
            ],
            "updateProcedureHazards": [
                820,
                {
                    "_inc": [
                        814
                    ],
                    "_set": [
                        827
                    ],
                    "where": [
                        812,
                        "ProcedureHazardsBoolExp!"
                    ]
                }
            ],
            "updateProcedureHazardsByPk": [
                804,
                {
                    "_inc": [
                        814
                    ],
                    "_set": [
                        827
                    ],
                    "pkColumns": [
                        823,
                        "ProcedureHazardsPkColumnsInput!"
                    ]
                }
            ],
            "updateProcedureHazardsMany": [
                820,
                {
                    "updates": [
                        839,
                        "[ProcedureHazardsUpdates!]!"
                    ]
                }
            ],
            "updateProcedureTemplates": [
                860,
                {
                    "_append": [
                        849
                    ],
                    "_deleteAtPath": [
                        853
                    ],
                    "_deleteElem": [
                        854
                    ],
                    "_deleteKey": [
                        855
                    ],
                    "_inc": [
                        856
                    ],
                    "_prepend": [
                        864
                    ],
                    "_set": [
                        866
                    ],
                    "where": [
                        851,
                        "ProcedureTemplatesBoolExp!"
                    ]
                }
            ],
            "updateProcedureTemplatesByPk": [
                846,
                {
                    "_append": [
                        849
                    ],
                    "_deleteAtPath": [
                        853
                    ],
                    "_deleteElem": [
                        854
                    ],
                    "_deleteKey": [
                        855
                    ],
                    "_inc": [
                        856
                    ],
                    "_prepend": [
                        864
                    ],
                    "_set": [
                        866
                    ],
                    "pkColumns": [
                        863,
                        "ProcedureTemplatesPkColumnsInput!"
                    ]
                }
            ],
            "updateProcedureTemplatesMany": [
                860,
                {
                    "updates": [
                        874,
                        "[ProcedureTemplatesUpdates!]!"
                    ]
                }
            ],
            "updateProcedureTypes": [
                893,
                {
                    "_inc": [
                        887
                    ],
                    "_set": [
                        901
                    ],
                    "where": [
                        885,
                        "ProcedureTypesBoolExp!"
                    ]
                }
            ],
            "updateProcedureTypesByPk": [
                878,
                {
                    "_inc": [
                        887
                    ],
                    "_set": [
                        901
                    ],
                    "pkColumns": [
                        897,
                        "ProcedureTypesPkColumnsInput!"
                    ]
                }
            ],
            "updateProcedureTypesMany": [
                893,
                {
                    "updates": [
                        913,
                        "[ProcedureTypesUpdates!]!"
                    ]
                }
            ],
            "updateProcedures": [
                940,
                {
                    "_append": [
                        925
                    ],
                    "_deleteAtPath": [
                        931
                    ],
                    "_deleteElem": [
                        932
                    ],
                    "_deleteKey": [
                        933
                    ],
                    "_inc": [
                        934
                    ],
                    "_prepend": [
                        945
                    ],
                    "_set": [
                        949
                    ],
                    "where": [
                        929,
                        "ProceduresBoolExp!"
                    ]
                }
            ],
            "updateProceduresByPk": [
                920,
                {
                    "_append": [
                        925
                    ],
                    "_deleteAtPath": [
                        931
                    ],
                    "_deleteElem": [
                        932
                    ],
                    "_deleteKey": [
                        933
                    ],
                    "_inc": [
                        934
                    ],
                    "_prepend": [
                        945
                    ],
                    "_set": [
                        949
                    ],
                    "pkColumns": [
                        944,
                        "ProceduresPkColumnsInput!"
                    ]
                }
            ],
            "updateProceduresMany": [
                940,
                {
                    "updates": [
                        961,
                        "[ProceduresUpdates!]!"
                    ]
                }
            ],
            "updateSeverities": [
                981,
                {
                    "_inc": [
                        979
                    ],
                    "_set": [
                        986
                    ],
                    "where": [
                        977,
                        "SeveritiesBoolExp!"
                    ]
                }
            ],
            "updateSeveritiesByPk": [
                976,
                {
                    "_inc": [
                        979
                    ],
                    "_set": [
                        986
                    ],
                    "pkColumns": [
                        984,
                        "SeveritiesPkColumnsInput!"
                    ]
                }
            ],
            "updateSeveritiesMany": [
                981,
                {
                    "updates": [
                        990,
                        "[SeveritiesUpdates!]!"
                    ]
                }
            ],
            "updateStatuses": [
                1006,
                {
                    "_inc": [
                        1000
                    ],
                    "_set": [
                        1014
                    ],
                    "where": [
                        998,
                        "StatusesBoolExp!"
                    ]
                }
            ],
            "updateStatusesByPk": [
                991,
                {
                    "_inc": [
                        1000
                    ],
                    "_set": [
                        1014
                    ],
                    "pkColumns": [
                        1010,
                        "StatusesPkColumnsInput!"
                    ]
                }
            ],
            "updateStatusesMany": [
                1006,
                {
                    "updates": [
                        1026,
                        "[StatusesUpdates!]!"
                    ]
                }
            ],
            "updateTemplates": [
                1050,
                {
                    "_inc": [
                        1044
                    ],
                    "_set": [
                        1058
                    ],
                    "where": [
                        1042,
                        "TemplatesBoolExp!"
                    ]
                }
            ],
            "updateTemplatesByPk": [
                1035,
                {
                    "_inc": [
                        1044
                    ],
                    "_set": [
                        1058
                    ],
                    "pkColumns": [
                        1054,
                        "TemplatesPkColumnsInput!"
                    ]
                }
            ],
            "updateTemplatesMany": [
                1050,
                {
                    "updates": [
                        1070,
                        "[TemplatesUpdates!]!"
                    ]
                }
            ],
            "updateTranslations": [
                1088,
                {
                    "_inc": [
                        1084
                    ],
                    "_set": [
                        1093
                    ],
                    "where": [
                        1082,
                        "TranslationsBoolExp!"
                    ]
                }
            ],
            "updateTranslationsByPk": [
                1078,
                {
                    "_inc": [
                        1084
                    ],
                    "_set": [
                        1093
                    ],
                    "pkColumns": [
                        1091,
                        "TranslationsPkColumnsInput!"
                    ]
                }
            ],
            "updateTranslationsMany": [
                1088,
                {
                    "updates": [
                        1101,
                        "[TranslationsUpdates!]!"
                    ]
                }
            ],
            "updateUploads": [
                1123,
                {
                    "_inc": [
                        1117
                    ],
                    "_set": [
                        1131
                    ],
                    "where": [
                        1115,
                        "UploadsBoolExp!"
                    ]
                }
            ],
            "updateUploadsByPk": [
                1108,
                {
                    "_inc": [
                        1117
                    ],
                    "_set": [
                        1131
                    ],
                    "pkColumns": [
                        1127,
                        "UploadsPkColumnsInput!"
                    ]
                }
            ],
            "updateUploadsMany": [
                1123,
                {
                    "updates": [
                        1143,
                        "[UploadsUpdates!]!"
                    ]
                }
            ],
            "__typename": [
                1033
            ]
        },
        "Subscription": {
            "assetTypes": [
                0,
                {
                    "distinctOn": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        18,
                        "[AssetTypesOrderBy!]"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assetTypesAggregate": [
                1,
                {
                    "distinctOn": [
                        20,
                        "[AssetTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        18,
                        "[AssetTypesOrderBy!]"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assetTypesByPk": [
                0,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "assetTypesStream": [
                0,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        30,
                        "[AssetTypesStreamCursorInput]!"
                    ],
                    "where": [
                        7
                    ]
                }
            ],
            "assets": [
                42,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsAggregate": [
                43,
                {
                    "distinctOn": [
                        68,
                        "[AssetsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        65,
                        "[AssetsOrderBy!]"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "assetsByPk": [
                42,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "assetsStream": [
                42,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        78,
                        "[AssetsStreamCursorInput]!"
                    ],
                    "where": [
                        51
                    ]
                }
            ],
            "auditFindingPhotos": [
                90,
                {
                    "distinctOn": [
                        104,
                        "[AuditFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        102,
                        "[AuditFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        94
                    ]
                }
            ],
            "auditFindingPhotosAggregate": [
                91,
                {
                    "distinctOn": [
                        104,
                        "[AuditFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        102,
                        "[AuditFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        94
                    ]
                }
            ],
            "auditFindingPhotosByPk": [
                90,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "findingId": [
                        1218,
                        "uuid!"
                    ],
                    "photoId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditFindingPhotosStream": [
                90,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        109,
                        "[AuditFindingPhotosStreamCursorInput]!"
                    ],
                    "where": [
                        94
                    ]
                }
            ],
            "auditFindings": [
                117,
                {
                    "distinctOn": [
                        131,
                        "[AuditFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        129,
                        "[AuditFindingsOrderBy!]"
                    ],
                    "where": [
                        121
                    ]
                }
            ],
            "auditFindingsAggregate": [
                118,
                {
                    "distinctOn": [
                        131,
                        "[AuditFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        129,
                        "[AuditFindingsOrderBy!]"
                    ],
                    "where": [
                        121
                    ]
                }
            ],
            "auditFindingsByPk": [
                117,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditFindingsStream": [
                117,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        136,
                        "[AuditFindingsStreamCursorInput]!"
                    ],
                    "where": [
                        121
                    ]
                }
            ],
            "auditItems": [
                144,
                {
                    "distinctOn": [
                        158,
                        "[AuditItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        156,
                        "[AuditItemsOrderBy!]"
                    ],
                    "where": [
                        148
                    ]
                }
            ],
            "auditItemsAggregate": [
                145,
                {
                    "distinctOn": [
                        158,
                        "[AuditItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        156,
                        "[AuditItemsOrderBy!]"
                    ],
                    "where": [
                        148
                    ]
                }
            ],
            "auditItemsByPk": [
                144,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditItemsStream": [
                144,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        163,
                        "[AuditItemsStreamCursorInput]!"
                    ],
                    "where": [
                        148
                    ]
                }
            ],
            "audits": [
                171,
                {
                    "distinctOn": [
                        185,
                        "[AuditsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        183,
                        "[AuditsOrderBy!]"
                    ],
                    "where": [
                        175
                    ]
                }
            ],
            "auditsAggregate": [
                172,
                {
                    "distinctOn": [
                        185,
                        "[AuditsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        183,
                        "[AuditsOrderBy!]"
                    ],
                    "where": [
                        175
                    ]
                }
            ],
            "auditsByPk": [
                171,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "auditsStream": [
                171,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        190,
                        "[AuditsStreamCursorInput]!"
                    ],
                    "where": [
                        175
                    ]
                }
            ],
            "companies": [
                200,
                {
                    "distinctOn": [
                        208,
                        "[CompaniesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        207,
                        "[CompaniesOrderBy!]"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "companiesAggregate": [
                201,
                {
                    "distinctOn": [
                        208,
                        "[CompaniesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        207,
                        "[CompaniesOrderBy!]"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "companiesByPk": [
                200,
                {
                    "id": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "companiesStream": [
                200,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        212,
                        "[CompaniesStreamCursorInput]!"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "companySettings": [
                218,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "companySettingsAggregate": [
                219,
                {
                    "distinctOn": [
                        238,
                        "[CompanySettingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        236,
                        "[CompanySettingsOrderBy!]"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "companySettingsByPk": [
                218,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "companySettingsStream": [
                218,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        246,
                        "[CompanySettingsStreamCursorInput]!"
                    ],
                    "where": [
                        226
                    ]
                }
            ],
            "controlPointTemplates": [
                258,
                {
                    "distinctOn": [
                        277,
                        "[ControlPointTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        274,
                        "[ControlPointTemplatesOrderBy!]"
                    ],
                    "where": [
                        263
                    ]
                }
            ],
            "controlPointTemplatesAggregate": [
                259,
                {
                    "distinctOn": [
                        277,
                        "[ControlPointTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        274,
                        "[ControlPointTemplatesOrderBy!]"
                    ],
                    "where": [
                        263
                    ]
                }
            ],
            "controlPointTemplatesByPk": [
                258,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "controlPointTemplatesStream": [
                258,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        282,
                        "[ControlPointTemplatesStreamCursorInput]!"
                    ],
                    "where": [
                        263
                    ]
                }
            ],
            "controlPoints": [
                290,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsAggregate": [
                291,
                {
                    "distinctOn": [
                        315,
                        "[ControlPointsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        312,
                        "[ControlPointsOrderBy!]"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "controlPointsByPk": [
                290,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "controlPointsStream": [
                290,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        325,
                        "[ControlPointsStreamCursorInput]!"
                    ],
                    "where": [
                        299
                    ]
                }
            ],
            "customFields": [
                342,
                {
                    "distinctOn": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        359,
                        "[CustomFieldsOrderBy!]"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "customFieldsAggregate": [
                343,
                {
                    "distinctOn": [
                        361,
                        "[CustomFieldsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        359,
                        "[CustomFieldsOrderBy!]"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "customFieldsByPk": [
                342,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "customFieldsStream": [
                342,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        371,
                        "[CustomFieldsStreamCursorInput]!"
                    ],
                    "where": [
                        349
                    ]
                }
            ],
            "energyMeasures": [
                383,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energyMeasuresAggregate": [
                384,
                {
                    "distinctOn": [
                        404,
                        "[EnergyMeasuresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        402,
                        "[EnergyMeasuresOrderBy!]"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energyMeasuresByPk": [
                383,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "energyMeasuresStream": [
                383,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        414,
                        "[EnergyMeasuresStreamCursorInput]!"
                    ],
                    "where": [
                        391
                    ]
                }
            ],
            "energySources": [
                426,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "energySourcesAggregate": [
                427,
                {
                    "distinctOn": [
                        447,
                        "[EnergySourcesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        445,
                        "[EnergySourcesOrderBy!]"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "energySourcesByPk": [
                426,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "energySourcesStream": [
                426,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        457,
                        "[EnergySourcesStreamCursorInput]!"
                    ],
                    "where": [
                        434
                    ]
                }
            ],
            "hazards": [
                470,
                {
                    "distinctOn": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        489,
                        "[HazardsOrderBy!]"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "hazardsAggregate": [
                471,
                {
                    "distinctOn": [
                        491,
                        "[HazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        489,
                        "[HazardsOrderBy!]"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "hazardsByPk": [
                470,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "hazardsStream": [
                470,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        501,
                        "[HazardsStreamCursorInput]!"
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "inspectionFindingPhotos": [
                513,
                {
                    "distinctOn": [
                        527,
                        "[InspectionFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        525,
                        "[InspectionFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        517
                    ]
                }
            ],
            "inspectionFindingPhotosAggregate": [
                514,
                {
                    "distinctOn": [
                        527,
                        "[InspectionFindingPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        525,
                        "[InspectionFindingPhotosOrderBy!]"
                    ],
                    "where": [
                        517
                    ]
                }
            ],
            "inspectionFindingPhotosByPk": [
                513,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "inspectionFindingId": [
                        1218,
                        "uuid!"
                    ],
                    "photoId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionFindingPhotosStream": [
                513,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        532,
                        "[InspectionFindingPhotosStreamCursorInput]!"
                    ],
                    "where": [
                        517
                    ]
                }
            ],
            "inspectionFindings": [
                540,
                {
                    "distinctOn": [
                        554,
                        "[InspectionFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        552,
                        "[InspectionFindingsOrderBy!]"
                    ],
                    "where": [
                        544
                    ]
                }
            ],
            "inspectionFindingsAggregate": [
                541,
                {
                    "distinctOn": [
                        554,
                        "[InspectionFindingsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        552,
                        "[InspectionFindingsOrderBy!]"
                    ],
                    "where": [
                        544
                    ]
                }
            ],
            "inspectionFindingsByPk": [
                540,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionFindingsStream": [
                540,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        559,
                        "[InspectionFindingsStreamCursorInput]!"
                    ],
                    "where": [
                        544
                    ]
                }
            ],
            "inspectionItemPhotos": [
                567,
                {
                    "distinctOn": [
                        581,
                        "[InspectionItemPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        579,
                        "[InspectionItemPhotosOrderBy!]"
                    ],
                    "where": [
                        571
                    ]
                }
            ],
            "inspectionItemPhotosAggregate": [
                568,
                {
                    "distinctOn": [
                        581,
                        "[InspectionItemPhotosSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        579,
                        "[InspectionItemPhotosOrderBy!]"
                    ],
                    "where": [
                        571
                    ]
                }
            ],
            "inspectionItemPhotosByPk": [
                567,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "inspectionItemId": [
                        1218,
                        "uuid!"
                    ],
                    "photoId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionItemPhotosStream": [
                567,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        586,
                        "[InspectionItemPhotosStreamCursorInput]!"
                    ],
                    "where": [
                        571
                    ]
                }
            ],
            "inspectionItems": [
                594,
                {
                    "distinctOn": [
                        608,
                        "[InspectionItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        606,
                        "[InspectionItemsOrderBy!]"
                    ],
                    "where": [
                        598
                    ]
                }
            ],
            "inspectionItemsAggregate": [
                595,
                {
                    "distinctOn": [
                        608,
                        "[InspectionItemsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        606,
                        "[InspectionItemsOrderBy!]"
                    ],
                    "where": [
                        598
                    ]
                }
            ],
            "inspectionItemsByPk": [
                594,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionItemsStream": [
                594,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        613,
                        "[InspectionItemsStreamCursorInput]!"
                    ],
                    "where": [
                        598
                    ]
                }
            ],
            "inspectionReasons": [
                621,
                {
                    "distinctOn": [
                        635,
                        "[InspectionReasonsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        633,
                        "[InspectionReasonsOrderBy!]"
                    ],
                    "where": [
                        625
                    ]
                }
            ],
            "inspectionReasonsAggregate": [
                622,
                {
                    "distinctOn": [
                        635,
                        "[InspectionReasonsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        633,
                        "[InspectionReasonsOrderBy!]"
                    ],
                    "where": [
                        625
                    ]
                }
            ],
            "inspectionReasonsByPk": [
                621,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionReasonsStream": [
                621,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        640,
                        "[InspectionReasonsStreamCursorInput]!"
                    ],
                    "where": [
                        625
                    ]
                }
            ],
            "inspections": [
                648,
                {
                    "distinctOn": [
                        662,
                        "[InspectionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        660,
                        "[InspectionsOrderBy!]"
                    ],
                    "where": [
                        652
                    ]
                }
            ],
            "inspectionsAggregate": [
                649,
                {
                    "distinctOn": [
                        662,
                        "[InspectionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        660,
                        "[InspectionsOrderBy!]"
                    ],
                    "where": [
                        652
                    ]
                }
            ],
            "inspectionsByPk": [
                648,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "inspectionsStream": [
                648,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        667,
                        "[InspectionsStreamCursorInput]!"
                    ],
                    "where": [
                        652
                    ]
                }
            ],
            "isolationDevices": [
                677,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesAggregate": [
                678,
                {
                    "distinctOn": [
                        698,
                        "[IsolationDevicesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        696,
                        "[IsolationDevicesOrderBy!]"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "isolationDevicesByPk": [
                677,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "isolationDevicesStream": [
                677,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        708,
                        "[IsolationDevicesStreamCursorInput]!"
                    ],
                    "where": [
                        685
                    ]
                }
            ],
            "languages": [
                722,
                {
                    "distinctOn": [
                        732,
                        "[LanguagesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        730,
                        "[LanguagesOrderBy!]"
                    ],
                    "where": [
                        723
                    ]
                }
            ],
            "languagesByPk": [
                722,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "languagesStream": [
                722,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        734,
                        "[LanguagesStreamCursorInput]!"
                    ],
                    "where": [
                        723
                    ]
                }
            ],
            "locations": [
                738,
                {
                    "distinctOn": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        760,
                        "[LocationsOrderBy!]"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "locationsAggregate": [
                739,
                {
                    "distinctOn": [
                        763,
                        "[LocationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        760,
                        "[LocationsOrderBy!]"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "locationsByPk": [
                738,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "locationsStream": [
                738,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        773,
                        "[LocationsStreamCursorInput]!"
                    ],
                    "where": [
                        746
                    ]
                }
            ],
            "permissions": [
                787,
                {
                    "distinctOn": [
                        794,
                        "[PermissionsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        793,
                        "[PermissionsOrderBy!]"
                    ],
                    "where": [
                        790
                    ]
                }
            ],
            "permissionsByPk": [
                787,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "role": [
                        970,
                        "RolesEnum!"
                    ],
                    "userId": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "permissionsStream": [
                787,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        798,
                        "[PermissionsStreamCursorInput]!"
                    ],
                    "where": [
                        790
                    ]
                }
            ],
            "procedureHazards": [
                804,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureHazardsAggregate": [
                805,
                {
                    "distinctOn": [
                        824,
                        "[ProcedureHazardsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        822,
                        "[ProcedureHazardsOrderBy!]"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureHazardsByPk": [
                804,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "hazardId": [
                        1218,
                        "uuid!"
                    ],
                    "procedureId": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "procedureHazardsStream": [
                804,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        834,
                        "[ProcedureHazardsStreamCursorInput]!"
                    ],
                    "where": [
                        812
                    ]
                }
            ],
            "procedureTemplates": [
                846,
                {
                    "distinctOn": [
                        865,
                        "[ProcedureTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        862,
                        "[ProcedureTemplatesOrderBy!]"
                    ],
                    "where": [
                        851
                    ]
                }
            ],
            "procedureTemplatesAggregate": [
                847,
                {
                    "distinctOn": [
                        865,
                        "[ProcedureTemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        862,
                        "[ProcedureTemplatesOrderBy!]"
                    ],
                    "where": [
                        851
                    ]
                }
            ],
            "procedureTemplatesByPk": [
                846,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "procedureTemplatesStream": [
                846,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        870,
                        "[ProcedureTemplatesStreamCursorInput]!"
                    ],
                    "where": [
                        851
                    ]
                }
            ],
            "procedureTypes": [
                878,
                {
                    "distinctOn": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        896,
                        "[ProcedureTypesOrderBy!]"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedureTypesAggregate": [
                879,
                {
                    "distinctOn": [
                        898,
                        "[ProcedureTypesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        896,
                        "[ProcedureTypesOrderBy!]"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedureTypesByPk": [
                878,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "procedureTypesStream": [
                878,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        908,
                        "[ProcedureTypesStreamCursorInput]!"
                    ],
                    "where": [
                        885
                    ]
                }
            ],
            "procedures": [
                920,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresAggregate": [
                921,
                {
                    "distinctOn": [
                        946,
                        "[ProceduresSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        943,
                        "[ProceduresOrderBy!]"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "proceduresByPk": [
                920,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "proceduresStream": [
                920,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        956,
                        "[ProceduresStreamCursorInput]!"
                    ],
                    "where": [
                        929
                    ]
                }
            ],
            "roles": [
                968,
                {
                    "distinctOn": [
                        973,
                        "[RolesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        972,
                        "[RolesOrderBy!]"
                    ],
                    "where": [
                        969
                    ]
                }
            ],
            "rolesByPk": [
                968,
                {
                    "role": [
                        1033,
                        "String!"
                    ]
                }
            ],
            "rolesStream": [
                968,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        974,
                        "[RolesStreamCursorInput]!"
                    ],
                    "where": [
                        969
                    ]
                }
            ],
            "severities": [
                976,
                {
                    "distinctOn": [
                        985,
                        "[SeveritiesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        983,
                        "[SeveritiesOrderBy!]"
                    ],
                    "where": [
                        977
                    ]
                }
            ],
            "severitiesByPk": [
                976,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "severitiesStream": [
                976,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        987,
                        "[SeveritiesStreamCursorInput]!"
                    ],
                    "where": [
                        977
                    ]
                }
            ],
            "statuses": [
                991,
                {
                    "distinctOn": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1009,
                        "[StatusesOrderBy!]"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "statusesAggregate": [
                992,
                {
                    "distinctOn": [
                        1011,
                        "[StatusesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1009,
                        "[StatusesOrderBy!]"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "statusesByPk": [
                991,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "statusesStream": [
                991,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        1021,
                        "[StatusesStreamCursorInput]!"
                    ],
                    "where": [
                        998
                    ]
                }
            ],
            "templates": [
                1035,
                {
                    "distinctOn": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1053,
                        "[TemplatesOrderBy!]"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "templatesAggregate": [
                1036,
                {
                    "distinctOn": [
                        1055,
                        "[TemplatesSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1053,
                        "[TemplatesOrderBy!]"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "templatesByPk": [
                1035,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "templatesStream": [
                1035,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        1065,
                        "[TemplatesStreamCursorInput]!"
                    ],
                    "where": [
                        1042
                    ]
                }
            ],
            "translations": [
                1078,
                {
                    "distinctOn": [
                        1092,
                        "[TranslationsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1090,
                        "[TranslationsOrderBy!]"
                    ],
                    "where": [
                        1082
                    ]
                }
            ],
            "translationsByPk": [
                1078,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "translationsStream": [
                1078,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        1097,
                        "[TranslationsStreamCursorInput]!"
                    ],
                    "where": [
                        1082
                    ]
                }
            ],
            "uploads": [
                1108,
                {
                    "distinctOn": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1126,
                        "[UploadsOrderBy!]"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "uploadsAggregate": [
                1109,
                {
                    "distinctOn": [
                        1128,
                        "[UploadsSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1126,
                        "[UploadsOrderBy!]"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "uploadsByPk": [
                1108,
                {
                    "companyId": [
                        675,
                        "Int!"
                    ],
                    "id": [
                        1218,
                        "uuid!"
                    ]
                }
            ],
            "uploadsStream": [
                1108,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        1138,
                        "[UploadsStreamCursorInput]!"
                    ],
                    "where": [
                        1115
                    ]
                }
            ],
            "users": [
                1150,
                {
                    "distinctOn": [
                        1158,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1157,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        1154
                    ]
                }
            ],
            "usersAggregate": [
                1151,
                {
                    "distinctOn": [
                        1158,
                        "[UsersSelectColumn!]"
                    ],
                    "limit": [
                        675
                    ],
                    "offset": [
                        675
                    ],
                    "orderBy": [
                        1157,
                        "[UsersOrderBy!]"
                    ],
                    "where": [
                        1154
                    ]
                }
            ],
            "usersByPk": [
                1150,
                {
                    "id": [
                        675,
                        "Int!"
                    ]
                }
            ],
            "usersStream": [
                1150,
                {
                    "batchSize": [
                        675,
                        "Int!"
                    ],
                    "cursor": [
                        1162,
                        "[UsersStreamCursorInput]!"
                    ],
                    "where": [
                        1154
                    ]
                }
            ],
            "__typename": [
                1033
            ]
        }
    }
};
