import { flow } from 'fp-ts/function';
import { loop } from './internal.mjs';
import { path } from './path.mjs';
import { predicate } from './test.mjs';
import { typed, cast } from './types.mjs';
import 'js-base64';
import 'value-or-factory';
import { map, mapFail } from './core.mjs';
import 'fp-ts/Array';
import 'fp-ts/Either';
import 'ramda';
import 'fp-ts/lib/function';
import './specialty.mjs';
import 'uuid';

/**
 * Maps an array using a mapper.
 */
const array = (mapper) => loop(index => flow(mapper, path(index)));
const flatArray = (mapper) => flow(loop(index => flow(mapper, path(index))), map(_ => _.flat()));
const arrayByIndex = (mapper) => loop(index => flow(mapper(index), path(index)));
function eachLine(mapper) {
    return flow((typed), map(string => string.split("\n")), arrayByIndex(index => {
        return flow(mapper, mapFail(errors => {
            return errors.map(error => {
                return {
                    ...error,
                    message: "Line #" + (index + 1) + ": " + error.message,
                };
            });
        }));
    }));
}
/**
 * Creates a mapper that validates that an array has the specified number of items.
 */
const elems = (count, error = _ => "Must have " + count + " elements (has " + _.length + ")") => predicate(_ => _.length === count, error);
const minElem = (count, error = _ => "Must have at least " + count + " elements (has " + _.length + ")") => predicate(_ => _.length >= count, error);
const maxElem = (count, error = _ => "Must have at most " + count + " elements (has " + _.length + ")") => predicate(_ => _.length <= count, error);
const oneElem = (emptyError = "This array is empty.", multiError = "This array has multiple items") => flow((typed), predicate(_ => _.length !== 0, emptyError), predicate(_ => _.length < 2, multiError), at(0), (cast));
/**
 * Position mappers for arrays.
 */
const at = (at) => map((i) => i.at(at));
const first = () => at(0);
const last = () => at(-1);
/**
 * Converts input to an array, if it is not currently an array.
 */
const arrayOrElement = () => flow((typed), map(value => Array.isArray(value) ? value : [value]));

export { array, arrayByIndex, arrayOrElement, at, eachLine, elems, first, flatArray, last, maxElem, minElem, oneElem };
