
import { useQueryTakeFirstOrThrow } from "adapters/hooks"
import { fromBroadcastAndLock } from "adapters/rxjs"
import { SYNC_INTERVAL, SYNC_INTERVAL_AFTER_FAILURE, SYNC_LOCK } from "config"
import { RxJS } from "namespaces/RxJS"
import { useCallback, useMemo } from "react"
import { useObservable } from "react-use"
import { useUserStore } from "services/Core"
import { companiesQuery } from "services/db"
import { SYNC_TABLES } from "services/db/Schema"
import { useOnline } from "state-hooks"
import { useAuth } from "ui/auth/authorization"
import { useSynchronousObservable } from "ui/observables"
import Store from "ui/store/Store"
import StoreItem from "ui/store/StoreItem"

export interface SyncSuccess {

    readonly status: "success"
    readonly date: number
    readonly value: Record<string, number>

}

export interface SyncFailure {

    readonly status: "failure"
    readonly date: number
    readonly error: string

}

export type SyncResult = SyncSuccess | SyncFailure

export function useStoreItem<T>(store: Store, key: string) {
    const item = useMemo(() => new StoreItem<T>(store, key), [store])
    const value = useSynchronousObservable(item)
    const setValue = useCallback((value: T | undefined) => item.setValue(value), [store])
    return [value, setValue] as const
}
export function useLastSync() {
    const auth = useAuth()
    const store = useUserStore(auth.data.id)
    return useStoreItem<SyncResult>(store, "lastSync")
}
export function useLastSyncSetting() {
    const auth = useAuth()
    const store = useUserStore(auth.data.id)
    return useMemo(() => new StoreItem<SyncResult>(store, "lastSync"), [store])
}
export function useIsSyncing() {
    const observable = useMemo(() => fromBroadcastAndLock(SYNC_LOCK), [SYNC_LOCK])
    return useObservable(observable, undefined)
}
export function useNextSync() {
    const online = useOnline()
    const auth = useAuth()
    const isSyncing = useIsSyncing()
    const [lastSync] = useLastSync()
    if (isSyncing === undefined) {
        return
    }
    if (!online) {
        return "offline" as const
    }
    if (auth.expired) {
        return "tokenExpired" as const
    }
    if (isSyncing) {
        return "syncing" as const
    }
    if (lastSync === undefined) {
        return Date.now()
    }
    if (lastSync.status === "failure") {
        return lastSync.date + SYNC_INTERVAL_AFTER_FAILURE
    }
    return lastSync.date + SYNC_INTERVAL
}

export function useNeedsSync() {
    const auth = useAuth()
    const dirties = useQueryTakeFirstOrThrow(qc => {
        return qc.selectFrom(sb => {
            return SYNC_TABLES.map(table => sb.selectFrom(table).where("companyId", "in", companiesQuery(qc).where("users.id", "=", auth.data.id).select("companies.id")).where("isDirty", "=", 1).select(eb => eb.fn.count<number>("isDirty").as("count")))
                .reduce((a, b) => a.unionAll(b))
                .as("count")
        }).select(eb => {
            return eb.fn.sum<number>("count").as("count")
        })
    })
    return useObservable(useMemo(() => dirties.pipe(RxJS.map(_ => _.count > 0)), [dirties]))
}
