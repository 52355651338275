import { GridApi, GridPreDestroyedEvent, GridReadyEvent } from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import { flattenPlugins } from "adapters/grid"
import GridOptions from "adapters/grid/GridOptions"
import GridToolBar from "adapters/grid/GridToolBar"
import { GridPlugin } from "adapters/grid/plugins"
import { IS_DEV } from "config"
import { Fragment, MutableRefObject, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useDeepCompareConstant, useDeepCompareUpdateEffect } from "state-hooks"

export interface GridProps<R> extends GridOptions<R> {

    readonly plugin: GridPlugin<R>
    readonly gridRef?: MutableRefObject<AgGridReact<R> | null> | undefined

}

export default function Grid<R>(props: GridProps<R>) {
    const { gridRef, plugin, ...initialOptions } = props
    const [api, setApi] = useState<GridApi<R>>()
    const plugins = useMemo(() => flattenPlugins(props.plugin), [props.plugin])
    const pluginOptions = useMemo(() => plugins.reduce((options, plugin) => plugin?.(options) ?? options, initialOptions), [plugins, initialOptions])
    const options = {
        ...pluginOptions,
        onGridReady: (event: GridReadyEvent<R>) => {
            pluginOptions.onGridReady?.(event)
            setApi(event.api)
        },
        onGridPreDestroyed: (events: GridPreDestroyedEvent<R>) => {
            pluginOptions.onGridPreDestroyed?.(events)
            setApi(undefined)
        }
    }
    const cachedOptions = {
        ...options,
        columnDefs: useDeepCompareConstant(options.columnDefs),
        defaultColDef: useDeepCompareConstant(options.defaultColDef),
        rowClassRules: useDeepCompareConstant(options.rowClassRules),
    }
    if (IS_DEV) {
        useDeepCompareUpdateEffect(() => {
            toast.error("Changed!", { position: "bottom-center" })
        }, [
            options.rowClassRules,
            options.columnDefs,
            options.defaultColDef,
        ])
    }
    return (
        <Fragment>
            <GridToolBar api={api} items={options.hooks} position="top" collapse={options.navCollapse ?? "xl"} />
            <div className={options.divClassName}><AgGridReact {...cachedOptions} ref={props.gridRef} /></div>
            <GridToolBar api={api} items={options.hooks} position="bottom" collapse={options.navCollapse ?? "xl"} />
        </Fragment>
    )
}

//TODO titles on big loading pages at least in the very beginning?
